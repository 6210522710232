import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type IProps = {
	isVisible: boolean;
	submitFunc: any;
	hideFunc: any;
	description?: string | null;
};

type IState = {
	checkString: string;
};

export class DeleteModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			checkString: "",
		};

		this.submit = this.submit.bind(this);
	}

	submit() {
		const { checkString } = this.state;
		const { submitFunc } = this.props;

		if (checkString === "ELIMINA") this.setState({ checkString: "" }, () => submitFunc());
		else alert("Stringa di controllo errata");
	}

	hide() {
		const { hideFunc } = this.props;

		this.setState({ checkString: "" }, () => {
			hideFunc();
		});
	}

	render() {
		const { checkString } = this.state;
		const { isVisible, description } = this.props;
		return (
			<Modal id="DeleteModal" className="text-center" show={isVisible} centered>
				<Modal.Header className="d-flex justify-content-center">
					<Modal.Title>Vuoi eliminare definitivamente?</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{description && <div dangerouslySetInnerHTML={{ __html: description }} className="mb-3" />}
					<Form.Group className="mb-3">
						<Form.Label className="me-3">
							Scrivi <strong>ELIMINA</strong> per potere cancellare:
						</Form.Label>
						<Form.Control type="text" value={checkString} onChange={(e: ChangeEvent<any>) => this.setState({ checkString: e.target.value })} />
					</Form.Group>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button variant="danger" onClick={() => this.submit()}>
						Elimina
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
