import axios from "axios";
import Select from "react-select";
import Calendar from "react-calendar";
import { isSameDay } from "date-fns";
import { ChangeEvent, Component } from "react";
import { Button, Form } from "react-bootstrap";
import { PazienteOutput, CalendarData, CalendarRow, CalendarTile, DisponibilitaOutput, ReactSelectOption, RepartoOutput } from "../types";
import { CreatePrenotazioneModal, VerticalCustomCalendar } from "../components";
import { endpoint, handleAxiosError } from "../helpers";
import { LoaderContext } from "../contexts";
import { notification } from "antd-notifications-messages";

type IProps = {};

type IState = {
	selectOptions: ReactSelectOption[];
	repartiOptions: RepartoOutput[];
	selectedDate: Date | null;
	selectedPaziente: number;
	selectedRepartoId: number;
	selectedReparto: RepartoOutput | null;
	oggettoPrestazione: string;
	descrizionePrestazione: string;
	allDisponibilita: DisponibilitaOutput[];
	filteredDataSelectedDate: CalendarData;
	verticalCalendarVisible: boolean;
	isModalVisible: boolean;
	selectedDisponibilita: CalendarRow | null;
};

const searchParams = new URLSearchParams(window.location.search);

export class CreatePrenotazionePage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectOptions: [],
			repartiOptions: [],
			selectedPaziente: -1,
			selectedRepartoId: -1,
			selectedReparto: null,
			selectedDate: null,
			oggettoPrestazione: "",
			descrizionePrestazione: "",
			allDisponibilita: [],
			filteredDataSelectedDate: { colTitles: [], columns: [] },
			verticalCalendarVisible: false,
			isModalVisible: false,
			selectedDisponibilita: null,
		};

		this.getAnagrafiche = this.getAnagrafiche.bind(this);
		this.getReparti = this.getReparti.bind(this);
		this.tileClasseName = this.tileClasseName.bind(this);
		this.selectDate = this.selectDate.bind(this);
		this.selectReparto = this.selectReparto.bind(this);
		this.selectedDisponibilitaShowModal = this.selectedDisponibilitaShowModal.bind(this);
		this.preSelect = this.preSelect.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getAnagrafiche("");
		this.getReparti();
	}

	getAnagrafiche(searchString: string) {
		axios
			.get<PazienteOutput[]>(`${endpoint}/api/Paziente/GetAllForDropdown/${searchString}`, { withCredentials: true })
			.then(({ data }) => {
				if (data && data.length > 0) {
					this.setState({
						selectOptions: data.map((e: PazienteOutput) => ({
							value: e.id.toString(),
							label: `${e.name} ${e.surname} - ${e.codiceFiscale}`,
						})),
					});
				}
			})
			.catch(handleAxiosError);
	}

	getReparti() {
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAll`, {
				withCredentials: true,
			})
			.then(({ data }) => {
				this.setState({ repartiOptions: data }, () => this.preSelect());
			})
			.catch(handleAxiosError);
	}

	preSelect() {
		if (searchParams.get("preSelectReparto") === null || searchParams.get("preSelectDate") === null) return;

		const repartoId: number = Number(searchParams.get("preSelectReparto"));
		const value: Date = new Date(searchParams.get("preSelectDate") ?? "");

		const { repartiOptions } = this.state;

		const selectedRepartoTemp: RepartoOutput | null =
			repartiOptions.filter((e) => e.id === Number(repartoId)).length > 0 ? repartiOptions.filter((e) => e.id === Number(repartoId))[0] : null;

		this.setState({ selectedRepartoId: repartoId, selectedReparto: selectedRepartoTemp }, () => {
			axios
				.get<DisponibilitaOutput[]>(`${endpoint}/api/Disponibilita/GetAllByReparto/${repartoId}`, { withCredentials: true })
				.then(({ data }) => {
					this.setState({ allDisponibilita: data }, () => {
						if (selectedRepartoTemp) {
							this.setState({ selectedDate: value, verticalCalendarVisible: false }, () => {
								axios
									.post<CalendarData>(
										`${endpoint}/api/Prenotazione/GetAllByCalendarDate`,
										{ selectedDate: value, selectedRepartoId: selectedRepartoTemp.id },
										{ withCredentials: true }
									)
									.then(({ data: dataDisponibilita }) => {
										this.setState({ filteredDataSelectedDate: dataDisponibilita }, () => {
											this.setState({ verticalCalendarVisible: true });
										});
									})
									.catch(handleAxiosError);
							});
						} else {
							notification({ message: "Seleziona un reparto", type: "error" });
						}
					});
				})
				.catch(handleAxiosError);
		});
	}

	tileClasseName({ date, view }: CalendarTile) {
		const { selectedDate, allDisponibilita } = this.state;
		let classApplied: string = "";
		if (view === "month") {
			if (selectedDate && isSameDay(selectedDate, date)) {
				classApplied += "highlight ";
			}

			if (allDisponibilita && allDisponibilita.length > 0) {
				allDisponibilita.forEach((e: DisponibilitaOutput) => {
					if (isSameDay(new Date(e.startDate), date)) {
						classApplied += "highlight-available ";
					}
				});
			}
		}
		return classApplied;
	}

	selectDate(value: Date) {
		const { selectedReparto } = this.state;

		if (selectedReparto) {
			this.setState({ selectedDate: value, verticalCalendarVisible: false }, () => {
				axios
					.post<CalendarData>(
						`${endpoint}/api/Prenotazione/GetAllByCalendarDate`,
						{ selectedDate: value, selectedRepartoId: selectedReparto.id },
						{ withCredentials: true }
					)
					.then(({ data: dataDisponibilita }) => {
						this.setState({ filteredDataSelectedDate: dataDisponibilita }, () => {
							this.setState({ verticalCalendarVisible: true });
						});
					})
					.catch(handleAxiosError);
			});
		} else {
			notification({ message: "Seleziona un reparto", type: "error" });
		}
	}

	selectReparto(value: number) {
		const { repartiOptions } = this.state;
		const selectedRepartoTemp: RepartoOutput | null =
			repartiOptions.filter((e) => e.id === Number(value)).length > 0 ? repartiOptions.filter((e) => e.id === Number(value))[0] : null;

		this.setState({ selectedRepartoId: value, selectedReparto: selectedRepartoTemp }, () => {
			axios
				.get<DisponibilitaOutput[]>(`${endpoint}/api/Disponibilita/GetAllByReparto/${value}`, { withCredentials: true })
				.then(({ data }) => {
					this.setState({ allDisponibilita: data });
				})
				.catch(handleAxiosError);
		});
	}

	selectedDisponibilitaShowModal(calendarRowDisponibilita: CalendarRow) {
		this.setState({ selectedDisponibilita: calendarRowDisponibilita }, () => {
			this.setState({ isModalVisible: true });
		});
	}

	render() {
		const {
			selectOptions,
			repartiOptions,
			selectedPaziente,
			selectedRepartoId,
			selectedReparto,
			selectedDate,
			filteredDataSelectedDate,
			verticalCalendarVisible,
			isModalVisible,
			selectedDisponibilita,
			descrizionePrestazione,
		} = this.state;

		return (
			<>
				{selectedDisponibilita && selectedDate && (
					<CreatePrenotazioneModal
						visible={isModalVisible}
						selectedDisponibilita={selectedDisponibilita}
						pazienteId={selectedPaziente}
						reparto={selectedReparto}
						descriptionText={descrizionePrestazione}
						hideFunc={() => this.setState({ isModalVisible: false })}
					/>
				)}
				<div id="createPrenotazionePage" className="main-color">
					<div className="container">
						<div className="row my-5">
							<div className="col-12 col-md-6">
								<h1>Nuova prenotazione</h1>

								<Form>
									<Form.Group className="mb-3" controlId="formBasicEmail">
										<Form.Label>Paziente</Form.Label>
										<div className="d-flex-inline d-md-flex align-items-center">
											<Select
												onInputChange={this.getAnagrafiche}
												options={selectOptions}
												onChange={(e) =>
													this.setState({
														selectedPaziente: e === null ? -1 : Number(e.value),
													})
												}
												placeholder="Seleziona un paziente..."
												className="w-100 me-4"
											/>
											<Button variant="success" className="text-nowrap mt-3 mt-md-0 widthCustomAutoTo100" href="/createAnagrafica">
												NUOVO PAZIENTE
											</Button>
										</div>
									</Form.Group>

									{selectedPaziente !== -1 && (
										<>
											<Form.Group className="mb-3 d-flex justify-content-between" controlId="formBasicEmail">
												<div className="w-100 me-0 me-md-3">
													<Form.Label>Reparto</Form.Label>
													<Form.Select
														value={selectedRepartoId}
														onChange={(e: ChangeEvent<any>) => this.selectReparto(e.target.value)}
														aria-label="reparto"
													>
														<option value={-1} disabled>
															Seleziona un reparto...
														</option>
														{repartiOptions &&
															repartiOptions.map((e: RepartoOutput, i: number) => (
																<option key={i} value={e.id}>
																	{e.name}
																</option>
															))}
													</Form.Select>
												</div>
											</Form.Group>

											<Form.Group>
												<Form.Label>Note</Form.Label>
												<Form.Control
													as="textarea"
													rows={3}
													value={descrizionePrestazione}
													onChange={(e: ChangeEvent<any>) => this.setState({ descrizionePrestazione: e.target.value })}
												/>
											</Form.Group>
										</>
									)}

									{selectedPaziente !== -1 && selectedRepartoId !== -1 && (
										<>
											<div className="mb-3">
												<Calendar tileClassName={this.tileClasseName} onClickDay={(e: Date) => this.selectDate(e)} />
											</div>
										</>
									)}
								</Form>
							</div>
							<div className="col-12 col-md-6">
								{selectedPaziente !== -1 && selectedRepartoId !== -1 && selectedDate && (
									<VerticalCustomCalendar
										data={filteredDataSelectedDate}
										selectedDate={selectedDate}
										isVisible={verticalCalendarVisible}
										selectedDisponibilitaShowModalFunc={this.selectedDisponibilitaShowModal}
									/>
								)}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

CreatePrenotazionePage.contextType = LoaderContext;
