import { isBefore } from "date-fns";
import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";

type TimeRange = {
    start: string;
    end: string;
};

type IProps = {
    isVisible: boolean;
    submitFunc: (e: TimeRange) => void;
    hideFunc: () => void;
};

type IState = {
    timeRange: TimeRange;
};

export class StatsTimeRangeModal extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            timeRange: {
                start: "",
                end: "",
            },
        };

        this.submit = this.submit.bind(this);
    }

    submit() {
        const { timeRange } = this.state;
        const { submitFunc } = this.props;

        if ((isBefore(new Date(timeRange.start), new Date(timeRange.end)) || timeRange.start === timeRange.end) && timeRange.start !== "" && timeRange.end !== "") {
            submitFunc(timeRange);
            this.setState({ timeRange: { start: "", end: "" } })
        } else {
            alert("La data di inizio deve essere precedente a quella di fine o non sono valide");
        }
    }

    hide() {
        const { hideFunc } = this.props;

        this.setState({
            timeRange: {
                start: "",
                end: "",
            }
        }, () => {
            hideFunc();
        })
    }

    render() {
        const { timeRange } = this.state;
        const { isVisible } = this.props;
        return (
            <Modal id="StatsTimeRangeModal" className="text-center" show={isVisible} centered>
                <Modal.Body>
                    <div className="mb-3">
                        <strong>Seleziona il periodo della ricerca:</strong>
                    </div>
                    <div className="d-flex row">
                        <Form.Group className="col-6 mb-3">
                            <Form.Control
                                type="date"
                                value={timeRange.start}
                                onChange={(e: ChangeEvent<any>) => this.setState({ timeRange: { ...timeRange, start: e.target.value } })}
                            />
                        </Form.Group>
                        <Form.Group className="col-6 mb-3">
                            <Form.Control
                                type="date"
                                value={timeRange.end}
                                onChange={(e: ChangeEvent<any>) => this.setState({ timeRange: { ...timeRange, end: e.target.value } })}
                            />
                        </Form.Group>
                    </div>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={() => this.hide()}>
                        Annulla
                    </Button>
                    <Button variant="primary" onClick={() => this.submit()}>
                        Filtra
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
