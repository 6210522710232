import { HoursChoices, ReactSelectOption } from "../types";

export const BeautifyDateExplicit = (date: Date): string => {
	const dateSplitted: string[] = date.toLocaleDateString().split("/");
	let monthString: string = "gennaio";

	switch (dateSplitted[1]) {
		case "1":
			monthString = "gennaio";
			break;
		case "2":
			monthString = "febbraio";
			break;
		case "3":
			monthString = "marzo";
			break;
		case "4":
			monthString = "aprile";
			break;
		case "5":
			monthString = "maggio";
			break;
		case "6":
			monthString = "giugno";
			break;
		case "7":
			monthString = "luglio";
			break;
		case "8":
			monthString = "agosto";
			break;
		case "9":
			monthString = "settembre";
			break;
		case "10":
			monthString = "ottobre";
			break;
		case "11":
			monthString = "novembre";
			break;
		case "12":
			monthString = "dicembre";
			break;
	}

	return `${dateSplitted[0]} ${monthString} ${dateSplitted[2]}`;
};

export const FilterTimeSelect = (startDate: Date, endDate: Date): ReactSelectOption[] => {
	const startDateFixed: number = new Date(`${new Date().toDateString()} ${startDate.toLocaleTimeString()}`).getTime();
	const endDateFixed: number = new Date(`${new Date().toDateString()} ${endDate.toLocaleTimeString()}`).getTime();

	const filteredOptions: ReactSelectOption[] = HoursChoices.filter((option: ReactSelectOption) => {
		const optionTime: number = new Date(`${new Date().toDateString()} ${option.value}`).getTime();
		return startDateFixed <= optionTime && endDateFixed >= optionTime;
	});

	return filteredOptions;
};

export const FilterTimeSelectFromWhen = (startDate: Date): ReactSelectOption[] => {
	const startDateFixed: number = new Date(`${new Date().toDateString()} ${startDate.toLocaleTimeString()}`).getTime();

	const filteredOptions: ReactSelectOption[] = HoursChoices.filter((option: ReactSelectOption) => {
		const optionTime: number = new Date(`${new Date().toDateString()} ${option.value}`).getTime();
		return startDateFixed < optionTime;
	});

	return filteredOptions;
};

export const CalcSecondValueOption = (firstTime: Date, minutesToAdd: number): ReactSelectOption | null => {
	firstTime.setMinutes(firstTime.getMinutes() + minutesToAdd);

	const hours = String(firstTime.getHours()).padStart(2, "0");
	const minutes = String(firstTime.getMinutes()).padStart(2, "0");
	const seconds = String(firstTime.getSeconds()).padStart(2, "0");
	const formattedDate = `${hours}:${minutes}:${seconds}`;

	return HoursChoices.find((choice: ReactSelectOption) => choice.value === formattedDate) ?? null;
};

export const FormatEndTime = (endTime: string) => {
	// Remove the timezone information from the endTime string
	const timeString = endTime.split(" ")[0];

	// Create a new Date object from the time string
	const date: Date = new Date(`1970-01-01T${timeString}Z`);

	// Extract the hours, minutes, and seconds
	const hours = String(date.getUTCHours()).padStart(2, "0");
	const minutes = String(date.getUTCMinutes()).padStart(2, "0");
	const seconds = String(date.getUTCSeconds()).padStart(2, "0");

	// Return the formatted time
	return `${hours}:${minutes}:${seconds}`;
};
