import { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
	AnagrafichePage,
	CalendarPage,
	CreateAnagraficaPage,
	CreatePrenotazionePage,
	DisplayPage,
	DisponibilitaPage,
	EditAnagraficaPage,
	HomePage,
	NotFound,
	PrenotazioneDetailPage,
	PrenotazioniPage,
	ProfilePage,
	SalePage,
	GestionePage,
	VisiteMDL,
	AnagraficaUser,
	CreateSchedaAnamnestica,
	CompileSchedaAnamnesticaPage,
	SignaturePage,
	SchedaAnamnesticaCompiledDetail,
	PrelieviPage,
} from "..";
import { UserContext, UserDefaultType } from "../../contexts";
import { NavbarComponent } from "../../components";
import { AnagraficheDetail } from "../AnagraficaDetail";
import { EditSchedaAnamnestica } from "../EditSchedaAnamnestica";

type RouteObj = {
	path: string;
	element: JSX.Element;
	restrictedRoles?: string[];
	navbarHidden?: boolean;
	noLogNeeded?: boolean;
	mdlUserNeeded?: boolean;
	prelieviUserNeeded?: boolean;
};

export class AllRoutes extends Component {
	render() {
		const routes: RouteObj[] = [
			{ path: "/display", element: <DisplayPage />, navbarHidden: true, noLogNeeded: true },
			{ path: "/profile", element: <ProfilePage /> },
			{ path: "/prenotazioni", element: <PrenotazioniPage /> },
			{ path: "/prenotazione", element: <PrenotazioneDetailPage /> },
			{ path: "/createPrenotazione", element: <CreatePrenotazionePage /> },
			{ path: "/anagrafiche", element: <AnagrafichePage /> },
			{ path: "/anagrafica", element: <AnagraficheDetail /> },
			{ path: "/createAnagrafica", element: <CreateAnagraficaPage /> },
			{ path: "/editAnagrafica", element: <EditAnagraficaPage /> },
			{ path: "/compileSA", element: <CompileSchedaAnamnesticaPage /> },
			{ path: "/saCompiled", element: <SchedaAnamnesticaCompiledDetail /> },
			{ path: "/disponibilita", element: <DisponibilitaPage /> },
			{ path: "/anagraficaUser", element: <AnagraficaUser /> },
			{ path: "/createSchedaAnamnestica", element: <CreateSchedaAnamnestica />, restrictedRoles: ["Admin", "SuperAdmin"] },
			{ path: "/editSchedaAnamnestica", element: <EditSchedaAnamnestica />, restrictedRoles: ["Admin", "SuperAdmin"] },
			{ path: "/calendari", element: <CalendarPage />, restrictedRoles: ["Admin", "SuperAdmin"] },
			{ path: "/gestione", element: <GestionePage />, restrictedRoles: ["Admin", "SuperAdmin"] },
			{ path: "/signature", element: <SignaturePage />, restrictedRoles: ["Admin", "SuperAdmin"], navbarHidden: true },
			{ path: "/visiteMDL", element: <VisiteMDL />, restrictedRoles: ["Dottore"], mdlUserNeeded: true },
			{ path: "/prelievi", element: <PrelieviPage />, restrictedRoles: ["Dottore"], prelieviUserNeeded: true },
			{ path: "/sale", element: <SalePage />, restrictedRoles: ["Dottore"] },
		];

		return (
			<div id="routes">
				<BrowserRouter>
					<UserContext.Consumer>
						{(userContext: UserDefaultType) => (
							<>
								<Routes>
									{routes.map(
										(e: RouteObj, i: number) =>
											(userContext.roles || e.noLogNeeded) &&
											((userContext.roles &&
												userContext.roles.some((role: string) => e.restrictedRoles && e.restrictedRoles.includes(role))) ||
												!e.restrictedRoles) &&
											(!e.mdlUserNeeded || (e.mdlUserNeeded && userContext.isMdl)) &&
											(!e.prelieviUserNeeded || (e.prelieviUserNeeded && userContext.isAbilitatoPrelievo)) && (
												<Route
													key={i}
													path={e.path}
													element={
														<>
															{!e.navbarHidden && <NavbarComponent />}
															{e.element}
														</>
													}
												/>
											)
									)}
									{userContext.isLogged ? (
										<>
											<Route path={"/"} element={<HomePage />} />
											<Route path="*" element={<NotFound />} />
										</>
									) : (
										<Route path="*" element={<HomePage />} />
									)}
								</Routes>{" "}
							</>
						)}
					</UserContext.Consumer>
				</BrowserRouter>
			</div>
		);
	}
}
