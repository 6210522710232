import { Component } from 'react';
import { SASelectedType } from '../../enums';

type IProps = {
    selectedType: SASelectedType;
};

type IState = {};

export class SchedaMultiValueSA extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="SchedaMultiValueSA">
                <div className='col-12 col-md-6'>

                </div>
            </div>
        );
    }
}
