export type Provincia = {
    sigla: string;
    titolo: string;
};


export const provinceList: Provincia[] = [
    { sigla: "TO", titolo: "Torino" },
    { sigla: "AG", titolo: "Agrigento" },
    { sigla: "AL", titolo: "Alessandria" },
    { sigla: "AN", titolo: "Ancona" },
    { sigla: "AO", titolo: "Aosta" },
    { sigla: "AQ", titolo: "L'Aquila" },
    { sigla: "AR", titolo: "Arezzo" },
    { sigla: "AP", titolo: "Ascoli Piceno" },
    { sigla: "AT", titolo: "Asti" },
    { sigla: "AV", titolo: "Avellino" },
    { sigla: "BA", titolo: "Bari" },
    { sigla: "BT", titolo: "Barletta-Andria-Trani" },
    { sigla: "BL", titolo: "Belluno" },
    { sigla: "BN", titolo: "Benevento" },
    { sigla: "BG", titolo: "Bergamo" },
    { sigla: "BI", titolo: "Biella" },
    { sigla: "BO", titolo: "Bologna" },
    { sigla: "BZ", titolo: "Bolzano" },
    { sigla: "BS", titolo: "Brescia" },
    { sigla: "BR", titolo: "Brindisi" },
    { sigla: "CA", titolo: "Cagliari" },
    { sigla: "CL", titolo: "Caltanissetta" },
    { sigla: "CB", titolo: "Campobasso" },
    { sigla: "CI", titolo: "Carbonia-Iglesias" },
    { sigla: "CE", titolo: "Caserta" },
    { sigla: "CT", titolo: "Catania" },
    { sigla: "CZ", titolo: "Catanzaro" },
    { sigla: "CH", titolo: "Chieti" },
    { sigla: "CO", titolo: "Como" },
    { sigla: "CS", titolo: "Cosenza" },
    { sigla: "CR", titolo: "Cremona" },
    { sigla: "KR", titolo: "Crotone" },
    { sigla: "CN", titolo: "Cuneo" },
    { sigla: "EN", titolo: "Enna" },
    { sigla: "FM", titolo: "Fermo" },
    { sigla: "FE", titolo: "Ferrara" },
    { sigla: "FI", titolo: "Firenze" },
    { sigla: "FG", titolo: "Foggia" },
    { sigla: "FC", titolo: "Forlì-Cesena" },
    { sigla: "FR", titolo: "Frosinone" },
    { sigla: "GE", titolo: "Genova" },
    { sigla: "GO", titolo: "Gorizia" },
    { sigla: "GR", titolo: "Grosseto" },
    { sigla: "IM", titolo: "Imperia" },
    { sigla: "IS", titolo: "Isernia" },
    { sigla: "SP", titolo: "La Spezia" },
    { sigla: "LT", titolo: "Latina" },
    { sigla: "LE", titolo: "Lecce" },
    { sigla: "LC", titolo: "Lecco" },
    { sigla: "LI", titolo: "Livorno" },
    { sigla: "LO", titolo: "Lodi" },
    { sigla: "LU", titolo: "Lucca" },
    { sigla: "MC", titolo: "Macerata" },
    { sigla: "MN", titolo: "Mantova" },
    { sigla: "MS", titolo: "Massa-Carrara" },
    { sigla: "MT", titolo: "Matera" },
    { sigla: "VS", titolo: "Medio Campidano" },
    { sigla: "ME", titolo: "Messina" },
    { sigla: "MI", titolo: "Milano" },
    { sigla: "MO", titolo: "Modena" },
    { sigla: "MB", titolo: "Monza e Brianza" },
    { sigla: "NA", titolo: "Napoli" },
    { sigla: "NO", titolo: "Novara" },
    { sigla: "NU", titolo: "Nuoro" },
    { sigla: "OG", titolo: "Ogliastra" },
    { sigla: "OT", titolo: "Olbia-Tempio" },
    { sigla: "OR", titolo: "Oristano" },
    { sigla: "PD", titolo: "Padova" },
    { sigla: "PA", titolo: "Palermo" },
    { sigla: "PR", titolo: "Parma" },
    { sigla: "PV", titolo: "Pavia" },
    { sigla: "PG", titolo: "Perugia" },
    { sigla: "PU", titolo: "Pesaro e Urbino" },
    { sigla: "PE", titolo: "Pescara" },
    { sigla: "PC", titolo: "Piacenza" },
    { sigla: "PI", titolo: "Pisa" },
    { sigla: "PT", titolo: "Pistoia" },
    { sigla: "PN", titolo: "Pordenone" },
    { sigla: "PZ", titolo: "Potenza" },
    { sigla: "PO", titolo: "Prato" },
    { sigla: "RG", titolo: "Ragusa" },
    { sigla: "RA", titolo: "Ravenna" },
    { sigla: "RC", titolo: "Reggio Calabria" },
    { sigla: "RE", titolo: "Reggio Emilia" },
    { sigla: "RI", titolo: "Rieti" },
    { sigla: "RN", titolo: "Rimini" },
    { sigla: "RM", titolo: "Roma" },
    { sigla: "RO", titolo: "Rovigo" },
    { sigla: "SA", titolo: "Salerno" },
    { sigla: "SS", titolo: "Sassari" },
    { sigla: "SV", titolo: "Savona" },
    { sigla: "SI", titolo: "Siena" },
    { sigla: "SR", titolo: "Siracusa" },
    { sigla: "SO", titolo: "Sondrio" },
    { sigla: "TA", titolo: "Taranto" },
    { sigla: "TE", titolo: "Teramo" },
    { sigla: "TR", titolo: "Terni" },
    { sigla: "TP", titolo: "Trapani" },
    { sigla: "TN", titolo: "Trento" },
    { sigla: "TV", titolo: "Treviso" },
    { sigla: "TS", titolo: "Trieste" },
    { sigla: "UD", titolo: "Udine" },
    { sigla: "VA", titolo: "Varese" },
    { sigla: "VE", titolo: "Venezia" },
    { sigla: "VB", titolo: "Verbano-Cusio-Ossola" },
    { sigla: "VC", titolo: "Vercelli" },
    { sigla: "VR", titolo: "Verona" },
    { sigla: "VV", titolo: "Vibo Valentia" },
    { sigla: "VI", titolo: "Vicenza" },
    { sigla: "VT", titolo: "Viterbo" }
]