import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { LoaderContext } from "../../contexts";
import { RepartoInput, RepartoOutput } from "../../types";

type IProps = {
	initialModel: RepartoOutput | null;
	submitFunc: any;
	hideFunc: any;
};

type IState = {
	model: RepartoInput;
};

export class EditRepartoModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				name: "",
				isActive: true,
			},
		};
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		const { initialModel } = this.props;
		if (initialModel && prevProps.initialModel !== initialModel) {
			this.setState({ model: { name: initialModel.name, isActive: initialModel.isActive } });
		}
	}

	submit() {
		const { model } = this.state;
		const { submitFunc } = this.props;

		this.setState({ model: { name: "", isActive: true } }, () => submitFunc(model));
	}

	render() {
		const { model } = this.state;
		const { initialModel, hideFunc, submitFunc } = this.props;
		return (
			<Modal id="newRepartoModal" show={initialModel !== undefined && initialModel !== null}>
				<Modal.Header>
					<Modal.Title>Modifica {initialModel?.name}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Nome Reparto</Form.Label>
						<Form.Control type="text" value={model.name} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, name: e.target.value } })} />
					</Form.Group>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center">
					<div>
						<Form.Group className='d-flex me-3'>
							<Form.Label className='me-2'>{model.isActive ? 'Attivato' : 'Disattivato'}</Form.Label>
							<Form.Switch checked={model.isActive} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, isActive: e.target.checked } })} />
						</Form.Group>
					</div>
					<div>
						<Button variant="secondary" onClick={() => hideFunc()} className="me-2">
							Annulla
						</Button>
						<Button variant="success" onClick={() => submitFunc(model)}>
							Crea
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}

EditRepartoModal.contextType = LoaderContext;
