import axios from "axios";
import { ChangeEvent, Component, RefObject, createRef } from "react";
import { LoaderContext, UserContext, UserDefaultType } from "../contexts";
import { BiPlusCircle, BiTrash } from "react-icons/bi";
import { Button, Form } from "react-bootstrap";
import { DeleteModal, PrenotazioneBlock, SchedaAnamnesticaBlock } from "../components";
import { APBlockType, PrenotazioneEnum } from "../enums";
import {
	CheckFileType,
	DeleteAnagraficaMessage,
	DeleteFileMessage,
	GenerateRandomString,
	GetIconFromExtension,
	endpoint,
	getPresignedUrl,
	handleAxiosError,
	uploadFile,
} from "../helpers";
import { DocumentoInput, DocumentoOutput, PazienteOutput, PrenotazioneOutput, SchedaAnamnesticaCompiledOutput } from "../types";
import { format } from "date-fns";
import { notification } from "antd-notifications-messages";

type IProps = {};
type IState = {
	anagrafica: PazienteOutput | null;
	showDeleteModal: boolean;
	selectedDocDelete: number | null;
	isDragOver: boolean;
	fileName: string;
	file: File | null;
};

const searchParams = new URLSearchParams(window.location.search);

export class AnagraficheDetail extends Component<IProps, IState> {
	fileInputRef: RefObject<HTMLInputElement>;
	constructor(props: IProps) {
		super(props);
		this.state = {
			anagrafica: null,
			showDeleteModal: false,
			selectedDocDelete: null,
			isDragOver: false,
			fileName: "",
			file: null,
		};

		this.fileInputRef = createRef<HTMLInputElement>();

		this.getData = this.getData.bind(this);
		this.deleteAnagrafica = this.deleteAnagrafica.bind(this);
		this.downloadAnagrafica = this.downloadAnagrafica.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
		this.uploadDoc = this.uploadDoc.bind(this);
		this.removeDoc = this.removeDoc.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.removeDoc = this.removeDoc.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getData();
	}

	getData() {
		const { toggleLoader } = this.context;
		toggleLoader(true);
		axios
			.get<PazienteOutput>(`${endpoint}/api/Paziente/GetById/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ anagrafica: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	deleteAnagrafica() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/Paziente/delete/${searchParams.get("id")}`, { withCredentials: true })
			.then(() => (window.location.href = "/anagrafiche"))
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	downloadAnagrafica() {
		const { toggleLoader } = this.context;
		const { anagrafica } = this.state;

		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Paziente/Download/${searchParams.get("id")}`, { withCredentials: true, responseType: "blob" })
			.then(({ data }) => {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${anagrafica?.name}_${anagrafica?.surname}.xlsx`);
				document.body.appendChild(link);
				link.click();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	handleDragEnter = (e: any) => {
		e.preventDefault();
		this.setState({ isDragOver: true });
	};

	handleDragLeave = (e: any) => {
		e.preventDefault();
		this.setState({ isDragOver: false });
	};

	handleDrop = (e: any) => {
		e.preventDefault();
		if (this.fileInputRef.current) {
			this.fileInputRef.current.files = e.dataTransfer.files;
		}
		const currentFile: File = e.dataTransfer.files[0];
		if (CheckFileType(currentFile)) {
			const currentFileName: string = e.dataTransfer.files[0].name;
			const nameWithoutExtension: string = currentFileName.split(".").slice(0, -1).join(".");
			this.setState({ fileName: nameWithoutExtension, file: currentFile });
		}
		this.setState({ isDragOver: false });
	};

	handleDragOver = (e: any) => {
		e.preventDefault();
	};

	uploadDoc = async () => {
		const { toggleLoader } = this.context;
		const { file, anagrafica, fileName } = this.state;
		if (!file || !anagrafica) return notification({ type: "error", message: "File non trovato o anagrafica mancante" });

		toggleLoader(true);
		const fileSize: string = (file.size / 1024 / 1024).toFixed(2);
		const fileExtension: string = file.name.split(".")[1] ?? "unknown";

		const uploadedUrl: string | null = await uploadFile(file, "documenti", GenerateRandomString(5));

		if (!uploadedUrl) {
			toggleLoader(false);
			return;
		}

		const model: DocumentoInput = {
			fk_Paziente: anagrafica.id,
			titolo: fileName,
			url: uploadedUrl,
			type: fileExtension,
			size: fileSize,
		};

		axios
			.post(`${endpoint}/api/Paziente/AddDoc`, model, { withCredentials: true })
			.then(() => {
				this.getData();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
				this.setState({ fileName: "", file: null });
			});
	};

	removeDoc = (id: number) => {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/Paziente/RemoveDoc/${id}`, { withCredentials: true })
			.then(() => {
				this.setState({ selectedDocDelete: null }, () => {
					this.getData();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	};

	handleFileSelect(e: ChangeEvent<HTMLInputElement>) {
		e.preventDefault();
		if (e.target.files) {
			const currentFile: File = e.target.files[0];
			if (CheckFileType(currentFile)) {
				const currentFileName: string = e.target.files[0].name;
				const nameWithoutExtension: string = currentFileName.split(".").slice(0, -1).join(".");
				this.setState({ fileName: nameWithoutExtension, file: currentFile });
			}
		}
	}

	render() {
		const { anagrafica, showDeleteModal, isDragOver, fileName, file, selectedDocDelete } = this.state;
		return (
			<div id="anagraficheDetailPage" className="main-color">
				<DeleteModal
					isVisible={showDeleteModal}
					submitFunc={() => this.deleteAnagrafica()}
					hideFunc={() => this.setState({ showDeleteModal: false })}
					description={DeleteAnagraficaMessage}
				/>
				<DeleteModal
					isVisible={selectedDocDelete !== null}
					submitFunc={() => this.removeDoc(selectedDocDelete ?? 0)}
					hideFunc={() => this.setState({ selectedDocDelete: null })}
					description={DeleteFileMessage}
				/>
				{anagrafica && (
					<div className="container my-5">
						<div className="row d-flex justify-content-between">
							<div className="col-12 col-md-6">
								<div className="d-flex-inline d-md-flex align-items-center">
									<div className="me-0 me-md-3 text-center text-md-start w-100">
										<span
											style={{ fontSize: "35px", fontWeight: "700", lineHeight: "35px" }}
											className="text-center text-md-start"
										>
											{anagrafica.name} {anagrafica.surname}
										</span>
									</div>
									<div className="me-0 me-md-3 mt-2 mt-md-0">
										<Button
											className="widthCustomAutoTo100"
											variant="outline-dark"
											href={`/editAnagrafica?id=${searchParams.get("id")}`}
										>
											MODIFICA
										</Button>
									</div>

									<UserContext.Consumer>
										{(user: UserDefaultType) => (
											<>
												{(user.roles.includes("Admin") || user.roles.includes("SuperAdmin")) && (
													<>
														<div className="me-0 me-md-3 mt-2 mt-md-0">
															<Button
																className="widthCustomAutoTo100"
																variant="danger"
																onClick={() => this.setState({ showDeleteModal: true })}
															>
																ELIMINA
															</Button>
														</div>
														<div>
															<Button
																className="widthCustomAutoTo100 mt-2 mt-md-0"
																onClick={() => this.downloadAnagrafica()}
															>
																SCARICA
															</Button>
														</div>
													</>
												)}
											</>
										)}
									</UserContext.Consumer>
								</div>

								<div>
									<table>
										<tbody>
											<tr>
												<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
													Indirizzo:
												</td>
												<td style={{ fontWeight: "700" }} className="pt-3">
													{anagrafica.indirizzo}, {anagrafica.citta}, {anagrafica.cap}, {anagrafica.provincia}
												</td>
											</tr>
											<tr>
												<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
													Email:
												</td>
												<td style={{ fontWeight: "700" }} className="pt-3">
													{anagrafica.email}
												</td>
											</tr>
											<tr>
												<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
													Telefono:
												</td>
												<td style={{ fontWeight: "700" }} className="pt-3">
													{anagrafica.phoneNumber}
												</td>
											</tr>
											<tr>
												<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
													Codice Fiscale:
												</td>
												<td style={{ fontWeight: "700" }} className="pt-3">
													{anagrafica.codiceFiscale}
												</td>
											</tr>
											{anagrafica.partitaIva && (
												<tr>
													<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
														Partita IVA:
													</td>
													<td style={{ fontWeight: "700" }} className="pt-3">
														{anagrafica.partitaIva}
													</td>
												</tr>
											)}
											{anagrafica.sdiPec && (
												<tr>
													<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
														SDI/PEC:
													</td>
													<td style={{ fontWeight: "700" }} className="pt-3">
														{anagrafica.sdiPec}
													</td>
												</tr>
											)}
											<tr>
												<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
													Convenzione MDL:
												</td>
												<td style={{ fontWeight: "700" }} className="pt-3">
													{anagrafica.hasConvenzione ? "Sì" : "No"}
												</td>
											</tr>
											{anagrafica.description && (
												<tr>
													<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
														Note:
													</td>
													<td className="pt-3 text-dark">{anagrafica.description}</td>
												</tr>
											)}
											<tr>
												{anagrafica.privacy && (
													<>
														<td style={{ fontWeight: "700" }} className="pe-5 pt-3">
															Privacy:
														</td>
														<td>
															{anagrafica.privacy.signatureUrl && (
																<div>
																	<img
																		className="w-100 h-100"
																		src={getPresignedUrl(anagrafica.privacy.signatureUrl)}
																		alt="signaturePreview"
																	/>
																</div>
															)}
															<div className="d-flex">
																<Form.Check className="me-3" checked={anagrafica.privacy !== null} disabled />
																<Form.Label>Finalità di gestione obbligatoria</Form.Label>
															</div>
															<div className="d-flex">
																<Form.Check
																	className="me-3"
																	checked={anagrafica.privacy.isProfilingEnabled}
																	disabled
																/>
																<Form.Label>Finalità di profilazione</Form.Label>
															</div>
															<div className="d-flex">
																<Form.Check
																	className="me-3"
																	checked={anagrafica.privacy.isThirdPartyEnabled}
																	disabled
																/>
																<Form.Label>Comunicazione a terze parti</Form.Label>
															</div>
															<div>
																{anagrafica.privacy.createdBy ? (
																	<span>
																		Consensi firmati in presenza da {anagrafica.privacy.createdBy.name}{" "}
																		{anagrafica.privacy.createdBy.surname} il{" "}
																	</span>
																) : (
																	<span>Consensi firmati su grmedical.it dal paziente il{" "}</span>
																)}
																<span>
																	{format(new Date(anagrafica.privacy.createdDate), "dd/MM/yyyy")} alle{" "}
																	{format(new Date(anagrafica.privacy.createdDate), "HH:mm")}
																</span>
															</div>
														</td>
													</>
												)}
											</tr>
										</tbody>
									</table>
								</div>

								<div className="mt-5">
									<div className="row d-flex">
										{anagrafica.documenti.map((e: DocumentoOutput, i: number) => (
											<div className="col-12 col-md-4" key={i}>
												<div className="p-3 mb-4 grey-bg">
													<div className="d-flex justify-content-between align-items-center">
														<div className="d-flex align-items-center">
															{GetIconFromExtension(e.type)}
															<span className="text-break">
																<strong>{e.type.toUpperCase()}</strong> - {e.size}mb
															</span>
														</div>
														<UserContext.Consumer>
															{(user: UserDefaultType) =>
																(user.roles.includes("Admin") || user.roles.includes("SuperAdmin")) && (
																	<div
																		style={{ cursor: "pointer" }}
																		onClick={() => this.setState({ selectedDocDelete: e.id })}
																	>
																		<BiTrash className="text-white rounded bg-danger p-1" />
																	</div>
																)
															}
														</UserContext.Consumer>
													</div>
													<div className="d-flex">
														<div className="my-3">
															<strong
																className="text-break"
																style={{ cursor: "pointer" }}
																onClick={() => window.open(getPresignedUrl(e.url), "_blank")}
															>
																{e.titolo}
															</strong>
														</div>
													</div>
													<div style={{ fontSize: "12px" }}>
														<div>
															<span>
																Dott. {e.createdBy.name} {e.createdBy.surname}
															</span>
														</div>
														<div>
															<strong>
																{format(new Date(e.createdDate), "dd/MM/yyyy")} -{" "}
																{format(new Date(e.createdDate), "HH:mm")}
															</strong>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
									{file ? (
										<div className="p-3 grey-bg">
											<div>
												<strong>Conferma il nome del caricamento:</strong>
											</div>
											<div className="d-flex-inline d-md-flex justify-content-between">
												<div className="col-12 col-md-7">
													<Form.Control
														onChange={(e: ChangeEvent<any>) => this.setState({ fileName: e.target.value })}
														value={fileName}
														type="text"
														className="w-100"
													/>
												</div>
												<div className="col-12 col-md-4">
													<Button
														className="me-2 mt-2 mt-md-0"
														variant="secondary"
														onClick={() =>
															this.setState({ fileName: "", file: null }, () => {
																if (this.fileInputRef.current) {
																	this.fileInputRef.current.value = "";
																}
															})
														}
													>
														ANNULLA
													</Button>
													<Button className="mt-2 mt-md-0" variant="primary" onClick={() => this.uploadDoc()}>
														CARICA
													</Button>
												</div>
											</div>
										</div>
									) : (
										<div
											onDrop={this.handleDrop}
											onDragOver={this.handleDragOver}
											onDragEnter={this.handleDragEnter}
											onDragLeave={this.handleDragLeave}
											onClick={() => this.fileInputRef.current?.click()}
										>
											<img
												className="w-100 h-100 d-none d-md-block"
												src={
													isDragOver
														? "https://grmedical-public.s3.eu-south-1.amazonaws.com/static/hoverupload.jpg"
														: "https://grmedical-public.s3.eu-south-1.amazonaws.com/static/preupload.jpg"
												}
												alt="uploadImg"
											/>
											<img
												className="w-100 h-100 d-block d-md-none"
												src="https://grmedical-public.s3.eu-south-1.amazonaws.com/static/PreuploadResp.jpg"
												alt="uploadImg"
											/>
										</div>
									)}
									<input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect} style={{ display: "none" }} />
								</div>

								<div className="mt-5">
									<div className="my-3">
										<strong>Schede anamnestiche:</strong>
									</div>
									<div>
										{anagrafica.schedeAnamnesticheCompiled.map((e: SchedaAnamnesticaCompiledOutput, i: number) => (
											<SchedaAnamnesticaBlock data={e} key={i} />
										))}
										<a href={`/compileSA?id=${anagrafica.id}&returnUrl=${window.location.href}`} className="text-decoration-none">
											<div className="main-bg w-100 my-3">
												<div className="d-flex justify-content-between p-3">
													<div className="col-12">
														<span
															className="d-flex align-items-center"
															style={{
																fontSize: "20px",
																fontWeight: "700",
																color: "white",
															}}
														>
															<BiPlusCircle className="me-2" />
															Crea nuova scheda
														</span>
													</div>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-1" />
							<div className="col-12 col-md-5">
								{anagrafica.prenotazioni && (
									<div>
										{anagrafica.prenotazioni.filter(
											(e: PrenotazioneOutput) =>
												(e.status === PrenotazioneEnum.Inizializzato || e.status === PrenotazioneEnum.Arrivato) &&
												!e.isDeleted &&
												new Date(e.startDate) >= new Date()
										).length > 0 && (
												<div className="mb-3">
													<div className="d-flex justify-content-between align-items-center">
														<span style={{ fontSize: "30px", fontWeight: "700" }}>Prossime prenotazioni</span>
														<Button href={`/createPrenotazione`}>NUOVA</Button>
													</div>

													<div>
														{anagrafica.prenotazioni
															.filter(
																(e) =>
																	(e.status === PrenotazioneEnum.Inizializzato || e.status === PrenotazioneEnum.Arrivato) &&
																	!e.isDeleted &&
																	new Date(e.startDate) >= new Date()
															)
															.map((e: PrenotazioneOutput, i: number) => (
																<PrenotazioneBlock key={i} prenotazione={e} blockType={APBlockType.Prossime} />
															))}
													</div>
												</div>
											)}
										{anagrafica.prenotazioni.filter((e) => e.status === PrenotazioneEnum.Concluso).length > 0 && (
											<div className="mb-3">
												<div className="d-flex justify-content-between align-items-center">
													<div>
														<span style={{ fontSize: "30px", fontWeight: "700" }}>Prenotazioni passate</span>
													</div>
													<div>
														<Button href={`/createPrenotazione`}>NUOVA PRENOTAZIONE</Button>
													</div>
												</div>

												<div>
													{anagrafica.prenotazioni
														.filter((e) => e.status === PrenotazioneEnum.Concluso)
														.reverse()
														.map((e: PrenotazioneOutput, i: number) => (
															<PrenotazioneBlock key={i} prenotazione={e} blockType={APBlockType.Passate} />
														))}
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

AnagraficheDetail.contextType = LoaderContext;
