import { Component } from "react"
import { AllRoutes } from "./routes"
import { LoaderProvider, UserProvider } from "./contexts"

export class App extends Component {
  render() {
    return (
      <div className="min-vh-100">
        <LoaderProvider>
          <UserProvider>
            <AllRoutes />
          </UserProvider>
        </LoaderProvider>
      </div>
    )
  }
}
