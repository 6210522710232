import { DisponibilitaOutput, PrenotazioneOutput, SalaOutput } from "../../types"

export type UserDefaultType = {
    isLoaded: boolean,
    isLogged: boolean,
    name: string
    surname: string
    email: string
    phoneNumber: string
    codiceFiscale: string
    personeInCoda: number
    roles: string[]
    sala: SalaOutput | null
    isMdl: boolean
    isAbilitatoPrelievo: boolean
    prenotazioni: PrenotazioneOutput[]
    disponibilita: DisponibilitaOutput[]
}



export const UserDefaultValue: UserDefaultType = {
    isLoaded: false,
    isLogged: false,
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    codiceFiscale: "",
    personeInCoda: 0,
    roles: [],
    sala: null,
    isMdl: false,
    isAbilitatoPrelievo: false,
    prenotazioni: [],
    disponibilita: []
}
