import Marquee from "react-fast-marquee";

import * as signalR from "@microsoft/signalr";
import Sound from "react-sound";

import { Component } from "react";
import { endpoint, handleAxiosError } from "../helpers";
import axios from "axios";
import { LoaderContext } from "../contexts";
import { format } from "date-fns";

type FeedItem = {
	title: string;
	description: string;
};

type Message = {
	sala: number;
	coda: string;
};

type PlayStatus = "PLAYING" | "STOPPED" | "PAUSED";

type IProps = {};

type IState = {
	connection: signalR.HubConnection | null;
	messages: Message[];
	feedItems: FeedItem[];
	currentTime: string;
	statusAudio: PlayStatus;
	playAudio: boolean;
	flashingSala: number | null;
};

export class DisplayPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			connection: null,
			messages: [],
			feedItems: [],
			currentTime: format(new Date(), "HH:mm"),
			statusAudio: "STOPPED",
			playAudio: false,
			flashingSala: null,
		};

		this.getFeed = this.getFeed.bind(this);
		this.createWebSocket = this.createWebSocket.bind(this);
		this.clickClock = this.clickClock.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.getFeed();
		this.createWebSocket();
		this.clickClock();
	}

	clickClock() {
		setTimeout(() => {
			if (Number(format(new Date(), "mm")) % 60 === 0)
				this.getFeed();
			this.setState({ currentTime: format(new Date(), "HH:mm") }, () => {
				this.clickClock();
			});
		}, 60000);
	}

	getFeed() {
		axios
			.get<FeedItem[]>(`${endpoint}/api/Display/GetRSSFeed`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ feedItems: data });
			})
			.catch(handleAxiosError);
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(`${endpoint}/ws`, signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("PushCallMessage", (e: string) => {
			const { messages } = this.state;

			const splittedMsg: string[] = e.split("_");

			if (splittedMsg.length >= 2) {
				this.setState({
					messages: [
						{
							sala: Number(splittedMsg[0]),
							coda: splittedMsg[1],
						},
						...messages.filter((e) => e.sala !== Number(splittedMsg[0])),
					],
					statusAudio: "PLAYING",
					playAudio: true
				});

				this.setState({
					flashingSala: Number(splittedMsg[0]),
				});

				setTimeout(() => {
					this.setState({ flashingSala: null });
				}, 5000);

			}
		});

		hubConnection.on("CleanSala", (e: string) => {
			const { messages } = this.state;

			const salaNum: number = Number(e);

			const index: number = messages.findIndex((e) => e.sala === salaNum);

			messages[index] = {
				sala: salaNum,
				coda: "",
			};

			this.setState({ messages: messages });
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection });
			})
			.catch(() => {
				window.location.reload();
			});
	}

	render() {
		const { feedItems, currentTime, messages, statusAudio, playAudio } = this.state;
		return (
			<div id="displayPage" className="vh-100">
				{playAudio && (
					<Sound url={"https://grmedical-public.s3.eu-south-1.amazonaws.com/static/ping.mp3"} playStatus={statusAudio} onFinishedPlaying={() => this.setState({ statusAudio: "STOPPED" })} />
				)}
				<div style={{ background: "#4DABE2" }}>
					<div className="text-white d-flex justify-content-between align-items-center p-0" style={{ fontSize: "80px", fontWeight: "700" }}>
						<span className="p-3 px-5" style={{ fontSize: "70px" }}>
							Benvenuti in GR Medical
						</span>
						<span className="p-3 px-4">{currentTime}</span>
					</div>
				</div>
				<div className="row m-0 h-75">
					<div className="col-9 p-0 h-100">
						<div className="h-100 w-100">
							<iframe
								title="iFrameBase"
								className="w-100 h-100"
								src="https://www.youtube.com/embed/?listType=playlist&list=PLsZAL08DCVHN8vpLNFxzIMCUgAXNQo9BK&autoplay=1&mute=1&loop=1&controls=0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						</div>
					</div>
					<div className="col-3 p-0 h-100" style={{ fontWeight: "700", lineHeight: "50px" }}>
						<div className="row m-0 h-100">
							<div className="d-flex justify-content-between align-items-center p-0 h-25">
								<div className="main-bg text-white h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<div className="text-center p-5">
											<span style={{ fontSize: "30px" }}>SALA</span>
											<br />
											<span style={{ fontSize: "130px" }}>1</span>
										</div>
									</div>
								</div>
								<div className="w-100 h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<span className={`main-color ${this.state.flashingSala === 1 ? 'flash' : ''}`} style={{ fontSize: "90px" }}>
											{messages.find((e) => e.sala === 1)?.coda}
										</span>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-center p-0 h-25">
								<div className="main-bg text-white h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<div className="text-center p-5">
											<span style={{ fontSize: "30px" }}>SALA</span>
											<br />
											<span style={{ fontSize: "130px" }}>2</span>
										</div>
									</div>
								</div>
								<div style={{ background: "#E9F1F8" }} className="w-100 h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<span className={`main-color ${this.state.flashingSala === 2 ? 'flash' : ''}`} style={{ fontSize: "90px" }}>
											{messages.find((e) => e.sala === 2)?.coda}
										</span>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-center p-0 h-25">
								<div className="main-bg text-white h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<div className="text-center p-5">
											<span style={{ fontSize: "30px" }}>SALA</span>
											<br />
											<span style={{ fontSize: "130px" }}>3</span>
										</div>
									</div>
								</div>
								<div className="w-100 h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<span className={`main-color ${this.state.flashingSala === 3 ? 'flash' : ''}`} style={{ fontSize: "90px" }}>
											{messages.find((e) => e.sala === 3)?.coda}
										</span>
									</div>
								</div>
							</div>
							<div className="d-flex justify-content-between align-items-center p-0 h-25">
								<div className="main-bg text-white h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<div className="text-center p-5">
											<span style={{ fontSize: "30px" }}>SALA</span>
											<br />
											<span style={{ fontSize: "130px" }}>4</span>
										</div>
									</div>
								</div>
								<div style={{ background: "#E9F1F8" }} className="w-100 h-100">
									<div className="d-flex justify-content-center align-items-center h-100">
										<span className={`main-color ${this.state.flashingSala === 4 ? 'flash' : ''}`} style={{ fontSize: "90px" }}>
											{messages.find((e) => e.sala === 4)?.coda}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="main-color d-flex justify-content-between align-items-center p-0 pt-3" style={{ fontSize: "40px", fontWeight: "700" }}>
							<Marquee className="p-3">
								{feedItems.map((e: FeedItem) => (
									<>
										{e.title.length > 0 && <span className="ms-2" style={{ fontWeight: "700" }}>{`- ${e.title}`}</span>}
										{e.description.length > 0 && <span className="me-2" style={{ fontWeight: "500" }}>{`: ${e.description}`}</span>}
									</>
								))}
							</Marquee>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

DisplayPage.contextType = LoaderContext;
