import axios from "axios";
import { Component } from "react";
import { Button } from "react-bootstrap";
import {
	OggettoPrestazioneInput,
	OggettoPrestazioneOutput,
	PrenotazioniStats,
	RepartoInput,
	RepartoOutput,
	SalaOutput,
	SchedaAnamnesticaOutput,
} from "../types";
import { endpoint, handleAxiosError } from "../helpers";
import { LoaderContext } from "../contexts";
import {
	EditOggettoPrestazioneModal,
	EditRepartoModal,
	NewOggettoPrestazioneModal,
	NewRepartoModal,
	RepartoRow,
	SchedaAnamnesticaRow,
} from "../components";

type IProps = {};

type IState = {
	stats: PrenotazioniStats;
	sale: SalaOutput[];
	reparti: RepartoOutput[];
	schedeAnamnestiche: SchedaAnamnesticaOutput[];
	repartoSelected: number | null;
	repartoForNewPrestazione: RepartoOutput | null;
	newRepartoModalShow: boolean;
	selectedEditReparto: RepartoOutput | null;
	selectedEditOggettoPrestazione: OggettoPrestazioneOutput | null;
};

export class GestionePage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			stats: {
				totale: 0,
				arrivati: 0,
				inArrivo: 0,
				inRitardo: 0,
			},
			sale: [],
			reparti: [],
			schedeAnamnestiche: [],
			repartoSelected: null,
			repartoForNewPrestazione: null,
			newRepartoModalShow: false,
			selectedEditReparto: null,
			selectedEditOggettoPrestazione: null,
		};

		this.getPrenotazioniStats = this.getPrenotazioniStats.bind(this);
		this.getAllSale = this.getAllSale.bind(this);
		this.getAllReparti = this.getAllReparti.bind(this);
		this.createNewOggettoPrestazione = this.createNewOggettoPrestazione.bind(this);
		this.createNewReparto = this.createNewReparto.bind(this);
		this.editOggettoPrestazione = this.editOggettoPrestazione.bind(this);
		this.editReparto = this.editReparto.bind(this);
		this.liberaSala = this.liberaSala.bind(this);
		this.getSchedeAnamnestiche = this.getSchedeAnamnestiche.bind(this);
		this.downloadAll = this.downloadAll.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getPrenotazioniStats();
		this.getAllSale();
		this.getAllReparti();
		this.getSchedeAnamnestiche();
	}

	getSchedeAnamnestiche() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<SchedaAnamnesticaOutput[]>(`${endpoint}/api/SchedaAnamnestica/GetAll`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ schedeAnamnestiche: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getPrenotazioniStats() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PrenotazioniStats>(`${endpoint}/api/Prenotazione/GetAllStats`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ stats: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAllSale() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<SalaOutput[]>(`${endpoint}/api/Sala/GetAll`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ sale: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAllReparti() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAll/true`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ reparti: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	liberaSala(id: number) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<SalaOutput[]>(`${endpoint}/api/Sala/LiberaById/${id}`, { withCredentials: true })
			.then(() => {
				this.getAllSale();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	createNewOggettoPrestazione(model: OggettoPrestazioneInput) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		this.setState({ repartoForNewPrestazione: null }, () => {
			axios
				.post(`${endpoint}/api/Reparto/CreateOggettoPrestazione`, model, { withCredentials: true })
				.then(() => {
					this.getAllReparti();
				})
				.catch(handleAxiosError)
				.finally(() => {
					toggleLoader(false);
				});
		});
	}

	editOggettoPrestazione(data: OggettoPrestazioneInput) {
		const { toggleLoader } = this.context;
		const { selectedEditOggettoPrestazione } = this.state;

		if (selectedEditOggettoPrestazione === null) return;

		toggleLoader(true);
		axios
			.put(`${endpoint}/api/Reparto/EditOggettoPrestazione/${selectedEditOggettoPrestazione.id}`, data, { withCredentials: true })
			.then(() => {
				this.setState({ selectedEditOggettoPrestazione: null }, () => {
					this.getAllReparti();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	createNewReparto(model: RepartoInput) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/Reparto/Create`, model, { withCredentials: true })
			.then(() => {
				this.getAllReparti();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	editReparto(data: RepartoInput) {
		const { toggleLoader } = this.context;
		const { selectedEditReparto } = this.state;

		if (selectedEditReparto === null) return;

		toggleLoader(true);
		axios
			.put(`${endpoint}/api/Reparto/Edit/${selectedEditReparto?.id}`, data, { withCredentials: true })
			.then(() => {
				this.setState({ selectedEditReparto: null }, () => {
					this.getAllReparti();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	downloadAll() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Paziente/DownloadAll`, { withCredentials: true, responseType: "blob" })
			.then(({ data }) => {
				const url = window.URL.createObjectURL(new Blob([data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "Pazienti.csv");
				document.body.appendChild(link);
				link.click();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const {
			sale,
			reparti,
			stats,
			repartoSelected,
			repartoForNewPrestazione,
			newRepartoModalShow,
			selectedEditOggettoPrestazione,
			selectedEditReparto,
			schedeAnamnestiche,
		} = this.state;

		return (
			<>
				<NewRepartoModal
					submitFunc={this.createNewReparto}
					hideFunc={() => this.setState({ newRepartoModalShow: false })}
					isVisible={newRepartoModalShow}
				/>
				<NewOggettoPrestazioneModal
					submitFunc={this.createNewOggettoPrestazione}
					hideFunc={() => this.setState({ repartoForNewPrestazione: null })}
					reparto={repartoForNewPrestazione}
				/>

				<EditRepartoModal
					initialModel={selectedEditReparto}
					submitFunc={this.editReparto}
					hideFunc={() => this.setState({ selectedEditReparto: null })}
				/>
				<EditOggettoPrestazioneModal
					initialModel={selectedEditOggettoPrestazione}
					submitFunc={this.editOggettoPrestazione}
					hideFunc={() =>
						this.setState({
							selectedEditOggettoPrestazione: null,
							repartoSelected: null,
						})
					}
				/>

				<div id="gestionePage" className="main-color">
					<div className="container">
						<div className="d-flex-inline d-md-flex text-center text-md-start justify-content-between align-items-center my-5">
							<div>
								<strong className="fs-1">Gestione Studio</strong>
							</div>
							<div>
								<Button onClick={() => this.downloadAll()}>SCARICA TUTTI I PAZIENTI</Button>
							</div>
						</div>
						<div className="row d-flex align-items-center">
							<div className="col-12 col-md-8">
								<div className="row d-flex">
									{sale.map((e: SalaOutput, i: number) => (
										<div key={i} className="col-6">
											<div className="py-3">
												<h1>
													<span>
														{e.dottore === null ? "🟢" : "🔴"} <strong>{e.name}</strong>
													</span>
												</h1>
												{e.dottore !== null ? (
													<>
														<div style={{ lineHeight: "18px" }} className="mb-2">
															<div>
																<strong>
																	Dott. {e.dottore.name} {e.dottore.surname}
																</strong>
															</div>
															<div>
																<span>{e.dottore.personeInCoda} pazienti in attesa</span>
															</div>
														</div>
														<div>
															<Button onClick={() => this.liberaSala(e.id)}>Libera</Button>
														</div>
													</>
												) : (
													<p>Libera</p>
												)}
											</div>
										</div>
									))}
								</div>
							</div>
							<div className="col-12 col-md-4">
								<div className="row d-flex text-center">
									<div className="col-12">
										<div className="py-3">
											<h1>
												<strong>{stats.totale} Prenotazioni</strong>
											</h1>
										</div>
									</div>
									<div className="col-4">
										<div className="p-3">
											<div className="w-100">
												<h3>{stats.arrivati}</h3>
											</div>
											<div className="w-100">
												<strong>Arrivati</strong>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="p-3">
											<div className="w-100">
												<h3>{stats.inArrivo}</h3>
											</div>
											<div className="w-100">
												<strong>In Arrivo</strong>
											</div>
										</div>
									</div>
									<div className="col-4">
										<div className="p-3">
											<div className="w-100">
												<h3>{stats.inRitardo}</h3>
											</div>
											<div className="w-100">
												<strong>In Ritardo</strong>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="mb-5">
							<div className="d-flex-inline d-md-flex justify-content-between align-items-center">
								<div className="mt-5">
									<h2 className="text-center text-md-start">
										<strong>Reparti e Prestazioni</strong>
									</h2>
								</div>
								<div className="d-none d-md-block">
									<Button onClick={() => this.setState({ newRepartoModalShow: true })}>NUOVO REPARTO</Button>
								</div>
							</div>
							<div>
								{reparti.map((e: RepartoOutput, i: number) => (
									<RepartoRow
										key={i}
										reparto={e}
										isSelected={repartoSelected === e.id}
										selectFunc={(value: number) => this.setState({ repartoSelected: value })}
										newPrestazioneFunc={() => this.setState({ repartoForNewPrestazione: e, repartoSelected: e.id })}
										editOggettoFunc={(value: OggettoPrestazioneOutput) =>
											this.setState({ selectedEditOggettoPrestazione: value })
										}
										editRepartoFunc={(value: RepartoOutput) => this.setState({ selectedEditReparto: value })}
									/>
								))}
							</div>
						</div>
						<div className="mb-5">
							<div className="d-flex-inline d-md-flex justify-content-between align-items-center">
								<div className="mt-5">
									<h2 className="text-center text-md-start">
										<strong>Template Schede Anamnestiche</strong>
									</h2>
								</div>
								<div className="d-none d-md-block">
									<Button href="/createSchedaAnamnestica">NUOVA SCHEDA ANAMNESTICA</Button>
								</div>
							</div>
							<div>
								{schedeAnamnestiche.map((e: SchedaAnamnesticaOutput, i: number) => (
									<SchedaAnamnesticaRow key={i} reparti={reparti} schedaAnamnestica={e} />
								))}
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

GestionePage.contextType = LoaderContext;
