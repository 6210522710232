import { ChangeEvent, Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { LoaderContext } from "../../contexts";
import { MinutesChoices, OggettoPrestazioneInput, RepartoOutput } from "../../types";
import { notification } from "antd-notifications-messages";

type IProps = {
	reparto: RepartoOutput | null;
	submitFunc: any;
	hideFunc: any;
};

type IState = {
	model: OggettoPrestazioneInput;
};

export class NewOggettoPrestazioneModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				name: "",
				timeRange: 0,
				fk_Reparto: 0,
				isActive: true,
				isExtVisible: false,
				price: "0",
			},
		};

		this.submit = this.submit.bind(this);
		this.hide = this.hide.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	submit() {
		const { model } = this.state;
		const { submitFunc, reparto } = this.props;

		if (reparto) {
			const tempModel: OggettoPrestazioneInput = { ...model, fk_Reparto: reparto.id };
			this.setState(
				{
					model: {
						name: "",
						timeRange: 0,
						fk_Reparto: 0,
						isActive: true,
						isExtVisible: false,
						price: "0",
					},
				},
				() => submitFunc(tempModel)
			);
		} else {
			notification({ message: "Reparto non selezionato.", type: "error" });
		}
	}

	hide() {
		const { hideFunc } = this.props;
		this.setState(
			{
				model: {
					name: "",
					timeRange: 0,
					fk_Reparto: 0,
					isActive: true,
					isExtVisible: false,
					price: "0",
				},
			},
			() => hideFunc()
		);
	}

	render() {
		const { model } = this.state;
		const { reparto } = this.props;
		return (
			<Modal id="NewOggettoPrestazioneModal" show={reparto !== undefined && reparto !== null}>
				<Modal.Header>
					<Modal.Title>Nuova Prestazione per {reparto?.name}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="mb-3">
						<span>Attenzione! Non sarà possibile eliminare questa prestazione nel futuro. Crea questa prestazione solo se necessario.</span>
					</div>

					<Form.Group className="mb-3">
						<Form.Label className="me-3">Nome Prestazione</Form.Label>
						<Form.Control type="text" value={model.name} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, name: e.target.value } })} />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Durata in minuti</Form.Label>
						<Form.Select value={model.timeRange} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, timeRange: Number(e.target.value) } })}>
							<option value={-1}>Seleziona la durata della prestazione...</option>
							{MinutesChoices.map((e: number, i: number) => (
								<option key={i} value={e}>
									{e} minuti
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Prezzo</Form.Label>
						<InputGroup>
							<span className="input-group-text">
								€
							</span>
							<Form.Control type="text" value={model.price} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, price: e.target.value } })} />
						</InputGroup>
					</Form.Group>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button variant="success" onClick={() => this.submit()}>
						Crea
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

NewOggettoPrestazioneModal.contextType = LoaderContext;
