import { ChangeEvent, Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { LoaderContext } from "../../contexts";
import { MinutesChoices, OggettoPrestazioneInput, OggettoPrestazioneOutput } from "../../types";

type IProps = {
	initialModel: OggettoPrestazioneOutput | null;
	submitFunc: any;
	hideFunc: any;
};

type IState = {
	model: OggettoPrestazioneInput | null;
};

export class EditOggettoPrestazioneModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: null,
		};
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		const { initialModel } = this.props;
		if (initialModel && prevProps.initialModel !== initialModel) {
			this.setState({
				model:
				{
					name: initialModel.name,
					timeRange: initialModel.timeRange,
					fk_Reparto: initialModel.fk_Reparto,
					isActive: initialModel.isActive,
					isExtVisible: initialModel.isExtVisible,
					price: initialModel.price,
				}
			});
		}
	}

	hide() {
		const { hideFunc } = this.props;
		this.setState({ model: null }, () => hideFunc());
	}

	render() {
		const { model } = this.state;
		const { initialModel, hideFunc, submitFunc } = this.props;
		return (
			<Modal id="EditOggettoPrestazioneModal" show={initialModel !== undefined && initialModel !== null}>
				<Modal.Header>
					<Modal.Title>Modifica {initialModel?.name}</Modal.Title>
				</Modal.Header>



				{model && (
					<>
						<Modal.Body>
							<Form.Group className="mb-3">
								<Form.Label className="me-3">Nome Prestazione</Form.Label>
								<Form.Control type="text" value={model.name} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, name: e.target.value } })} />
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label className="me-3">Durata in minuti</Form.Label>
								<Form.Select value={model.timeRange} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, timeRange: Number(e.target.value) } })}>
									<option value={-1}>Seleziona la durata della prestazione...</option>
									{MinutesChoices.map((e: number, i: number) => (
										<option key={i} value={e}>
											{e} minuti
										</option>
									))}
								</Form.Select>
							</Form.Group>
							<Form.Group className="mb-3">
								<Form.Label className="me-3">Prezzo</Form.Label>
								<InputGroup>
									<span className="input-group-text">
										€
									</span>
									<Form.Control type="text" value={model.price} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, price: e.target.value } })} />
								</InputGroup>
							</Form.Group>
						</Modal.Body>

						<Modal.Footer className="d-flex justify-content-center">
							<div>
								<Form.Group className='d-flex me-3'>
									<Form.Label className='me-2'>{model.isActive ? 'Attivato' : 'Disattivato'}</Form.Label>
									<Form.Switch checked={model.isActive} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, isActive: e.target.checked } })} />
									<Form.Label className='me-2'>{model.isExtVisible ? 'Prenotabile Online' : 'Non prenotabile Online'}</Form.Label>
									<Form.Switch checked={model.isExtVisible} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, isExtVisible: e.target.checked } })} />
								</Form.Group>
							</div>
							<div>
								<Button variant="secondary" className="me-2" onClick={() => hideFunc()}>
									Annulla
								</Button>
								<Button variant="success" onClick={() => submitFunc(model)}>
									Modifica
								</Button>
							</div>
						</Modal.Footer>
					</>
				)}
			</Modal>
		);
	}
}

EditOggettoPrestazioneModal.contextType = LoaderContext;
