export type ReactSelectOption = {
	value: string | number
	label: string
}

export const HoursChoices: ReactSelectOption[] = [
	{ value: "07:00:00", label: "07:00" },
	{ value: "07:05:00", label: "07:05" },
	{ value: "07:10:00", label: "07:10" },
	{ value: "07:15:00", label: "07:15" },
	{ value: "07:20:00", label: "07:20" },
	{ value: "07:25:00", label: "07:25" },
	{ value: "07:30:00", label: "07:30" },
	{ value: "07:35:00", label: "07:35" },
	{ value: "07:40:00", label: "07:40" },
	{ value: "07:45:00", label: "07:45" },
	{ value: "07:50:00", label: "07:50" },
	{ value: "07:55:00", label: "07:55" },
	{ value: "08:00:00", label: "08:00" },
	{ value: "08:05:00", label: "08:05" },
	{ value: "08:10:00", label: "08:10" },
	{ value: "08:15:00", label: "08:15" },
	{ value: "08:20:00", label: "08:20" },
	{ value: "08:25:00", label: "08:25" },
	{ value: "08:30:00", label: "08:30" },
	{ value: "08:35:00", label: "08:35" },
	{ value: "08:40:00", label: "08:40" },
	{ value: "08:45:00", label: "08:45" },
	{ value: "08:50:00", label: "08:50" },
	{ value: "08:55:00", label: "08:55" },
	{ value: "09:00:00", label: "09:00" },
	{ value: "09:05:00", label: "09:05" },
	{ value: "09:10:00", label: "09:10" },
	{ value: "09:15:00", label: "09:15" },
	{ value: "09:20:00", label: "09:20" },
	{ value: "09:25:00", label: "09:25" },
	{ value: "09:30:00", label: "09:30" },
	{ value: "09:35:00", label: "09:35" },
	{ value: "09:40:00", label: "09:40" },
	{ value: "09:45:00", label: "09:45" },
	{ value: "09:50:00", label: "09:50" },
	{ value: "09:55:00", label: "09:55" },
	{ value: "10:00:00", label: "10:00" },
	{ value: "10:05:00", label: "10:05" },
	{ value: "10:10:00", label: "10:10" },
	{ value: "10:15:00", label: "10:15" },
	{ value: "10:20:00", label: "10:20" },
	{ value: "10:25:00", label: "10:25" },
	{ value: "10:30:00", label: "10:30" },
	{ value: "10:35:00", label: "10:35" },
	{ value: "10:40:00", label: "10:40" },
	{ value: "10:45:00", label: "10:45" },
	{ value: "10:50:00", label: "10:50" },
	{ value: "10:55:00", label: "10:55" },
	{ value: "11:00:00", label: "11:00" },
	{ value: "11:05:00", label: "11:05" },
	{ value: "11:10:00", label: "11:10" },
	{ value: "11:15:00", label: "11:15" },
	{ value: "11:20:00", label: "11:20" },
	{ value: "11:25:00", label: "11:25" },
	{ value: "11:30:00", label: "11:30" },
	{ value: "11:35:00", label: "11:35" },
	{ value: "11:40:00", label: "11:40" },
	{ value: "11:45:00", label: "11:45" },
	{ value: "11:50:00", label: "11:50" },
	{ value: "11:55:00", label: "11:55" },
	{ value: "12:00:00", label: "12:00" },
	{ value: "12:05:00", label: "12:05" },
	{ value: "12:10:00", label: "12:10" },
	{ value: "12:15:00", label: "12:15" },
	{ value: "12:20:00", label: "12:20" },
	{ value: "12:25:00", label: "12:25" },
	{ value: "12:30:00", label: "12:30" },
	{ value: "12:35:00", label: "12:35" },
	{ value: "12:40:00", label: "12:40" },
	{ value: "12:45:00", label: "12:45" },
	{ value: "12:50:00", label: "12:50" },
	{ value: "12:55:00", label: "12:55" },
	{ value: "13:00:00", label: "13:00" },
	{ value: "13:05:00", label: "13:05" },
	{ value: "13:10:00", label: "13:10" },
	{ value: "13:15:00", label: "13:15" },
	{ value: "13:20:00", label: "13:20" },
	{ value: "13:25:00", label: "13:25" },
	{ value: "13:30:00", label: "13:30" },
	{ value: "13:35:00", label: "13:35" },
	{ value: "13:40:00", label: "13:40" },
	{ value: "13:45:00", label: "13:45" },
	{ value: "13:50:00", label: "13:50" },
	{ value: "13:55:00", label: "13:55" },
	{ value: "14:00:00", label: "14:00" },
	{ value: "14:05:00", label: "14:05" },
	{ value: "14:10:00", label: "14:10" },
	{ value: "14:15:00", label: "14:15" },
	{ value: "14:20:00", label: "14:20" },
	{ value: "14:25:00", label: "14:25" },
	{ value: "14:30:00", label: "14:30" },
	{ value: "14:35:00", label: "14:35" },
	{ value: "14:40:00", label: "14:40" },
	{ value: "14:45:00", label: "14:45" },
	{ value: "14:50:00", label: "14:50" },
	{ value: "14:55:00", label: "14:55" },
	{ value: "15:00:00", label: "15:00" },
	{ value: "15:05:00", label: "15:05" },
	{ value: "15:10:00", label: "15:10" },
	{ value: "15:15:00", label: "15:15" },
	{ value: "15:20:00", label: "15:20" },
	{ value: "15:25:00", label: "15:25" },
	{ value: "15:30:00", label: "15:30" },
	{ value: "15:35:00", label: "15:35" },
	{ value: "15:40:00", label: "15:40" },
	{ value: "15:45:00", label: "15:45" },
	{ value: "15:50:00", label: "15:50" },
	{ value: "15:55:00", label: "15:55" },
	{ value: "16:00:00", label: "16:00" },
	{ value: "16:05:00", label: "16:05" },
	{ value: "16:10:00", label: "16:10" },
	{ value: "16:15:00", label: "16:15" },
	{ value: "16:20:00", label: "16:20" },
	{ value: "16:25:00", label: "16:25" },
	{ value: "16:30:00", label: "16:30" },
	{ value: "16:35:00", label: "16:35" },
	{ value: "16:40:00", label: "16:40" },
	{ value: "16:45:00", label: "16:45" },
	{ value: "16:50:00", label: "16:50" },
	{ value: "16:55:00", label: "16:55" },
	{ value: "17:00:00", label: "17:00" },
	{ value: "17:05:00", label: "17:05" },
	{ value: "17:10:00", label: "17:10" },
	{ value: "17:15:00", label: "17:15" },
	{ value: "17:20:00", label: "17:20" },
	{ value: "17:25:00", label: "17:25" },
	{ value: "17:30:00", label: "17:30" },
	{ value: "17:35:00", label: "17:35" },
	{ value: "17:40:00", label: "17:40" },
	{ value: "17:45:00", label: "17:45" },
	{ value: "17:50:00", label: "17:50" },
	{ value: "17:55:00", label: "17:55" },
	{ value: "18:00:00", label: "18:00" },
	{ value: "18:05:00", label: "18:05" },
	{ value: "18:10:00", label: "18:10" },
	{ value: "18:15:00", label: "18:15" },
	{ value: "18:20:00", label: "18:20" },
	{ value: "18:25:00", label: "18:25" },
	{ value: "18:30:00", label: "18:30" },
	{ value: "18:35:00", label: "18:35" },
	{ value: "18:40:00", label: "18:40" },
	{ value: "18:45:00", label: "18:45" },
	{ value: "18:50:00", label: "18:50" },
	{ value: "18:55:00", label: "18:55" },
	{ value: "19:00:00", label: "19:00" },
	{ value: "19:05:00", label: "19:05" },
	{ value: "19:10:00", label: "19:10" },
	{ value: "19:15:00", label: "19:15" },
	{ value: "19:20:00", label: "19:20" },
	{ value: "19:25:00", label: "19:25" },
	{ value: "19:30:00", label: "19:30" },
	{ value: "19:35:00", label: "19:35" },
	{ value: "19:40:00", label: "19:40" },
	{ value: "19:45:00", label: "19:45" },
	{ value: "19:50:00", label: "19:50" },
	{ value: "19:55:00", label: "19:55" },
	{ value: "20:00:00", label: "20:00" }
]

export const MinutesChoices: number[] = [
	5,
	10,
	15,
	20,
	25,
	30,
	35,
	40,
	45,
	50,
	55,
	60,
	75,
	90,
	105,
	120,
	135,
	150,
]