import axios from "axios";
import Select, { SingleValue } from "react-select";
import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { CalcSecondValueOption, FilterTimeSelect, FormatEndTime, endpoint, handleAxiosError } from "../../helpers";
import { CalendarRow, HoursChoices, OggettoPrestazioneOutput, PrenotazioneInput, ReactSelectOption, RepartoOutput } from "../../types";
import { LoaderContext } from "../../contexts";

type IProps = {
	visible: boolean;
	hideFunc: any;
	selectedDisponibilita: CalendarRow;
	pazienteId: number;
	reparto: RepartoOutput | null;
	descriptionText: string;
};

type IState = {
	startTime: string | null;
	endTime: string | null;
	selectOptions: ReactSelectOption[];
	selectedOggettoPrestazione: number;
};

export class CreatePrenotazioneModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			startTime: null,
			endTime: null,
			selectOptions: HoursChoices,
			selectedOggettoPrestazione: -1,
		};

		this.createPrenotazione = this.createPrenotazione.bind(this);
		this.handleFirstSelectChange = this.handleFirstSelectChange.bind(this);
		this.handleSecondSelectChange = this.handleSecondSelectChange.bind(this);
		this.hide = this.hide.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidUpdate(_: IProps, prevState: IState) {
		const { selectedDisponibilita } = this.props;
		const { selectedOggettoPrestazione } = this.state;

		if (prevState.selectedOggettoPrestazione !== selectedOggettoPrestazione) {
			this.setState({
				selectOptions: FilterTimeSelect(new Date(selectedDisponibilita.startTime), new Date(selectedDisponibilita.endTime)),
			});
		}
	}

	createPrenotazione() {
		const { pazienteId, descriptionText, selectedDisponibilita } = this.props;
		const { startTime, endTime, selectedOggettoPrestazione } = this.state;
		const { toggleLoader } = this.context;

		const model: PrenotazioneInput = {
			fk_Paziente: pazienteId,
			fk_Dottore: selectedDisponibilita.dottore?.id ?? 0,
			startDate: new Date(`${new Date(selectedDisponibilita?.startTime).toDateString()} ${startTime}`).toISOString(),
			endDate: new Date(`${new Date(selectedDisponibilita?.startTime).toDateString()} ${endTime}`).toISOString(),
			fk_Oggetto: Number(selectedOggettoPrestazione),
			description: descriptionText,
			refertoUrl: null,
		};

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/Prenotazione/Create`, model, { withCredentials: true })
			.then(() => {
				this.setState({ selectedOggettoPrestazione: -1, startTime: "", endTime: "" }, () => {
					window.location.href = `anagrafica?id=${pazienteId}`;
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				this.hide();
				toggleLoader(false);
			});
	}

	handleFirstSelectChange(e: SingleValue<ReactSelectOption>) {
		const { selectedOggettoPrestazione, selectOptions } = this.state;
		const { reparto, selectedDisponibilita } = this.props;
		if (e) {
			const currentTimeRange: number = reparto?.oggettiPrestazioni.find((e) => e.id === Number(selectedOggettoPrestazione))?.timeRange ?? 0;
			const newOptionToSet: ReactSelectOption | null = CalcSecondValueOption(
				new Date(`${new Date(selectedDisponibilita.startTime).toDateString()} ${e.value}`),
				currentTimeRange
			);
			if (newOptionToSet) {
				console.log(new Date(`${new Date(selectedDisponibilita.endTime).toDateString()} ${newOptionToSet.value}`).toTimeString());
				this.setState(
					{
						startTime: new Date(`${new Date(selectedDisponibilita.startTime).toDateString()} ${e.value}`).toTimeString(),
						endTime: new Date(`${new Date(selectedDisponibilita.endTime).toDateString()} ${newOptionToSet.value}`).toTimeString(),
					},
					() => {
						console.log("Formatted endTime:", FormatEndTime(new Date(`${new Date(selectedDisponibilita.endTime).toDateString()} ${newOptionToSet.value}`).toTimeString()));
						console.log(
							"Matching option:",
							selectOptions.find((e) => e.value === FormatEndTime(new Date(`${new Date(selectedDisponibilita.endTime).toDateString()} ${newOptionToSet.value}`).toTimeString()))
						);
					}
				);
			}
		}
	}

	handleSecondSelectChange(e: SingleValue<ReactSelectOption>) {
		const { selectedDisponibilita } = this.props;
		if (e) this.setState({ endTime: new Date(`${new Date(selectedDisponibilita.startTime).toDateString()} ${e.value}`).toTimeString() });
	}

	hide() {
		const { hideFunc } = this.props;

		this.setState({ selectedOggettoPrestazione: -1, startTime: null, endTime: null }, () => {
			hideFunc();
		});
	}

	render() {
		const { endTime, selectedOggettoPrestazione, selectOptions, startTime } = this.state;
		const { visible, reparto } = this.props;

		return (
			<Modal show={visible} id="createPrenotazioneModal">
				<Modal.Header>
					<Modal.Title>Vuoi creare la prenotazione?</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="pb-3">
						<Form.Label>Oggetto Prestazione</Form.Label>
						<Form.Select
							value={selectedOggettoPrestazione}
							onChange={(e: ChangeEvent<any>) => this.setState({ selectedOggettoPrestazione: e.target.value })}
						>
							<option value={-1} disabled>
								Seleziona un oggetto prestazione...
							</option>
							{reparto &&
								reparto.oggettiPrestazioni &&
								reparto.oggettiPrestazioni.filter(e => e.isActive).map((e: OggettoPrestazioneOutput, i: number) => (
									<option key={i} value={e.id}>
										{e.name} ({e.timeRange} min)
									</option>
								))}
						</Form.Select>
					</div>
					<div className="d-flex justify-content-center">
						<Select
							isDisabled={selectedOggettoPrestazione === -1}
							onChange={this.handleFirstSelectChange}
							options={selectOptions}
							placeholder="Inizio prenotazione"
							className="w-100 me-4"
						/>
						<Select
							isDisabled={selectedOggettoPrestazione === -1 || startTime === null}
							onChange={this.handleSecondSelectChange}
							options={selectOptions}
							placeholder="Fine prenotazione"
							className="w-100"
							value={endTime ? selectOptions.find((e) => e.value === FormatEndTime(endTime)) : null}
						/>
					</div>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button variant="success" onClick={() => this.createPrenotazione()}>
						Crea
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

CreatePrenotazioneModal.contextType = LoaderContext;
