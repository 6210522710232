import AWS from "aws-sdk";
import { awsBucketNameSafe, awsAccessKey, awsRegion, awsSecretKey } from "./EnvHelper";
import { handleError } from "./AxiosHelper";
import { notification } from "antd-notifications-messages";

AWS.config.update({
	accessKeyId: awsAccessKey,
	secretAccessKey: awsSecretKey,
});

export const uploadFile = async (file: File, folderName: string | null = null, namePrefix?: string): Promise<string | null> => {
	debugger;
	const MAX_SIZE_MB = 50;
	const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

	if (file.size > MAX_SIZE_BYTES) {
		notification({ type: "error", message: `La grandezza del file super il limite: ${MAX_SIZE_MB}MB` });
		return null;
	}

	const s3 = new AWS.S3({
		params: { Bucket: awsBucketNameSafe },
		region: awsRegion,
	});

	const key = `${folderName ? `${folderName}/` : ""}${namePrefix}${file.name}`;

	const params = {
		Bucket: awsBucketNameSafe,
		Key: key,
		Body: file,
	};

	await s3
		.putObject(params)
		.on("httpUploadProgress", (evt) => {
			console.log(evt.loaded + " of " + evt.total + " Bytes");
		})
		.promise();

	const url = `https://${awsBucketNameSafe}.s3.${awsRegion}.amazonaws.com/${key}`;
	return url;
};

export const getPresignedUrl = (url: string): string => {
	let presignedUrl = "";
	try {
		const s3 = new AWS.S3({
			params: { Bucket: awsBucketNameSafe },
			region: awsRegion,
		});

		// Extract the key from the URL
		const key = url.split(`${awsBucketNameSafe}.s3.${awsRegion}.amazonaws.com/`)[1];

		presignedUrl = s3.getSignedUrl("getObject", {
			Bucket: awsBucketNameSafe,
			Key: key,
			Expires: 60 * 20, // 20 minutes
		});
	} catch (error: any) {
		handleError(error);
	}

	return presignedUrl;
};
