import { Component } from "react";
import { LoaderContext } from "../../contexts";
import { Button } from "react-bootstrap";
import { APBlockType, PrenotazioneEnum } from "../../enums";
import { PrenotazioneOutput } from "../../types";
import { format } from "date-fns";
import { DeleteModal } from "..";
import { DeletePrenotazioneMessage, endpoint, getPresignedUrl, handleAxiosError } from "../../helpers";
import axios from "axios";

type IProps = {
	blockType: APBlockType;
	prenotazione: PrenotazioneOutput;
};
type IState = {
	showDeleteModal: boolean;
};

export class PrenotazioneBlock extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			showDeleteModal: false,
		};

		this.deletePrenotazione = this.deletePrenotazione.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	deletePrenotazione() {
		const { prenotazione } = this.props;
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/Prenotazione/Delete/${prenotazione.id}`, { withCredentials: true })
			.then(() => {
				window.location.reload();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const { blockType, prenotazione } = this.props;
		const { showDeleteModal } = this.state;

		return (
			<>
				<DeleteModal
					isVisible={showDeleteModal}
					submitFunc={() => this.deletePrenotazione()}
					hideFunc={() => this.setState({ showDeleteModal: false })}
					description={DeletePrenotazioneMessage}
				/>
				<div className="grey-bg w-100 my-3">
					<div className="d-flex justify-content-between p-3">
						<div className="col-8">
							<div>
								<span style={{ fontSize: "12px" }}>
									{prenotazione.status === PrenotazioneEnum.Inizializzato && "🔵 PRENOTATO"}
									{prenotazione.status === PrenotazioneEnum.Arrivato && "🟡 ARRIVATO"}
									{prenotazione.status === PrenotazioneEnum.Concluso && "✅ CONCLUSO"}
								</span>
							</div>
							<div>
								<span style={{ fontSize: "30px", fontWeight: "700" }}>
									{prenotazione.dottore.name} {prenotazione.dottore.surname}
								</span>
							</div>
							<div>
								<span>
									{format(new Date(prenotazione.startDate), "dd/MM/yyyy")} - {format(new Date(prenotazione.startDate), "HH:mm")}
								</span>
							</div>
							<div>
								<div>
									<strong className="fs-5">{prenotazione.oggetto.name}</strong>
								</div>
								<div>
									<span className="text-dark">{prenotazione.description}</span>
								</div>
								{prenotazione.refertoUrl && (
									<div className="mt-3">
										<Button onClick={() => window.open(getPresignedUrl(prenotazione.refertoUrl ?? ""), "_blank")}>
											Visualizza Referto
										</Button>
									</div>
								)}
							</div>
							<div className="mt-3" style={{ fontSize: "12px" }}>
								<div>
									<strong>
										Aggiunto il {format(new Date(prenotazione.createdDate), "dd/MM/yyyy")} alle {format(new Date(prenotazione.createdDate), "HH:mm")}
									</strong>
									{prenotazione.createdBy ? (
										<strong>
											da {prenotazione.createdBy.name} {prenotazione.createdBy.surname}
										</strong>
									) : (
										<strong>dall'utente su grmedical.it</strong>
									)}
								</div>
							</div>
						</div>
						{blockType === APBlockType.Prossime && (
							<div className="col-4">
								<div className="text-end">
									<div className="mt-3">
										<Button className="w-75" variant="danger" onClick={() => this.setState({ showDeleteModal: true })}>
											CANCELLA
										</Button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}

PrenotazioneBlock.contextType = LoaderContext;
