import axios from "axios";
import Calendar from "react-calendar";
import { Component } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { LoaderContext, UserContext, UserDefaultType } from "../contexts";
import { format, isBefore, isSameDay, subDays, subMonths } from "date-fns";
import { CalendarTile, DisponibilitaOutput, DoctorStats, PrenotazioneStat, UserOutput } from "../types";
import { DeleteUserMessage, endpoint, handleAxiosError } from "../helpers";
import { DeleteModal, StatsTimeRangeModal } from "../components";
import { BsGraphUp } from "react-icons/bs";
import { notification } from "antd-notifications-messages";

enum SelectedStatsEnum {
	LastMonth,
	ThisMonth,
	Custom,
}

type TimeRange = {
	start: string;
	end: string;
};

type IProps = {};

type IState = {
	user: UserOutput | null;
	allDisponibilita: DisponibilitaOutput[];
	disponibilita: DisponibilitaOutput[];
	selectedDate: Date;
	showDeleteModal: boolean;
	selectedStatsType: SelectedStatsEnum;
	statsTimeRange: TimeRange;
	showModalCustomStats: boolean;
	doctorStats: DoctorStats | null;
};

const searchParams = new URLSearchParams(window.location.search);

export class AnagraficaUser extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		const initialStatsTimeRange = this.getMonth(false);
		this.state = {
			user: null,
			allDisponibilita: [],
			disponibilita: [],
			selectedDate: new Date(),
			showDeleteModal: false,
			selectedStatsType: SelectedStatsEnum.LastMonth,
			statsTimeRange: initialStatsTimeRange,
			showModalCustomStats: false,
			doctorStats: null,
		};

		this.tileClasseName = this.tileClasseName.bind(this);
		this.getDisponibilita = this.getDisponibilita.bind(this);
		this.getUser = this.getUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.getStats = this.getStats.bind(this);
		this.selectDate = this.selectDate.bind(this);
		this.refreshPassword = this.refreshPassword.bind(this);
		this.getAllDisponibilita = this.getAllDisponibilita.bind(this);
		this.getMonth = this.getMonth.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getUser();
		this.getDisponibilita();
		this.getAllDisponibilita();
	}

	getMonth(currentMonth: boolean): TimeRange {
		if (currentMonth) {
			// Get the first day of the current month
			const firstDayOfCurrentMonth = new Date();
			firstDayOfCurrentMonth.setDate(1);

			// Get the last day of the current month
			const lastDayOfCurrentMonth = new Date();
			lastDayOfCurrentMonth.setMonth(lastDayOfCurrentMonth.getMonth() + 1);
			lastDayOfCurrentMonth.setDate(0);

			return {
				start: firstDayOfCurrentMonth.toISOString(),
				end: lastDayOfCurrentMonth.toISOString(),
			};
		} else {
			const firstDayOfLastMonth = new Date();
			firstDayOfLastMonth.setMonth(firstDayOfLastMonth.getMonth() - 1);
			firstDayOfLastMonth.setDate(1);

			// Get the last day of the previous month
			const lastDayOfLastMonth = new Date();
			lastDayOfLastMonth.setDate(0);

			return {
				start: firstDayOfLastMonth.toISOString(),
				end: lastDayOfLastMonth.toISOString(),
			};
		}
	}

	getUser() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<UserOutput>(`${endpoint}/api/User/GetById/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ user: data }, () => {
					if (data.roles.includes("Dottore")) this.getStats();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	selectDate(value: Date) {
		this.setState({ selectedDate: value }, () => this.getDisponibilita());
	}

	tileClasseName({ date, view }: CalendarTile) {
		const { selectedDate, allDisponibilita } = this.state;
		let classApplied: string = "";
		if (view === "month") {
			if (selectedDate && isSameDay(selectedDate, date)) {
				classApplied += "highlight ";
			}

			if (allDisponibilita && allDisponibilita.length > 0) {
				allDisponibilita.forEach((e: DisponibilitaOutput) => {
					if (isSameDay(new Date(e.startDate), date)) {
						classApplied += "highlight-available ";
					}
				});
			}

			if (isBefore(date, subDays(new Date(), 1))) {
				classApplied += "before-date";
			}
		}
		return classApplied;
	}

	getDisponibilita() {
		const { selectedDate } = this.state;

		axios
			.post(`${endpoint}/api/Disponibilita/GetAllByCalendarAndByDottore/${searchParams.get("id")}`, { selectedDate }, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ disponibilita: data });
			})
			.catch(handleAxiosError);
	}

	getAllDisponibilita() {
		axios
			.get(`${endpoint}/api/Disponibilita/GetAllByDottore/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ allDisponibilita: data });
			})
			.catch(handleAxiosError);
	}

	deleteUser() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/User/delete/${searchParams.get("id")}`, { withCredentials: true })
			.then(() => (window.location.href = "/anagrafiche"))
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getStats() {
		const { toggleLoader } = this.context;
		const { statsTimeRange } = this.state;

		toggleLoader(true);
		axios
			.get<DoctorStats>(`${endpoint}/api/User/GetStatsByPeriod/${statsTimeRange.start}/${statsTimeRange.end}/${searchParams.get("id")}`, {
				withCredentials: true,
			})
			.then(({ data }) => {
				this.setState({ doctorStats: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	refreshPassword() {
		const { toggleLoader } = this.context;
		const { user } = this.state;

		toggleLoader(true);
		if (!user) return notification({ title: "Errore", message: "Utente non trovato" });

		axios
			.get(`${endpoint}/api/User/RefreshPassword/${user.id}`, { withCredentials: true })
			.then(() => {
				notification({ title: "Password inviata", message: `La nuova password è stata inviata all'indirizzo email ${user.email}` });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const { user, showDeleteModal, showModalCustomStats, selectedStatsType, disponibilita, doctorStats, statsTimeRange } = this.state;

		return (
			<div id="profilePage" className="main-color">
				<>
					<StatsTimeRangeModal
						isVisible={showModalCustomStats}
						submitFunc={(e: TimeRange) => this.setState({ statsTimeRange: e, showModalCustomStats: false }, () => this.getStats())}
						hideFunc={() =>
							this.setState(
								{
									selectedStatsType: SelectedStatsEnum.LastMonth,
									showModalCustomStats: false,
									statsTimeRange: { start: subMonths(new Date(), 1).toISOString(), end: new Date().toISOString() },
								},
								() => this.getStats()
							)
						}
					/>
					<DeleteModal
						isVisible={showDeleteModal}
						submitFunc={() => this.deleteUser()}
						hideFunc={() => this.setState({ showDeleteModal: false })}
						description={DeleteUserMessage}
					/>
					{user && (
						<div className="container mt-2 mt-md-5">
							<div className="d-flex justify-content-between">
								<div className="col-12 col-md-7">
									<div className="my-5">
										<h1>
											<span className="me-2">
												{user.name} {user.surname}
											</span>
											{user.roles && user.roles.map((e: string, i: number) => <Button key={i}>{e.toUpperCase()}</Button>)}
										</h1>
									</div>
									<p>
										<strong>Email: {user.email}</strong>
									</p>
									<p>
										<strong>Telefono: {user.phoneNumber}</strong>
									</p>
									<p>
										<strong>Codice Fiscale: {user.codiceFiscale}</strong>
									</p>
									<div className="d-flex-inline d-md-flex mt-5">
										<UserContext.Consumer>
											{(currentUser: UserDefaultType) =>
												currentUser.roles &&
												((currentUser.roles.includes("Admin") && user.roles.includes("Dottore")) ||
													currentUser.roles.includes("SuperAdmin")) && (
													<>
														<Button
															style={{ fontSize: "12px" }}
															className="me-0 me-md-3 mt-2 mt-md-0 widthCustomAutoTo100"
															variant="outline-dark"
															onClick={() => this.refreshPassword()}
														>
															INVIA NUOVA PASSWORD
														</Button>
														<Button
															style={{ fontSize: "12px" }}
															className="me-0 me-md-3 mt-2 mt-md-0 widthCustomAutoTo100"
															variant="outline-dark"
															href={`/editAnagrafica?id=${user.id}&isUser=1`}
														>
															MODIFICA PROFILO
														</Button>
														<Button
															style={{ fontSize: "12px" }}
															className="me-0 me-md-3 mt-2 mt-md-0 widthCustomAutoTo100"
															variant="danger"
															onClick={() => this.setState({ showDeleteModal: true })}
														>
															ELIMINA PROFILO
														</Button>
														<Button
															className="mt-2 mt-md-0 widthCustomAutoTo100"
															style={{ fontSize: "12px" }}
															variant="success"
															href={`/disponibilita?id=${user.id}`}
														>
															MODIFICA DISPONIBILITÀ
														</Button>
													</>
												)
											}
										</UserContext.Consumer>
									</div>
									{doctorStats !== null && (
										<div className="my-5 me-5">
											<div className="d-flex align-items-center justify-content-between">
												<div>
													<strong className="d-flex align-items-center fs-4">
														<BsGraphUp className="me-3" /> Statistiche
													</strong>
												</div>
												<div>
													<ButtonGroup>
														<Button
															variant="outline-dark"
															active={selectedStatsType === SelectedStatsEnum.LastMonth}
															onClick={() =>
																this.setState(
																	{
																		selectedStatsType: SelectedStatsEnum.LastMonth,
																		statsTimeRange: this.getMonth(false),
																	},
																	() => this.getStats()
																)
															}
														>
															Mese Scorso
														</Button>
														<Button
															variant="outline-dark"
															active={selectedStatsType === SelectedStatsEnum.ThisMonth}
															onClick={() =>
																this.setState(
																	{
																		selectedStatsType: SelectedStatsEnum.ThisMonth,
																		statsTimeRange: this.getMonth(true),
																	},
																	() => this.getStats()
																)
															}
														>
															Questo Mese
														</Button>
														<Button
															variant="outline-dark"
															active={selectedStatsType === SelectedStatsEnum.Custom}
															onClick={() =>
																this.setState(
																	{ selectedStatsType: SelectedStatsEnum.Custom, showModalCustomStats: true },
																	() => this.getStats()
																)
															}
														>
															Seleziona
														</Button>
													</ButtonGroup>
												</div>
											</div>
											<div>
												<div className="d-flex row">
													{statsTimeRange.start !== "" && statsTimeRange.end !== "" && (
														<div className="col-12 my-4">
															<div>
																<u>
																	<strong>
																		Statistiche prestazioni effettuate dal{" "}
																		{format(new Date(statsTimeRange.start), "dd/MM/yyyy")} al{" "}
																		{format(new Date(statsTimeRange.end), "dd/MM/yyyy")}
																	</strong>
																</u>
															</div>
														</div>
													)}

													{doctorStats.oggettiPrestazioniStats.length > 0 ? (
														doctorStats.oggettiPrestazioniStats.map((e: PrenotazioneStat, i: number) => (
															<div className="col-6 pb-3">
																<div>
																	<strong>{e.oggetto}</strong>
																</div>
																<div>
																	<div>
																		<span>Pazienti prenotati: {e.prenotati}</span>
																	</div>
																	<div>
																		<span>Visite completate: {e.completati}</span>
																	</div>
																	<div>
																		<span>Visite completate convenzionate: {e.convenzionati}</span>
																	</div>
																	<div>
																		<span>Visite eliminate: {e.eliminati}</span>
																	</div>
																</div>
															</div>
														))
													) : (
														<div className="mt-5">
															<span>Nessuna visita specialistica effettuata...</span>
														</div>
													)}
												</div>
												{doctorStats.visiteInfermieristiche === 0 && doctorStats.visiteMedicoCompetente === 0 ? (
													<div className="border-top mt-3 pt-3">
														<span>Nessuna visita MDL effettuta...</span>
													</div>
												) : (
													<div className="border-top mt-3 pt-3">
														<div>
															<span>Visite infermieristiche: {doctorStats.visiteInfermieristiche}</span>
														</div>
														<div>
															<span>Visite medico competente: {doctorStats.visiteMedicoCompetente}</span>
														</div>
													</div>
												)}
											</div>
										</div>
									)}
								</div>

								{user.roles && !(user.roles.includes("Admin") || user.roles.includes("SuperAdmin")) && (
									<div className="col-12 col-md-5">
										<div>
											<div>
												<Calendar tileClassName={this.tileClasseName} onClickDay={(e: Date) => this.selectDate(e)} />
											</div>
											<div className="mt-3">
												{disponibilita.map((e: DisponibilitaOutput, i: number) => (
													<div key={i} className="py-1 my-3 rounded" style={{ backgroundColor: "#EFEFEF" }}>
														<div className="d-flex justify-content-between align-items-center px-4 py-2">
															<div>
																<strong>{format(new Date(e.startDate), "dd/MM/yyyy")}</strong>
															</div>
															<div>
																<span>
																	{format(new Date(e.startDate), "HH:mm")} - {format(new Date(e.endDate), "HH:mm")}
																</span>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)}
				</>
			</div>
		);
	}
}

AnagraficaUser.contextType = LoaderContext;
