import axios from "axios";
import { Component } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { LoaderContext, UserContext, UserDefaultType } from "../../contexts";
import { endpoint, handleAxiosError } from "../../helpers";

type IProps = {};
type IState = {};

export class NavbarComponent extends Component<IState, IProps> {
	constructor(props: IProps) {
		super(props);
		this.state = {};

		this.logout = this.logout.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	logout() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/User/Logout`, null, { withCredentials: true })
			.then(() => {
				window.location.href = "/";
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		return (
			<Navbar style={{ boxShadow: "0px 0px 10px 1px grey" }} expand="lg">
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<div className="container">
							<Navbar.Brand className="widthCustomAutoTo50">
								<a href="/">
									{userContext.roles?.includes("Admin") || userContext.roles.includes("SuperAdmin") ? (
										<img
											src="https://testbucketgr.s3.eu-west-3.amazonaws.com/static/amministrazione.svg"
											style={{ width: "300px" }}
											alt="navbarLogo"
											className="widthCustomAutoTo100"
										/>
									) : (
										<img
											src="https://testbucketgr.s3.eu-west-3.amazonaws.com/static/medici.svg"
											style={{ width: "300px" }}
											alt="navbarLogo"
											className="widthCustomAutoTo100"
										/>
									)}
								</a>
								{userContext.isLogged &&
									userContext.roles &&
									!(userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
										<Button className="ms-3" variant="primary" href="/sale">
											{userContext.sala ? userContext.sala.name : "FUORI UFFICIO"}
										</Button>
									)}
							</Navbar.Brand>
							{userContext.isLogged && userContext.roles && (
								<>
									<Navbar.Toggle aria-controls="basic-navbar-nav" />
									<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
										<Nav>
											{userContext.roles.includes("Dottore") &&
												!(userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
													<>
														<Nav.Link href="/">Home</Nav.Link>
														<Nav.Link href="/prenotazioni">Prenotazioni</Nav.Link>
														<Nav.Link href="/anagrafiche">Anagrafiche</Nav.Link>
														{userContext.isMdl && <Nav.Link href="/visiteMDL">Visite MDL</Nav.Link>}
														{userContext.isAbilitatoPrelievo && <Nav.Link href="/prelievi">Prelievi</Nav.Link>}
														<Nav.Link href="/profile">
															{userContext.name} {userContext.surname}
														</Nav.Link>
														<Nav.Link href="#" onClick={() => this.logout()}>
															Esci
														</Nav.Link>
													</>
												)}

											{(userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
												<>
													<Nav.Link href="/">Home</Nav.Link>
													<Nav.Link href="/calendari">Calendari</Nav.Link>
													<Nav.Link href="/prenotazioni">Prenotazioni</Nav.Link>
													<Nav.Link href="/anagrafiche">Anagrafiche</Nav.Link>
													<Nav.Link href="/gestione">Gestione Studio</Nav.Link>
													<Nav.Link href="/profile">
														{userContext.name} {userContext.surname}
													</Nav.Link>
													<Nav.Link href="#" onClick={() => this.logout()}>
														Esci
													</Nav.Link>
												</>
											)}
										</Nav>
									</Navbar.Collapse>
								</>
							)}
						</div>
					)}
				</UserContext.Consumer>
			</Navbar>
		);
	}
}

NavbarComponent.contextType = LoaderContext;
