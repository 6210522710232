import axios from "axios";
import Select, { SingleValue } from "react-select";
import { Component } from "react";
import { Button } from "react-bootstrap";
import Calendar from "react-calendar";
import { CalendarTile, DisponibilitaOutput, DisponibilitaInput, HoursChoices, ReactSelectOption } from "../types";
import { notification } from "antd-notifications-messages";
import { endpoint, FilterTimeSelectFromWhen, handleAxiosError } from "../helpers";
import { format, isBefore, isSameDay, subDays } from "date-fns";
import { LoaderContext } from "../contexts";
import { DisponibilitaModal } from "../components";

type IProps = {};
type IState = {
	filteredAllDisponibilita: DisponibilitaOutput[];
	disponibilita: DisponibilitaOutput[];
	selectedDates: Date[];
	dataInizio: Date | null;
	dataFine: Date | null;
	model: DisponibilitaInput;
	modalVisible: boolean;
	selectedDisponibilitaModal: number;
};

const searchParams = new URLSearchParams(window.location.search);

export class DisponibilitaPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			filteredAllDisponibilita: [],
			disponibilita: [],
			selectedDates: [],
			dataInizio: null,
			dataFine: null,
			model: {
				userId: -1,
				selectedDates: [],
				startTime: new Date().toISOString(),
				endTime: new Date().toISOString(),
			},
			modalVisible: false,
			selectedDisponibilitaModal: -1,
		};

		this.getDisponibilita = this.getDisponibilita.bind(this);
		this.tileClasseName = this.tileClasseName.bind(this);
		this.selectDate = this.selectDate.bind(this);
		this.createDisponibilita = this.createDisponibilita.bind(this);
		this.showDeleteModal = this.showDeleteModal.bind(this);
		this.getAllDisponibilita = this.getAllDisponibilita.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	showDeleteModal(value: number) {
		this.setState({ selectedDisponibilitaModal: value, modalVisible: true });
	}

	createDisponibilita() {
		const { selectedDates, dataInizio, dataFine } = this.state;
		const { toggleLoader } = this.context;

		if (dataInizio && dataFine) {
			const submitModel: DisponibilitaInput = {
				userId: Number(searchParams.get("id")),
				selectedDates: selectedDates.map((e: Date) => e.toISOString()),
				startTime: new Date(dataInizio).toISOString(),
				endTime: new Date(dataFine).toISOString(),
			};

			toggleLoader(true);
			axios
				.post(`${endpoint}/api/Disponibilita/Create`, submitModel, {
					withCredentials: true,
				})
				.then(() => {
					window.location.reload();
				})
				.catch(handleAxiosError)
				.finally(() => {
					toggleLoader(false);
				});
		} else {
			notification({ message: "Orario di fine dopo quella d'inizio", type: "error" });
		}
	}

	tileClasseName({ date, view }: CalendarTile) {
		const { selectedDates, filteredAllDisponibilita } = this.state;
		let classApplied: string = "";
		if (view === "month") {
			selectedDates.forEach((e: Date) => {
				if (selectedDates && isSameDay(e, date)) {
					classApplied += "highlight ";
				}
			});

			if (filteredAllDisponibilita && filteredAllDisponibilita.length > 0) {
				filteredAllDisponibilita.forEach((e: DisponibilitaOutput) => {
					if (isSameDay(new Date(e.startDate), date)) {
						classApplied += "highlight-available ";
					}
				});
			}

			if (isBefore(date, subDays(new Date(), 1))) {
				classApplied += "before-date";
			}
		}
		return classApplied;
	}

	selectDate(value: Date) {
		const { selectedDates } = this.state;

		if (!isBefore(value, subDays(new Date(), 1))) {
			const isDateIncluded: boolean = selectedDates.some((e: Date) => e.getTime() === value.getTime());

			if (isDateIncluded) {
				const newSelectedDates: Date[] = selectedDates.filter((e) => e.getTime() !== value.getTime());
				this.setState({ selectedDates: newSelectedDates });
			} else {
				this.setState({ selectedDates: [...selectedDates, value] });
			}
		}
	}

	getDisponibilita() {
		const { toggleLoader } = this.context;
		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Disponibilita/GetAllFromTodayByUserId/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ disponibilita: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAllDisponibilita() {
		const { toggleLoader } = this.context;
		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Disponibilita/GetAllByDottore/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ filteredAllDisponibilita: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	componentDidMount() {
		this.getDisponibilita();
		this.getAllDisponibilita();
	}

	render() {
		const { disponibilita, selectedDates, modalVisible, selectedDisponibilitaModal, dataInizio } = this.state;
		return (
			<>
				<DisponibilitaModal visible={modalVisible} selectedDisponibilita={selectedDisponibilitaModal} hideFunc={() => this.setState({ modalVisible: false })} />
				<div id="disponibilitaPage" className="container mb-5">
					<div className="d-flex mt-5">
						<div className="col-12 col-md-4">
							<div className="pe-3">
								<Calendar className="small-calendar-tiles" tileClassName={this.tileClasseName} onClickDay={(e: Date) => this.selectDate(e)} />
							</div>
							{selectedDates && (
								<div className="my-2 border rounded">
									<div
										className="main-bg text-light p-2"
										style={{
											letterSpacing: "3px",
											borderRadius: "5px 5px 0px 0px",
										}}
									>
										<span className="text-light ms-3" style={{ fontSize: "12px" }}>
											AGGIUNGI NUOVA DISPONIBILITÀ
										</span>
									</div>
									<div className="p-3">
										<div className="d-flex justify-content-center" style={{ fontSize: "12px" }}>
											<Select
												onChange={(e: SingleValue<ReactSelectOption>) => {
													this.setState({ dataInizio: new Date(e ? `${new Date().toDateString()} ${e.value}` : "") });
												}}
												options={HoursChoices}
												placeholder="Inizio Disponibilità"
												className="w-100 me-4"
											/>

											<Select
												onChange={(e: SingleValue<ReactSelectOption>) => {
													this.setState({ dataFine: new Date(e ? `${new Date().toDateString()} ${e.value}` : "") });
												}}
												options={FilterTimeSelectFromWhen(dataInizio ?? new Date())}
												placeholder="Fine Disponibilità"
												className="w-100 me-4"
												isDisabled={dataInizio === null}
											/>
										</div>
										<div className="d-flex justify-content-end mt-3">
											<Button className="h-auto p-2" onClick={() => this.createDisponibilita()} style={{ fontSize: "12px" }}>
												CREA
											</Button>
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="col-12 col-md-8">
							<div className="ps-3">
								<div>
									<h5>Prossime presenze pianificate per {}</h5>
									<p>Non puoi eliminare presenze su cui siano già pianificate prenotazioni.</p>
								</div>
								<div>
									{disponibilita.map((e: DisponibilitaOutput, i: number) => (
										<div key={i} className="d-flex justify-content-between align-items-center py-1 my-3 rounded" style={{ backgroundColor: "#EFEFEF" }}>
											<div className="ps-4 p-1">
												<span>
													<strong>{format(new Date(e.startDate), "dd/MM/yyyy")}</strong> {format(new Date(e.startDate), "HH:mm")} - {format(new Date(e.endDate), "HH:mm")}
												</span>
											</div>
											<div className="p-1">
												<Button style={{ fontSize: "12px" }} className="mx-3" variant="danger" onClick={() => this.showDeleteModal(e.id)}>
													X
												</Button>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

DisponibilitaPage.contextType = LoaderContext;
