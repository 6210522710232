import axios from 'axios';
import { ChangeEvent, Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import { BiCheckboxChecked, BiEdit, BiPlusCircle, BiText, BiToggleRight, BiTrash } from 'react-icons/bi';
import { CreateNewSectionSAModal } from '../components';
import { RepartoOutput, SchedaAnamnesticaSectionInput, SchedaAnamnesticaInput, SchedaAnamnesticaSectionMultiResponseInputDto } from '../types';
import { SASelectedType } from '../enums';
import { BsCalendarDate, BsTextLeft } from 'react-icons/bs';
import { endpoint, handleAxiosError } from '../helpers';
import { LoaderContext } from '../contexts';

type IProps = {};
type IState = {
    isModalVisible: boolean
    title: string;
    sections: SchedaAnamnesticaSectionInput[];
    selectedIndex: number;
    editedSection: SchedaAnamnesticaSectionInput | null;
    reparti: RepartoOutput[];
    fk_Reparto: number;
    isActive: boolean;
};

export class CreateSchedaAnamnestica extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            isModalVisible: false,
            title: '',
            sections: [],
            selectedIndex: 0,
            editedSection: null,
            reparti: [],
            fk_Reparto: -1,
            isActive: true
        };

        this.submit = this.submit.bind(this);
        this.addSection = this.addSection.bind(this);
        this.removeSection = this.removeSection.bind(this);
        this.editSection = this.editSection.bind(this);
        this.createSA = this.createSA.bind(this);
        this.getAllReparti = this.getAllReparti.bind(this);
    }

    context!: React.ContextType<typeof LoaderContext>

    componentDidMount() {
        this.getAllReparti();
    }

    submit(section: SchedaAnamnesticaSectionInput) {
        const { editedSection } = this.state;
        if (editedSection === null)
            this.addSection(section);
        else
            this.editSection(section);
    }

    addSection = (section: SchedaAnamnesticaSectionInput) => {
        const { sections, selectedIndex } = this.state;
        sections.splice(selectedIndex, 0, section);
        this.setState({ sections });
    }

    removeSection = (index: number) => {
        const { sections } = this.state;
        sections.splice(index, 1);
        this.setState({ sections });
    }

    editSection = (section: SchedaAnamnesticaSectionInput) => {
        const { sections, selectedIndex } = this.state;
        sections[selectedIndex] = section;
        this.setState({ sections });
    }

    getAllReparti = () => {
        const { toggleLoader } = this.context;

        toggleLoader(true)
        axios.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAllForDropdown`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ reparti: data });
            })
            .catch(handleAxiosError)
            .finally(() => {
                toggleLoader(false)
            })
    }

    createSA = () => {
        const { title, sections, fk_Reparto, isActive } = this.state;
        const { toggleLoader } = this.context;

        const model: SchedaAnamnesticaInput = {
            title,
            sections,
            fk_Reparto,
            isActive
        }

        toggleLoader(true)
        axios.post(`${endpoint}/api/SchedaAnamnestica/Create`, model, { withCredentials: true })
            .then(() => {
                window.location.href = '/gestione';
            })
            .catch(handleAxiosError)
            .finally(() => {
                toggleLoader(false)
            })
    }

    render() {
        const { editedSection, isModalVisible, title, sections, reparti, fk_Reparto, isActive } = this.state;
        return (
            <div id="CreateSchedaAnamnestica">
                <CreateNewSectionSAModal
                    editedSection={editedSection}
                    isVisible={isModalVisible}
                    submitFunc={(e: SchedaAnamnesticaSectionInput) => this.submit(e)}
                    hideFunc={() => this.setState({ isModalVisible: false })}
                    clearEditSection={() => this.setState({ editedSection: null })}
                />
                <div className='container'>
                    <div className='row d-flex'>
                        <div className='col-12 col-md-6'>
                            <div className='mt-5'>
                                <div>
                                    <strong className='main-color fs-1' >Nuova Scheda Anamnestica</strong>
                                </div>
                                <div className='mt-2'>
                                    <Form.Control
                                        type='text'
                                        placeholder='Nome della scheda'
                                        value={title}
                                        onChange={(e: ChangeEvent<any>) => this.setState({ title: e.target.value })}
                                    />
                                </div>
                                <div className='mt-2'>
                                    <Form.Select
                                        value={fk_Reparto}
                                        onChange={(e: ChangeEvent<any>) => this.setState({ fk_Reparto: Number(e.target.value) })}
                                    >
                                        <option value={-1}>Seleziona un reparto</option>
                                        {reparti.map((e: RepartoOutput, i: number) => (
                                            <option key={i} value={e.id}>{e.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>

                                <div className='p-1'>
                                    {sections.map((e: SchedaAnamnesticaSectionInput, i: number) => (
                                        <div key={i}>
                                            <div className='my-3 d-flex justify-content-between align-items-center'>
                                                <div className='main-bg w-100 me-3' style={{ height: '3px' }} />
                                                <div className='main-bg rounded-circle' style={{ cursor: 'pointer' }} onClick={() => this.setState({ isModalVisible: true, selectedIndex: i })}>
                                                    <BiPlusCircle className='text-white m-2 fs-5' />
                                                </div>
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center main-bg text-white py-2 px-3 fs-5'>
                                                    <div>{e.selectedType != null && (
                                                        e.selectedType === SASelectedType.SiNo ? (
                                                            <span className='d-flex align-items-center'>
                                                                <BiToggleRight className="fs-3 me-2" />Sì / No
                                                            </span>
                                                        ) : e.selectedType === SASelectedType.RispostaMultipla ? (
                                                            <span className='d-flex align-items-center'>
                                                                <BiCheckboxChecked className="fs-3 me-2" />Risposta multipla
                                                            </span>
                                                        ) : e.selectedType === SASelectedType.RispostaBreve ? (
                                                            <span className='d-flex align-items-center'>
                                                                <BsTextLeft className="fs-3 me-2" />Risposta Breve
                                                            </span>
                                                        ) : e.selectedType === SASelectedType.RispostaLunga ? (
                                                            <span className='d-flex align-items-center'>
                                                                <BsTextLeft className="fs-3 me-2" />Risposta Lunga
                                                            </span>
                                                        ) : e.selectedType === SASelectedType.Data ? (
                                                            <span className='d-flex align-items-center'>
                                                                <BsCalendarDate className="fs-5 me-2" />Data
                                                            </span>
                                                        ) : e.selectedType === SASelectedType.TitoloSezione && (
                                                            <span className='d-flex align-items-center'>
                                                                <BiText className="fs-5 me-2" />Titolo Sezione
                                                            </span>
                                                        )
                                                    )}
                                                    </div>
                                                    <div>
                                                        <Button variant='danger' className='me-2' onClick={() => this.removeSection(i)}>
                                                            <BiTrash />
                                                        </Button>
                                                        <Button className='main-color' variant='light' onClick={() => this.setState({ isModalVisible: true, editedSection: sections[i], selectedIndex: i })}>
                                                            <BiEdit />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='grey-bg main-color p-3'>
                                                    <strong>{e.question}</strong>
                                                    <div className='ms-3'>
                                                        {e.sectionMultiResponses.map((e: SchedaAnamnesticaSectionMultiResponseInputDto, i: number) => (
                                                            <div key={i} className='mt-2'>
                                                                <strong>
                                                                    {e.sectionResponse}
                                                                </strong>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='d-flex justify-content-center mt-5'>
                                    <div
                                        className='main-bg rounded-circle'
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.setState({ isModalVisible: true, selectedIndex: sections.length })}
                                    >
                                        <BiPlusCircle className='text-white m-2 fs-3' />
                                    </div>
                                </div>
                                {sections.length === 0 && (
                                    <div className='text-center mt-3' style={{ lineHeight: '20px' }}>
                                        <div>
                                            <span>Nessun modulo presente.</span>
                                        </div>
                                        <div>
                                            <span className="d-flex justify-content-center align-items-center">Per aggiungere un modulo a questa scheda clicca sul <strong className='ms-2 fs-3'>+</strong></span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='d-flex justify-content-between mt-5'>
                                <Button variant='danger' onClick={() => window.history.back()}>
                                    Annulla
                                </Button>
                                <div className='d-flex align-items-center'>
                                    <Form.Group className='d-flex me-3'>
                                        <Form.Label className='me-2'>{isActive ? 'Attivato' : 'Disattivato'}</Form.Label>
                                        <Form.Switch checked={isActive} onChange={(e: ChangeEvent<any>) => this.setState({ isActive: e.target.checked })} />
                                    </Form.Group>
                                    <Button variant='success' onClick={() => this.createSA()}>
                                        Salva
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}

CreateSchedaAnamnestica.contextType = LoaderContext;