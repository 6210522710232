import axios from "axios";
import { ChangeEvent, Component } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsPersonFillAdd } from "react-icons/bs";
import { LoaderContext, UserContext, UserDefaultType } from "../contexts";
import { AnagraficheList, PazienteOutput } from "../types";
import { endpoint, handleAxiosError } from "../helpers";
import { debounce } from "lodash";

enum AnagraficaType {
	Paziente,
	Medico,
	Amministrativo,
}

type IProps = {};
type IState = {
	anagraficaType: AnagraficaType;
	searchString: string;
	anagrafiche: PazienteOutput[];
	take: number;
	anagraficheTotali: number;
	currentPage: number;
};

export class AnagrafichePage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			anagraficaType: AnagraficaType.Paziente,
			searchString: "",
			anagrafiche: [],
			take: 20,
			anagraficheTotali: 0,
			currentPage: 0,
		};

		this.searchBarGetAnagrafiche = debounce(this.searchBarGetAnagrafiche, 400);
		this.getAnagrafiche = this.getAnagrafiche.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.previousPage = this.previousPage.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getAnagrafiche();
	}

	searchBarGetAnagrafiche() {
		this.getAnagrafiche();
	}

	componentDidUpdate(_: Readonly<IProps>, prevState: Readonly<IState>): void {
		const { searchString } = this.state;
		if (prevState.searchString !== searchString) this.searchBarGetAnagrafiche();
	}

	getAnagrafiche() {
		const { anagraficaType, take, currentPage, searchString } = this.state;

		let typeFilter: string = "Paziente/GetAll";

		switch (anagraficaType) {
			case AnagraficaType.Paziente:
				typeFilter = "Paziente/GetAll";
				break;
			case AnagraficaType.Medico:
				typeFilter = "User/GetAllDottori";
				break;
			case AnagraficaType.Amministrativo:
				typeFilter = "User/GetAllAmministrativi";
				break;
		}

		const skip: number = currentPage * take;

		axios
			.get<AnagraficheList>(`${endpoint}/api/${typeFilter}/${take}/${skip}/${searchString}`, {
				withCredentials: true,
			})
			.then(({ data }) => {
				this.setState({ anagrafiche: data.data, anagraficheTotali: data.count });
			})
			.catch(handleAxiosError);
	}

	nextPage() {
		const { anagraficheTotali, currentPage, take } = this.state;
		const maxPage: number = Math.ceil(anagraficheTotali / take);
		if (currentPage + 1 < maxPage) this.setState({ currentPage: currentPage + 1 }, () => this.getAnagrafiche());
	}

	previousPage() {
		const { currentPage } = this.state;
		if (currentPage > 0) this.setState({ currentPage: currentPage - 1 }, () => this.getAnagrafiche());
	}

	render() {
		const { anagrafiche, anagraficaType, take, searchString, currentPage, anagraficheTotali } = this.state;

		const maxPage: number = Math.ceil(anagraficheTotali / take);

		return (
			<div id="anagrafichePage" className="main-color">
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<div className="container mt-5 text-center text-md-start">
							<div className="d-flex-inline d-md-flex justify-content-between">
								<h1>
									<strong>Elenco anagrafiche</strong>
								</h1>
								<Form.Group className="d-flex-inline d-md-flex justify-content-between mt-3 mt-md-0">
									{userContext.roles && (userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
										<Form.Select
											onChange={(e: ChangeEvent<any>) =>
												this.setState(
													{
														anagraficaType: Number(e.target.value),
														searchString: "",
														currentPage: 0,
													},
													() => this.getAnagrafiche()
												)
											}
											className="me-3"
											value={anagraficaType}
										>
											<option value={AnagraficaType.Paziente}>Pazienti</option>
											<option value={AnagraficaType.Medico}>Dottori</option>
											<option value={AnagraficaType.Amministrativo}>Amministrativi</option>
										</Form.Select>
									)}
									<InputGroup className="mt-3 mt-md-0">
										<Form.Control
											type="text"
											placeholder="Cerca..."
											value={searchString}
											onChange={(e: ChangeEvent<any>) =>
												this.setState({
													searchString: e.target.value,
												})
											}
										/>
										<Button className="me-0 me-md-3">
											<BiSearchAlt2 />
										</Button>
									</InputGroup>

									<Button
										className="me-0 me-md-3 mt-3 mt-md-0 d-flex align-items-center"
										href={`/createAnagrafica${anagraficaType !== AnagraficaType.Paziente ? "?isUser=1" : ""}`}
									>
										<BsPersonFillAdd className="me-2" />
										NUOVO
									</Button>
								</Form.Group>
							</div>

							<div className="mt-5">
								{anagrafiche.map((e: PazienteOutput, i: number) => (
									<div
										key={i}
										className="d-flex-inline d-md-flex justify-content-between align-items-center border-bottom mb-3 pb-3"
									>
										<div>
											<strong>{`${e.name} ${e.surname}`}</strong>
										</div>

										<div>
											<span>{e.codiceFiscale}</span>
										</div>
										<div>
											<span>{e.phoneNumber}</span>
										</div>

										<div className="mt-3 mt-md-0">
											<UserContext.Consumer>
												{(userContext: UserDefaultType) =>
													(anagraficaType !== AnagraficaType.Amministrativo ||
														userContext.roles.includes("SuperAdmin")) && (
														<Button
															variant="success"
															className="ms-3"
															href={`${anagraficaType !== AnagraficaType.Paziente
																	? `anagraficaUser?id=${e.id}`
																	: `/anagrafica?id=${e.id}`
																}`}
														>
															DETTAGLI
														</Button>
													)
												}
											</UserContext.Consumer>
										</div>
									</div>
								))}
							</div>
							<div className="d-flex justify-content-center mb-5">
								<div className="px-2">
									<Button disabled={currentPage + 1 === 1} onClick={() => this.previousPage()}>
										{"<"}
									</Button>
								</div>
								<div className="px-2">
									<span>
										{currentPage + 1} / {maxPage}
									</span>
								</div>
								<div className="px-2">
									<Button disabled={currentPage + 1 === maxPage} onClick={() => this.nextPage()}>
										{">"}
									</Button>
								</div>
							</div>
						</div>
					)}
				</UserContext.Consumer>
			</div>
		);
	}
}

AnagrafichePage.contextType = LoaderContext;
