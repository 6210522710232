import axios from "axios"
import { Component } from "react"
import { Button } from "react-bootstrap"
import { LoaderContext } from "../../contexts"
import { endpoint, handleAxiosError } from "../../helpers"

type IProps = {}

type IState = {
    salaSelected: number
    saleOccupied: number[]
}

export class SaleButtons extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            salaSelected: -1,
            saleOccupied: []
        }

        this.getSale = this.getSale.bind(this)
        this.changeRoom = this.changeRoom.bind(this)
    }

    context!: React.ContextType<typeof LoaderContext>

    componentDidMount() {
        this.getSale()
    }

    getSale() {
        const { toggleLoader } = this.context

        toggleLoader(true)
        axios.get<number[]>(`${endpoint}/api/Sala/GetAllOccupied`, { withCredentials: true })
            .then(({ data }) => {
                this.setState({ saleOccupied: data })
            })
            .catch(handleAxiosError)
            .finally(() => {
                toggleLoader(false)
            })
    }

    changeRoom() {
        const { toggleLoader } = this.context
        const { salaSelected } = this.state

        toggleLoader(true)
        if (salaSelected === -1) {
            axios.get(`${endpoint}/api/Sala/Libera`, { withCredentials: true })
                .then(() => {
                    window.location.href = "/"
                })
                .catch(handleAxiosError)
                .finally(() => {
                    toggleLoader(false)
                })
        } else {
            axios.get(`${endpoint}/api/Sala/Occupa/${salaSelected}`, { withCredentials: true })
                .then(() => {
                    window.location.href = "/"
                })
                .catch(handleAxiosError)
                .finally(() => {
                    toggleLoader(false)
                })
        }
    }

    render() {
        const { salaSelected, saleOccupied } = this.state

        return (
            <div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <Button
                        className="w-100 mx-2"
                        variant={saleOccupied.includes(1) ? 'secondary' : salaSelected === 1 ? "primary" : "outline-dark"}
                        onClick={() => this.setState({ salaSelected: 1 })}
                        disabled={saleOccupied.includes(1)}
                    >
                        1
                    </Button>
                    <Button
                        className="w-100 mx-2"
                        variant={saleOccupied.includes(2) ? 'secondary' : salaSelected === 2 ? "primary" : "outline-dark"}
                        onClick={() => this.setState({ salaSelected: 2 })}
                        disabled={saleOccupied.includes(2)}
                    >
                        2
                    </Button>
                    <Button
                        className="w-100 mx-2"
                        variant={saleOccupied.includes(3) ? 'secondary' : salaSelected === 3 ? "primary" : "outline-dark"}
                        onClick={() => this.setState({ salaSelected: 3 })}
                        disabled={saleOccupied.includes(3)}
                    >
                        3
                    </Button>
                    <Button
                        className="w-100 mx-2"
                        variant={saleOccupied.includes(4) ? 'secondary' : salaSelected === 4 ? "primary" : "outline-dark"}
                        onClick={() => this.setState({ salaSelected: 4 })}
                        disabled={saleOccupied.includes(4)}
                    >
                        4
                    </Button>
                    <Button
                        className="w-100 mx-2"
                        style={{ fontSize: "10px" }}
                        variant={salaSelected === -1 ? "primary" : "outline-dark"}
                        onClick={() => this.setState({ salaSelected: -1 })}
                    >
                        Fuori ufficio
                    </Button>
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <Button className="w-100 mx-2 text-start" variant="primary" onClick={() => this.changeRoom()}>
                        Entra
                    </Button>
                </div>
            </div>
        )
    }
}

SaleButtons.contextType = LoaderContext