import { PropsWithChildren, PureComponent } from "react";
import { LoaderContext } from "./LoaderContext";

type IState = {
	isLoading: boolean;
};

export class LoaderProvider extends PureComponent<PropsWithChildren<{}>, IState> {
	constructor(props: PropsWithChildren<{}>) {
		super(props);
		this.state = {
			isLoading: false,
		};
		this.toggleLoader = this.toggleLoader.bind(this);
	}

	toggleLoader(value: boolean) {
		this.setState({ isLoading: value });
	}

	render() {
		const { isLoading } = this.state;
		return (
			<LoaderContext.Provider value={{ isLoading: isLoading, toggleLoader: this.toggleLoader }}>
				<div id="loader" className="justify-content-center align-items-center" style={{ position: "fixed", display: isLoading ? "flex" : "none" }}>
					<div className="spinner-border" role="status">
						<span className="sr-only" />
					</div>
				</div>

				{this.props.children}
			</LoaderContext.Provider>
		);
	}
}
