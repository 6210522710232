import React, { ChangeEvent, Component } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { MdlType, PrenotazioneEnum } from "../../enums";
import { DisponibilitaOutput, UserOutput, PrenotazioneOutput, SalaOutput } from "../../types";
import { LoaderContext } from "../../contexts";
import { format } from "date-fns";
import { endpoint, handleError } from "../../helpers";
import axios from "axios";

type IProps = {
	nome: string;
	sale: SalaOutput[];
	prenotazioni: PrenotazioneOutput[];
	activeDoctors: UserOutput[];
	createTicketMdl: (quantity: number, type: MdlType | null) => void;
	createTicketPrelievo: () => void;
	callByAmministrazione: (id: number) => void;
};

type IState = {
	ticketQuantity: number;
	codaMDLInfermieristica: number;
	codaMDLMedicoCompetente: number;
};

export class AdminHome extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			ticketQuantity: 1,
			codaMDLInfermieristica: 0,
			codaMDLMedicoCompetente: 0,
		};

		this.getPersoneInCodaMdl = this.getPersoneInCodaMdl.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.getPersoneInCodaMdl();
	}

	getPersoneInCodaMdl = async () => {
		const { toggleLoader } = this.context;

		toggleLoader(true);

		try {
			const { data: coda1 } = await axios.get<number>(`${endpoint}/api/Mdl/GetPersoneInCoda/${MdlType.Infermieristica}`, {
				withCredentials: true,
			});
			const { data: coda2 } = await axios.get<number>(`${endpoint}/api/Mdl/GetPersoneInCoda/${MdlType.EsamiStrumentali}`, {
				withCredentials: true,
			});
			this.setState({ codaMDLInfermieristica: coda1, codaMDLMedicoCompetente: coda2 });
		} catch (e: any) {
			handleError(e);
		} finally {
			toggleLoader(false);
		}
	};

	render() {
		const { ticketQuantity, codaMDLInfermieristica, codaMDLMedicoCompetente } = this.state;
		const { nome, sale, prenotazioni, activeDoctors, createTicketMdl, createTicketPrelievo, callByAmministrazione } = this.props;
		return (
			<div className="container">
				<div className="mt-5">
					<div className="row">
						<div className="col-12 col-md-6">
							<div style={{ lineHeight: "5px" }}>
								<h1>Benvenuto, {nome}.</h1>
							</div>
							<div>
								<div className="widthCustom75To100">
									<InputGroup className="w-100">
										<Button className="h-100" style={{ width: "80%" }} onClick={() => createTicketMdl(ticketQuantity, null)}>
											GENERA TICKET MDL
										</Button>
										<Form.Control
											type="number"
											min={1}
											value={ticketQuantity}
											onChange={(e: ChangeEvent<any>) => this.setState({ ticketQuantity: e.target.value })}
											style={{ width: "20%" }}
										/>
									</InputGroup>
								</div>
								<div className="d-flex align-items-center widthCustom75To100 mt-5 h-100">
									<div className="col-12 col-md-4">
										<div>
											<Button
												className="h-100 w-100"
												onClick={() => createTicketMdl(1, MdlType.Infermieristica)}
											>
												Visita Infermieristica
											</Button>
										</div>
									</div>
									<div className="col-12 col-md-4">
										<div className="ms-3 me-3">
											<Button
												className="h-100 w-100"
												onClick={() => createTicketMdl(1, MdlType.EsamiStrumentali)}
											>
												Visita Medico Competente
											</Button>
										</div>
									</div>
									<div className="col-12 col-md-4 h-100">
										<div className="h-100">
											<Button
												className="h-100 w-100"
												onClick={() => createTicketPrelievo()}
											>
												Prelievo
											</Button>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-10">
								<div className="mt-5">
									<div className="row d-flex">
										{sale.map((e: SalaOutput, i: number) => (
											<div key={i} className="col-6">
												<div className="py-3 lineHeightCustom">
													<h1>
														<span>
															{e.dottore === null ? "🟢" : "🔴"} <strong>{e.name}</strong>
														</span>
													</h1>
													{e.dottore !== null ? (
														<>
															<p>
																Dott. {e.dottore.name} {e.dottore.surname}
															</p>
															<p>{e.dottore.personeInCoda} pazienti in attesa.</p>
														</>
													) : (
														<p>Libera.</p>
													)}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="text-center text-md-start my-3 my-md-0">
								<div>
									<strong>Coda MDL</strong>
								</div>
								<div>
									<span>Infermieristica: {codaMDLInfermieristica}</span>
								</div>
								<div>
									<span>Medico Competente: {codaMDLMedicoCompetente}</span>
								</div>
							</div>

							{activeDoctors.length > 0 && (
								<table className="table">
									<thead>
										<tr>
											<th scope="col">Nome Professionista</th>
											<th scope="col">Orario</th>
											<th scope="col">Pazienti</th>
										</tr>
									</thead>
									<tbody>
										{activeDoctors.map((e: UserOutput, i: number) => (
											<tr key={i}>
												<td>{`${e.name} ${e.surname}`}</td>
												<td>
													{e.disponibilita.map((item: DisponibilitaOutput, i: number) => (
														<div key={i}>{`${format(new Date(item.startDate), "HH:mm")} - ${format(
															new Date(item.endDate),
															"HH:mm"
														)}`}</div>
													))}
												</td>
												<td>{e.personeInCoda > 0 ? e.personeInCoda : "Nessuno"}</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
						</div>

						<div className="col-12 col-md-6">
							<div className="d-flex justify-content-between align-items-center">
								<h1>Appuntamenti di oggi</h1>
								<Button className="btn btn-primary h-100" href="/createPrenotazione">
									AGGIUNGI
								</Button>
							</div>

							<div className="mt-5">
								{prenotazioni &&
									prenotazioni.map((e: PrenotazioneOutput, i: number) => (
										<div key={i}>
											{!e.paziente.privacy && (
												<div className="bg-danger text-white">
													<div className="d-flex-inline d-md-flex text-center text-md-start justify-content-between align-items-center p-3">
														<div>
															<div>
																<strong>Consenso privacy non espresso!</strong>
															</div>
															<div>
																<span>Prima di accettare il paziente carica il documento.</span>
															</div>
														</div>
														<div className="mt-3 m-md-0">
															<Button variant="outline-light" href={`/editAnagrafica?id=${e.paziente.id}`}>
																Carica Ora
															</Button>
														</div>
													</div>
												</div>
											)}
											<div className="mb-4 p-4" style={{ backgroundColor: "#EEEEEE" }}>
												<div className="d-flex justify-content-between">
													<div>
														<div>
															<small>{format(new Date(e.startDate), "dd/MM/yyyy")}</small>
															<small className="ms-2">
																{e.status === PrenotazioneEnum.Inizializzato && "🔵 PRENOTATO"}
																{e.status === PrenotazioneEnum.Arrivato && "🟡 ARRIVATO"}
															</small>
														</div>
														<div>
															<small>
																{format(new Date(e.startDate), "HH:mm")} - {format(new Date(e.endDate), "HH:mm")} - {e.oggetto.price}€
																{e.paziente.hasConvenzione && (
																	<Button className="p-0 px-1 ms-2" style={{ fontSize: '12px', fontWeight: '600' }}>MDL</Button>
																)}
															</small>
														</div>
														<div>
															<strong className="fs-2">
																{e.paziente.name} {e.paziente.surname}
															</strong>
														</div>
													</div>
													<div className="text-end">
														<div className="mb-2">
															{e.status === PrenotazioneEnum.Inizializzato && (
																<Button
																	className="text-light w-100"
																	variant="primary"
																	onClick={() => callByAmministrazione(e.id)}
																>
																	IMPOSTA COME ARRIVATO
																</Button>
															)}
															{e.status === PrenotazioneEnum.Arrivato && (
																<Button disabled className="text-light w-100" variant="primary">
																	ARRIVATO
																</Button>
															)}
														</div>
													</div>
												</div>

												<div className="mt-4">
													<h4>{e.oggetto.name}</h4>
													<small>{e.description}</small>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AdminHome.contextType = LoaderContext;
