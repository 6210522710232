import { Component } from "react";
import { CalendarData, CalendarRow, CalendarRowType, HoursChoices, ReactSelectOption } from "../../types";
import { format } from "date-fns";

const ORE_CONST: number = 156;

type IProps = {
	data: CalendarData;
	selectedDate: Date;
	isVisible: boolean;
	selectedDisponibilitaShowModalFunc: any;
};

type IState = {
	mousePositionX: number;
	mousePositionY: number;
	showBox: boolean;
	selectedPreview: CalendarRow | null;
};

export class VerticalCustomCalendar extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			mousePositionX: 0,
			mousePositionY: 0,
			showBox: false,
			selectedPreview: null,
		};

		this.showModal = this.showModal.bind(this);
		this.handleMouseOver = this.handleMouseOver.bind(this);
	}

	handleMouseOver(e: React.MouseEvent<HTMLDivElement, MouseEvent>, row: CalendarRow) {
		if (row.type !== CalendarRowType.Vuoto) {
			const x: number = e.clientX;
			const y: number = e.clientY;

			this.setState({ mousePositionX: x, mousePositionY: y, showBox: true, selectedPreview: row });
		}
	}

	showModal(currentRow: CalendarRow) {
		const { selectedDisponibilitaShowModalFunc } = this.props;
		if (currentRow && currentRow.type === CalendarRowType.Disponibilita) {
			selectedDisponibilitaShowModalFunc(currentRow);
		}
	}

	render() {
		const { data } = this.props;
		const { mousePositionY, mousePositionX, showBox, selectedPreview } = this.state;

		return (
			<>
				<div
					className="position-absolute border bg-white p-2"
					style={{ top: mousePositionY + 50, left: mousePositionX + 50, display: showBox ? "block" : "none", zIndex: "9999" }}
				>
					{selectedPreview && (
						<>
							<span>
								{format(new Date(selectedPreview.startTime), "HH:mm")} - {format(new Date(selectedPreview.endTime), "HH:mm")}
							</span>
							{selectedPreview.paziente && (
								<div>
									<div>
										<span style={{ fontSize: "10px" }}>
											{selectedPreview.paziente.name} {selectedPreview.paziente.surname}
										</span>
									</div>
									<div>
										<span style={{ fontSize: "10px" }}>{selectedPreview.reparto}</span>
									</div>
								</div>
							)}
						</>
					)}
				</div>
				<div id="vertical-custom-calendar" className="position-relative ms-0 ms-md-3 mb-5 mb-md-2">
					<div
						className="position-absolute h-100 w-100 d-none d-md-block"
						style={{ zIndex: "999", pointerEvents: "none", marginTop: "5%", marginLeft: "-5%", fontSize: "12px" }}
					>
						{HoursChoices.map((e: ReactSelectOption, i: number) => (
							<div key={i} className="w-100" style={{ height: `${(100 / ORE_CONST) * 1}%` }}>
								<span>{i % 12 === 0 ? e.label : ""}</span>
							</div>
						))}
					</div>
					<div className="position-absolute h-100 w-100">
						<div className="d-flex justify-content-between h-100">
							<div className="col-4">
								<div className="d-flex align-content-between flex-wrap h-100 mx-2">
									<p className="text-center w-100">
										{data.colTitles[0] && data.colTitles[0].length > 21
											? data.colTitles[0].substring(0, 21) + "..."
											: data.colTitles[0]}
									</p>

									{data.columns[0] &&
										data.columns[0].rows.map((e: CalendarRow, i: number) => (
											<div
												key={i}
												className="w-100"
												style={{ height: `${(100 / ORE_CONST) * e.percentage}%` }}
												onClick={() => this.showModal(e)}
											>
												<div
													className={`space ${e.type === CalendarRowType.Disponibilita && "open-space"} ${e.type === CalendarRowType.Prenotazione && "booked-space"
														}`}
													onMouseOver={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
														this.handleMouseOver(event, e)
													}
													onMouseOut={() => this.setState({ showBox: false })}
												>
													<div>
														<span
															className="d-flex d-md-none rounded text-center text-black ms-2"
															style={{ fontWeight: "600" }}
														>
															{format(new Date(e.startTime), "HH:mm")} - {format(new Date(e.endTime), "HH:mm")}
														</span>
													</div>
													{e.paziente && (
														<div>
															<span
																className="d-flex rounded text-center text-white ms-2"
																style={{ fontWeight: "600" }}
															>
																{e.paziente.name} {e.paziente.surname}
															</span>
														</div>
													)}
												</div>
											</div>
										))}
								</div>
							</div>
							<div className="col-4">
								<div className="d-flex align-content-between flex-wrap h-100 mx-2">
									<p className="text-center w-100">
										{data.colTitles[1] && data.colTitles[1].length > 21
											? data.colTitles[1].substring(0, 21) + "..."
											: data.colTitles[1]}
									</p>

									{data.columns[1] &&
										data.columns[1].rows.map((e: CalendarRow, i: number) => (
											<div
												key={i}
												className="w-100"
												style={{ height: `${(100 / ORE_CONST) * e.percentage}%` }}
												onClick={() => this.showModal(e)}
											>
												<div
													className={`space ${e.type === CalendarRowType.Disponibilita && "open-space"} ${e.type === CalendarRowType.Prenotazione && "booked-space"
														}`}
													onMouseOver={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
														this.handleMouseOver(event, e)
													}
													onMouseOut={() => this.setState({ showBox: false })}
												>
													<div>
														<span
															className="d-flex d-md-none rounded text-center text-black ms-2"
															style={{ fontWeight: "600" }}
														>
															{format(new Date(e.startTime), "HH:mm")} - {format(new Date(e.endTime), "HH:mm")}
														</span>
													</div>
													{e.paziente && (
														<div>
															<span
																className="d-flex rounded text-center text-white ms-2"
																style={{ fontWeight: "600" }}
															>
																{e.paziente.name} {e.paziente.surname}
															</span>
														</div>
													)}
												</div>
											</div>
										))}
								</div>
							</div>
							<div className="col-4">
								<div className="d-flex align-content-between flex-wrap h-100 mx-2">
									<p className="text-center w-100">
										{data.colTitles[2] && data.colTitles[2].length > 21
											? data.colTitles[2].substring(0, 21) + "..."
											: data.colTitles[2]}
									</p>

									{data.columns[2] &&
										data.columns[2].rows.map((e: CalendarRow, i: number) => (
											<div
												key={i}
												className="w-100"
												style={{ height: `${(100 / ORE_CONST) * e.percentage}%` }}
												onClick={() => this.showModal(e)}
											>
												<div
													className={`space ${e.type === CalendarRowType.Disponibilita && "open-space"} ${e.type === CalendarRowType.Prenotazione && "booked-space"
														}`}
													onMouseOver={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
														this.handleMouseOver(event, e)
													}
													onMouseOut={() => this.setState({ showBox: false })}
												>
													<div>
														<span
															className="d-flex d-md-none rounded text-center text-black ms-2"
															style={{ fontWeight: "600" }}
														>
															{format(new Date(e.startTime), "HH:mm")} - {format(new Date(e.endTime), "HH:mm")}
														</span>
													</div>
													{e.paziente && (
														<div>
															<span
																className="d-flex rounded text-center text-white ms-2"
																style={{ fontWeight: "600" }}
															>
																{e.paziente.name} {e.paziente.surname}
															</span>
														</div>
													)}
												</div>
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
