import { Component } from "react";
import { UserContext, UserDefaultType } from "../contexts";

export class NotFound extends Component {
	render() {
		return (
			<div id="NotFound">
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<>
							{userContext.isLogged && (
								<div
									style={{ marginTop: "15%" }}
									className="d-flex justify-content-center align-items-center"
								>
									<h1>Pagina non trovata - 404</h1>
								</div>
							)}
						</>
					)}
				</UserContext.Consumer>
			</div>
		);
	}
}