import { ChangeEvent, Component, ContextType } from "react";
import { LoaderContext } from "../contexts";
import axios from "axios";
import { endpoint, handleAxiosError } from "../helpers";
import {
	MultiSelectValue,
	PazienteOutput,
	SchedaAnamnesticaCompiledInput,
	SchedaAnamnesticaCompiledOutput,
	SchedaAnamnesticaOutput,
	SchedaAnamnesticaSectionMultiResponseOutputDto,
	SchedaAnamnesticaSectionOutput,
	SchedaAnamnesticaUserResponseInput,
} from "../types";
import { Button, Form } from "react-bootstrap";
import { SASelectedType } from "../enums";
import { format } from "date-fns";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IProps {}
interface IState {
	paziente: PazienteOutput | null;
	schedeAnamnestiche: SchedaAnamnesticaOutput[];
	schedaSelected: SchedaAnamnesticaOutput | null;
	userResponses: SchedaAnamnesticaUserResponseInput[];
	multiSelectValues: MultiSelectValue[];
}

const params = new URLSearchParams(window.location.search);

const modules = {
	toolbar: [
		["bold", "italic", "underline", "strike"], // toggled buttons

		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }], // outdent/indent

		[{ size: ["small", false, "large", "huge"] }], // custom dropdown

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],

		["clean"], // remove formatting button
	],
};

export class CompileSchedaAnamnesticaPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			paziente: null,
			schedeAnamnestiche: [],
			schedaSelected: null,
			userResponses: [],
			multiSelectValues: [],
		};

		this.handleResponseChange = this.handleResponseChange.bind(this);
		this.handleMultiResponseChange = this.handleMultiResponseChange.bind(this);
		this.getPaziente = this.getPaziente.bind(this);
		this.getSchede = this.getSchede.bind(this);
		this.submit = this.submit.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getPaziente();
	}

	handleResponseChange(value: string, sectionId: number, remove: boolean = false) {
		const { userResponses } = this.state;

		const newUserResponses = [...userResponses];

		const index: number = newUserResponses.findIndex((e) => e.fk_SchedaAnamnesticaSection === sectionId);

		if (remove) {
			newUserResponses.splice(index, 1);
		} else {
			if (newUserResponses[index]) {
				newUserResponses[index] = { userResponse: value, fk_SchedaAnamnesticaSection: sectionId };
			} else {
				newUserResponses.push({ userResponse: value, fk_SchedaAnamnesticaSection: sectionId });
			}
		}

		this.setState({ userResponses: newUserResponses });
	}

	handleMultiResponseChange(checked: boolean, multiResponseId: number, sectionId: number) {
		const { multiSelectValues } = this.state;

		const newMultiSelectValues = multiSelectValues.map((e) => (e.multiResponseId === multiResponseId ? { ...e, checked: checked } : e));

		let newValue: string = newMultiSelectValues
			.filter((e) => e.checked)
			.map((e) => (e.otherString ? e.otherString : e.value))
			.join(",");
		this.setState({ multiSelectValues: newMultiSelectValues }, () => {
			this.handleResponseChange(newValue, sectionId);
		});

		// TODO maybe this is correct? dunno
		// let newValue: string = newMultiSelectValues
		// 	.map((e) => (e.otherString ? `${e.otherString}_${e.checked}` : `${e.value}_${e.checked}`))
		// 	.join(",");
		// this.setState({ multiSelectValues: newMultiSelectValues }, () => {
		// 	this.handleResponseChange(newValue, sectionId);
		// });
	}

	getPaziente() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PazienteOutput>(`${endpoint}/api/Paziente/GetById/${params.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ paziente: data }, () => {
					this.getSchede();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getSchede() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<SchedaAnamnesticaOutput[]>(`${endpoint}/api/SchedaAnamnestica/GetAllForCompilation`, {
				withCredentials: true,
			})
			.then(({ data }) => {
				this.setState({ schedeAnamnestiche: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	selectScheda(schedaSelected: SchedaAnamnesticaOutput) {
		let multiSelectValues: MultiSelectValue[] = [];

		schedaSelected.sections
			.filter((e) => e.selectedType === SASelectedType.RispostaMultipla)
			.forEach((section: SchedaAnamnesticaSectionOutput) => {
				section.sectionMultiResponses.forEach((multiResponse: SchedaAnamnesticaSectionMultiResponseOutputDto) => {
					multiSelectValues.push({
						sectionId: section.id,
						multiResponseId: multiResponse.id,
						value: multiResponse.sectionResponse,
						checked: false,
						otherString: "",
					});
				});
			});

		this.setState({ schedaSelected, multiSelectValues, userResponses: [] });
	}

	submit() {
		const { toggleLoader } = this.context;
		const { userResponses, paziente, schedaSelected, schedeAnamnestiche } = this.state;

		if (paziente == null || schedaSelected == null) return;

		let userResponsesFiltered: SchedaAnamnesticaUserResponseInput[] = [];
		if (userResponses)
			userResponsesFiltered = userResponses.filter(
				(e) => e !== undefined && e.userResponse !== null && e.userResponse !== "" && e.userResponse !== undefined
			);

		const selectedSchedaAnamnestica: SchedaAnamnesticaOutput | undefined = schedeAnamnestiche.find((e) => e.id === schedaSelected.id);

		if (selectedSchedaAnamnestica) {
			const sectionsSiNoIds: number[] = selectedSchedaAnamnestica.sections
				.filter((e) => e.selectedType === SASelectedType.SiNo)
				.map((e) => e.id);

			sectionsSiNoIds.forEach((sectionId: number) => {
				userResponsesFiltered.find((e) => e.fk_SchedaAnamnesticaSection === sectionId) ??
					userResponsesFiltered.push({ fk_SchedaAnamnesticaSection: sectionId, userResponse: "false" });
			});
			
			const sectionsTitoloSezioneIds: number[] = selectedSchedaAnamnestica.sections
				.filter((e) => e.selectedType === SASelectedType.TitoloSezione)
				.map((e) => e.id);

				sectionsTitoloSezioneIds.forEach((sectionId: number) => {
				userResponsesFiltered.find((e) => e.fk_SchedaAnamnesticaSection === sectionId) ??
					userResponsesFiltered.push({ fk_SchedaAnamnesticaSection: sectionId, userResponse: "" });
			});
		}

		userResponsesFiltered.sort((a, b) => a.fk_SchedaAnamnesticaSection - b.fk_SchedaAnamnesticaSection);

		const model: SchedaAnamnesticaCompiledInput = {
			fk_Paziente: paziente.id,
			fk_SchedaAnamnestica: schedaSelected.id,
			userResponses: userResponsesFiltered,
		};

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/SchedaAnamnestica/CreateCompiled`, model, { withCredentials: true })
			.then(() => {
				window.location.href = `${params.get("returnUrl")}`;
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const { paziente, schedeAnamnestiche, schedaSelected, userResponses, multiSelectValues } = this.state;

		return (
			<div id="CompileSchedaAnamnesticaPage">
				{paziente && (
					<div className="container my-5">
						<div className="row">
							<div className="col-12">
								<div>
									<strong className="fs-1 main-color">
										{paziente.name} {paziente.surname}
									</strong>
								</div>
								<div>
									<strong className="fs-4 main-color">Compila la scheda anamnestica</strong>
								</div>
							</div>
						</div>
						{schedeAnamnestiche.length > 0 ? (
							<div className="row">
								<div className="col-12 col-md-8">
									<div className="row d-flex mt-3 mb-5">
										{schedeAnamnestiche.map((e: SchedaAnamnesticaOutput, i: number) => (
											<SAButton
												key={i}
												title={e.title}
												isSelected={schedaSelected !== null && schedaSelected.id === e.id}
												selectFunc={() => this.selectScheda(e)}
											/>
										))}
									</div>
									{schedaSelected &&
										schedeAnamnestiche.find((e) => e.id === schedaSelected.id) &&
										schedeAnamnestiche
											.find((e) => e.id === schedaSelected.id)
											?.sections.map((section: SchedaAnamnesticaSectionOutput, sectionIndex: number) => (
												<div key={sectionIndex} className="py-2">
													{section.selectedType === SASelectedType.SiNo ? (
														<div className="d-flex align-items-center">
															<div className="col-3">
																<Form.Label>{section.question}</Form.Label>
															</div>
															<div className="col-9">
																<Form.Check
																	checked={
																		userResponses.find((e) => e.fk_SchedaAnamnesticaSection === section.id)
																			?.userResponse === "true"
																	}
																	onChange={(event: ChangeEvent<any>) =>
																		this.handleResponseChange(event.target.checked.toString(), section.id)
																	}
																/>
															</div>
														</div>
													) : section.selectedType === SASelectedType.Data ? (
														<div className="d-flex align-items-center">
															<div className="col-3">
																<Form.Label>{section.question}</Form.Label>
															</div>
															<div className="col-9">
																<Form.Control
																	type="date"
																	value={
																		userResponses.find((e) => e.fk_SchedaAnamnesticaSection === section.id)
																			?.userResponse ?? ""
																	}
																	onChange={(event: ChangeEvent<any>) =>
																		this.handleResponseChange(event.target.value, section.id)
																	}
																/>
															</div>
														</div>
													) : section.selectedType === SASelectedType.RispostaBreve ? (
														<div className="d-flex align-items-center">
															<div className="col-3">
																<Form.Label>{section.question}</Form.Label>
															</div>
															<div className="col-9">
																<Form.Control
																	type="text"
																	value={
																		userResponses.find((e) => e.fk_SchedaAnamnesticaSection === section.id)
																			?.userResponse ?? ""
																	}
																	onChange={(event: ChangeEvent<any>) =>
																		this.handleResponseChange(event.target.value, section.id)
																	}
																/>
															</div>
														</div>
													) : section.selectedType === SASelectedType.RispostaLunga ? (
														<div className="d-flex align-items-center">
															<div className="col-3">
																<Form.Label>
																	<Form.Label>{section.question}</Form.Label>
																</Form.Label>
															</div>
															<div className="col-9">
																<ReactQuill
																	value={
																		userResponses.find((e) => e.fk_SchedaAnamnesticaSection === section.id)
																			?.userResponse ?? ""
																	}
																	modules={modules}
																	onChange={(_content, _delta, _source, editor) =>
																		this.handleResponseChange(editor.getHTML(), section.id)
																	}
																/>
															</div>
														</div>
													) : section.selectedType === SASelectedType.DropDown ? (
														<div className="d-flex align-items-center">
															<div className="col-3">
																<Form.Label>{section.question}</Form.Label>
															</div>
															<div className="col-9">
																<Form.Select
																	value={
																		userResponses.find((e) => e.fk_SchedaAnamnesticaSection === section.id)
																			?.userResponse ?? "___defaultValue___"
																	}
																	onChange={(event: ChangeEvent<any>) =>
																		this.handleResponseChange(event.target.value, section.id)
																	}
																>
																	<option value={"___defaultValue___"} disabled>
																		Seleziona un valore...
																	</option>
																	{section.sectionMultiResponses.map(
																		(e: SchedaAnamnesticaSectionMultiResponseOutputDto, i: number) => (
																			<option value={e.sectionResponse} key={i}>
																				{e.sectionResponse}
																			</option>
																		)
																	)}
																</Form.Select>
															</div>
														</div>
													) : section.selectedType === SASelectedType.RispostaMultipla ? (
														<div className="d-flex align-items-start">
															<div className="col-3">
																<Form.Label>{section.question}</Form.Label>
															</div>
															<div className="col-9">
																{section.sectionMultiResponses.map(
																	(
																		multiResponse: SchedaAnamnesticaSectionMultiResponseOutputDto,
																		multiResponseIndex: number
																	) => (
																		<div key={multiResponseIndex} className="d-flex align-items-center w-100">
																			<div className="me-2">
																				<Form.Check
																					onChange={(event: ChangeEvent<HTMLInputElement>) =>
																						this.handleMultiResponseChange(
																							event.target.checked,
																							multiResponse.id,
																							section.id
																						)
																					}
																				/>
																			</div>
																			<div className="w-100">
																				{multiResponse.sectionResponse === "Altro" ? (
																					<Form.Control
																						className="d-flex"
																						type="text"
																						placeholder="Altro"
																						value={
																							multiSelectValues.find(
																								(e) => e.multiResponseId === multiResponse.id
																							)?.otherString ?? ""
																						}
																						onChange={(event: ChangeEvent<any>) =>
																							this.setState(
																								{
																									multiSelectValues: multiSelectValues.map(
																										(multiSelectValue) =>
																											multiSelectValue.multiResponseId ===
																											multiResponse.id
																												? {
																														...multiSelectValue,
																														otherString:
																															event.target.value,
																												  }
																												: multiSelectValue
																									),
																								},
																								() => {
																									this.handleMultiResponseChange(
																										multiSelectValues.find(
																											(e) =>
																												e.multiResponseId === multiResponse.id
																										)?.checked ?? false,
																										multiResponse.id,
																										section.id
																									);
																								}
																							)
																						}
																						disabled={
																							!multiSelectValues.find(
																								(e) => e.multiResponseId === multiResponse.id
																							)?.checked
																						}
																					/>
																				) : (
																					<Form.Label className="d-flex">
																						{multiResponse.sectionResponse}
																					</Form.Label>
																				)}
																			</div>
																		</div>
																	)
																)}
															</div>
														</div>
													) : section.selectedType === SASelectedType.TitoloSezione ? (
														<div className="d-flex align-items-center mt-4">
															<div className="col-3">
																<strong className="fs-5">{section.question}</strong>
															</div>
														</div>
													) : null}
												</div>
											))}
									{schedaSelected && (
										<div className="d-flex justify-content-md-start justify-content-between align-items-center mt-3">
											<div className="me-2">
												<Button onClick={() => this.submit()}>CONFERMA</Button>
											</div>
											<div>
												<Button variant="danger" onClick={() => (window.location.href = `${params.get("returnUrl")}`)}>
													ANNULLA
												</Button>
											</div>
										</div>
									)}
								</div>
								{paziente.schedeAnamnesticheCompiled.length > 0 && (
									<div className="col-12 col-md-4">
										<div>
											<strong className="fs-4 main-color">Ultime schede</strong>
										</div>
										<div className="row">
											{paziente.schedeAnamnesticheCompiled.map((e: SchedaAnamnesticaCompiledOutput, i: number) => (
												<LatestSA data={e} key={i} />
											))}
										</div>
									</div>
								)}
							</div>
						) : (
							<div>
								<strong className="fs-4 main-color">Nessuna scheda anamnestica disponibile...</strong>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

CompileSchedaAnamnesticaPage.contextType = LoaderContext;

interface IPropsSAButton {
	selectFunc: () => void;
	title: string;
	isSelected: boolean;
}

const SAButton = (props: IPropsSAButton): JSX.Element => {
	const { title, isSelected, selectFunc } = props;
	return (
		<div className="col-12 col-md-4 my-2" style={{ cursor: "pointer" }} onClick={() => selectFunc()}>
			<div className={`${isSelected ? "main-bg" : "grey-bg"} my-2 p-3 d-flex justify-content-center align-items-center h-100`}>
				<strong className={`fs-5 ${isSelected ? "text-white" : "main-color"}`}>{title}</strong>
			</div>
		</div>
	);
};

interface IPropsLatestSA {
	data: SchedaAnamnesticaCompiledOutput;
}

const LatestSA = (props: IPropsLatestSA): JSX.Element => {
	const { data } = props;
	const { schedaAnamnestica } = data;
	return (
		<div className="col-12">
			{schedaAnamnestica && (
				<div
					className="main-color grey-bg p-3 mt-3"
					style={{ cursor: "pointer" }}
					onClick={() => (window.location.href = `/saCompiled?id=${data.id}&returnUrl=${window.location.href}`)}
				>
					<div>
						<span>SCHEDA ANAMNESTICA</span>
					</div>
					<div>
						<strong className="fs-4">{schedaAnamnestica.title}</strong>
					</div>
					<div className="mt-3" style={{ fontSize: "14px" }}>
						<span className="me-2">
							Dott. {data.createdBy.name} {data.createdBy.surname}
						</span>
						<strong>{format(new Date(data.createdDate), "dd/MM/yyyy - HH:mm")}</strong>
					</div>
				</div>
			)}
		</div>
	);
};
