import { Component } from "react";
import { Button } from "react-bootstrap";
import { CallResponse, CurrentPrestazione } from "../types";
import { endpoint, handleAxiosError } from "../helpers";
import * as signalR from "@microsoft/signalr";
import axios from "axios";
import { notification } from "antd-notifications-messages";
import { LoaderContext } from "../contexts";
import { format } from "date-fns";

type PlayStatus = "PLAYING" | "STOPPED" | "PAUSED";

interface IProps { }
interface IState {
	currentPrelievo: CurrentPrestazione | null;
	currentCoda: number;
	connection: signalR.HubConnection | null;
	statusAudio: PlayStatus;
	playAudio: boolean;
}

export class PrelieviPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			currentPrelievo: null,
			currentCoda: 0,
			connection: null,
			statusAudio: "STOPPED",
			playAudio: false,
		};

		this.call = this.call.bind(this);
		this.closeVisita = this.closeVisita.bind(this);
		this.getPersoneInCoda = this.getPersoneInCoda.bind(this);
		this.getPersoneInCodaNoLoader = this.getPersoneInCodaNoLoader.bind(this);
		this.createWebSocket = this.createWebSocket.bind(this);
	}

	call() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<CallResponse>(`${endpoint}/api/Prelievo/CallByDottore`, { withCredentials: true })
			.then(({ data }) => {
				if (!data.anyOthers) {
					notification({ message: "Nessuna persona in coda disponibile", type: "info" });
					this.getPersoneInCoda();
				} else {
					this.setState({ currentPrelievo: { id: data.id, code: data.code, date: new Date() } }, () => this.getPersoneInCoda());
				}
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}


	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.createWebSocket();
	}

	getPersoneInCoda() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<number>(`${endpoint}/api/Prelievo/GetPersoneInCoda`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ currentCoda: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getPersoneInCodaNoLoader() {
		const { currentCoda } = this.state;

		axios
			.get<number>(`${endpoint}/api/Prelievo/GetPersoneInCoda`, { withCredentials: true })
			.then(({ data }) => {
				if (currentCoda !== data) {
					this.setState({ currentCoda: data, playAudio: true, statusAudio: "PLAYING" });
				}
			})
			.catch(handleAxiosError);
	}

	closeVisita(keepGoing: boolean) {
		const { currentPrelievo } = this.state;

		if (currentPrelievo) {
			axios
				.get<CallResponse>(`${endpoint}/api/Prelievo/CloseByDottore/${currentPrelievo.id}`, { withCredentials: true })
				.then(() => {
					if (keepGoing) {
						this.call();
					} else {
						this.setState({ currentPrelievo: null });
					}
				})
				.catch(handleAxiosError);
		}
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/ws`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("RefreshDocStatPrelievo", () => {
			this.getPersoneInCodaNoLoader();
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection }, () => {
					this.getPersoneInCoda();
				});
			})
			.catch(() => {
				window.location.reload();
			});
	}

	render() {
		const { currentCoda, currentPrelievo } = this.state;
		return (
			<div className="container my-5">
				<div className="my-5">
					<h2>Prelievi</h2>
				</div>
				{currentPrelievo ? (
					<>
						<Button disabled>{`A${currentPrelievo.code.toString().padStart(3, "0")}`}</Button>
						<div className="mt-3">
							<h1>{`${format(currentPrelievo.date, "HH:mm")}`}</h1>
						</div>
						<div className="mt-3">
							<span>Al momento ci sono {currentCoda - 1} persone in coda</span>
						</div>
						<div>
							{currentCoda - 1 > 0 && (
								<Button className="me-2" onClick={() => this.closeVisita(true)}>
									Chiudi e passa al prossimo
								</Button>
							)}
							<Button variant="danger" onClick={() => this.closeVisita(false)}>
								Chiudi
							</Button>
						</div>
					</>
				) : (
					<div>
						<div>
							<Button variant="success" className="me-3" onClick={() => this.call()}>
								Chiama
							</Button>
							<Button variant="primary" href="/">
								Torna alla dashboard
							</Button>
						</div>
						<div className="mt-3">
							<span>Al momento ci sono {currentCoda} persone in coda</span>
						</div>
					</div>
				)}
			</div>
		);
	}
}

PrelieviPage.contextType = LoaderContext;