import axios from "axios";
import { Component, ContextType, FormEvent } from "react";
import { UserPazienteInput, PazienteOutput, UserOutput, RepartoOutput } from "../types";
import { AnagraficheForm } from "../components";
import { endpoint, handleAxiosError } from "../helpers";
import { LoaderContext } from "../contexts";

type IProps = {};

type IState = {
	model: UserPazienteInput;
	reparti: RepartoOutput[];
	isAdmin: boolean;
};

const searchParams = new URLSearchParams(window.location.search);

export class EditAnagraficaPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			reparti: [],
			model: {
				name: "",
				surname: "",
				codiceFiscale: "",
				email: "",
				phoneNumber: "",
				description: null,
				cap: "",
				citta: "",
				indirizzo: "",
				provincia: "TO",
				partitaIva: null,
				sdiPec: null,
				isMdl: false,
				isAbilitatoPrelievo: false,
				repartiIds: [],
				hasConvenzione: false,
				privacy: null,
			},
			isAdmin: false,
		};

		this.getData = this.getData.bind(this);
		this.getAnagraficaPaziente = this.getAnagraficaPaziente.bind(this);
		this.getAnagraficaUser = this.getAnagraficaUser.bind(this);

		this.submit = this.submit.bind(this);
		this.setModel = this.setModel.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getReparti();
	}

	getData() {
		const isUser: boolean = Number(searchParams.get("isUser")) === 1;

		if (isUser) {
			this.getAnagraficaUser();
		} else {
			this.getAnagraficaPaziente();
		}
	}

	getReparti() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAllForDropdown`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ reparti: data }, () => {
					this.getData();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAnagraficaPaziente() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PazienteOutput>(`${endpoint}/api/Paziente/GetById/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({
					model: {
						name: data.name ?? null,
						surname: data.surname ?? null,
						email: data.email ?? null,
						codiceFiscale: data.codiceFiscale ?? null,
						phoneNumber: data.phoneNumber ?? null,
						description: data.description ?? null,
						cap: data.cap ?? null,
						citta: data.citta ?? null,
						indirizzo: data.indirizzo ?? null,
						provincia: data.provincia ?? "TO",
						partitaIva: data.partitaIva,
						sdiPec: data.sdiPec,
						isMdl: false,
						isAbilitatoPrelievo: false,
						hasConvenzione: data.hasConvenzione,
						repartiIds: [],
						privacy: data.privacy
							? {
									requestIpAddress: data.privacy.requestIpAddress,
									isProfilingEnabled: data.privacy.isProfilingEnabled,
									isThirdPartyEnabled: data.privacy.isThirdPartyEnabled,
									signatureUrl: data.privacy.signatureUrl,
							  }
							: null,
					},
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAnagraficaUser() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<UserOutput>(`${endpoint}/api/User/GetById/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({
					model: {
						name: data.name,
						surname: data.surname,
						email: data.email,
						codiceFiscale: data.codiceFiscale,
						phoneNumber: data.phoneNumber,
						description: data.description ?? null,
						isMdl: data.isMdl,
						isAbilitatoPrelievo: data.isAbilitatoPrelievo,
						cap: "",
						citta: "",
						indirizzo: "",
						partitaIva: null,
						sdiPec: null,
						provincia: "TO",
						repartiIds: data.reparti.map((e) => e.id),
						hasConvenzione: false,
						privacy: null,
					},
					isAdmin: data.roles.includes("Admin") || data.roles.includes("SuperAdmin"),
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	submit(e: FormEvent<HTMLFormElement>) {
		const userId = searchParams.get("id");
		const isUser: boolean = Number(searchParams.get("isUser")) === 1;

		const { toggleLoader } = this.context;
		const { model } = this.state;

		e.preventDefault();

		toggleLoader(true);
		axios
			.put(`${endpoint}/api/${isUser ? "User" : "Paziente"}/Update/${userId}`, model, { withCredentials: true })
			.then(() => {
				window.location.href = `/${isUser ? "anagraficaUser" : "anagrafica"}?id=${userId}`;
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	setModel(param: string, value: any) {
		const { model } = this.state;
		this.setState({ model: { ...model, [param]: value === "" ? null : value } });
	}

	render() {
		const { model, reparti, isAdmin } = this.state;
		const isUser: boolean = Number(searchParams.get("isUser")) === 1;
		return (
			<div id="createAnagraficaPage" className="main-color">
				<div className="container">
					<div className="py-5">
						<h2 className="my-5 mb-3">Modifica Anagrafica</h2>
						<AnagraficheForm
							reparti={reparti}
							model={model}
							setModel={this.setModel}
							submit={this.submit}
							isEdit
							isUser={isUser}
							isAdmin={isAdmin}
						/>
					</div>
				</div>
			</div>
		);
	}
}

EditAnagraficaPage.contextType = LoaderContext;
