import { PazienteOutput, UserOutput } from "."
import { PrenotazioneEnum } from "../enums"

export enum CalendarRowType {
	Vuoto,
	Disponibilita,
	Prenotazione,
}

export type CalendarRow = {
	id: number
	startTime: Date
	endTime: Date
	status?: PrenotazioneEnum | null
	dottore?: UserOutput | null
	paziente?: PazienteOutput | null
	percentage: number
	reparto?: string | null
	type: CalendarRowType
}


export type CalendarColumn = {
	rows: CalendarRow[]
}


export type CalendarData = {
	colTitles: string[],
	columns: CalendarColumn[]
}