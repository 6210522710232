import axios from "axios";
import { Component } from "react";
import { LoaderContext, UserContext, UserDefaultType } from "../../contexts";
import { PrenotazioneOutput, SalaOutput, UserOutput } from "../../types";
import { endpoint, handleAxiosError } from "../../helpers";
import { MdlType } from "../../enums";
import { AdminHome, DottoreHome } from "..";
import * as signalR from "@microsoft/signalr";
import Sound from "react-sound";

type PlayStatus = "PLAYING" | "STOPPED" | "PAUSED";

type IProps = {};

type IState = {
	activeDoctors: UserOutput[];
	prenotazioni: PrenotazioneOutput[];
	sale: SalaOutput[];
	connection: signalR.HubConnection | null;
	statusAudio: PlayStatus;
	playAudio: boolean;
};

export class HomePageComponent extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			activeDoctors: [],
			prenotazioni: [],
			sale: [],
			connection: null,
			statusAudio: "STOPPED",
			playAudio: false,
		};
		this.getAllPrenotazioni = this.getAllPrenotazioni.bind(this);
		this.callByDottore = this.callByDottore.bind(this);
		this.callByAmministrazione = this.callByAmministrazione.bind(this);
		this.getAllSale = this.getAllSale.bind(this);
		this.createTicketMdl = this.createTicketMdl.bind(this);
		this.createTicketPrelievo = this.createTicketPrelievo.bind(this);
		this.getActiveDoctors = this.getActiveDoctors.bind(this);
		this.createWebSocket = this.createWebSocket.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.createWebSocket();
	}

	callByDottore(prenotazioneId: number) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Prenotazione/CallByDottore/${prenotazioneId}`, { withCredentials: true })
			.then(() => {
				window.location.href = `/prenotazione?id=${prenotazioneId}`;
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	callByAmministrazione(prenotazioneId: number) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Prenotazione/CallByAmministrazione/${prenotazioneId}`, { withCredentials: true })
			.then(({ data }) => {
				window.open(data, "_blank");

				this.getAllPrenotazioni();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getAllPrenotazioni() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PrenotazioneOutput[]>(`${endpoint}/api/Prenotazione/GetAllForHomepage`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ prenotazioni: data }, () => {
					this.getAllSale();
				});
			})
			.catch(handleAxiosError);
	}

	getAllPrenotazioniNoLoader() {
		const { prenotazioni } = this.state;
		axios
			.get<PrenotazioneOutput[]>(`${endpoint}/api/Prenotazione/GetAllForHomepage`, { withCredentials: true })
			.then(({ data }) => {
				if (prenotazioni !== data) {
					this.setState({ statusAudio: "PLAYING", playAudio: true }, () => {
						this.setState({ prenotazioni: data }, () => {
							this.getAllSale();
						});
					});
				}
			})
			.catch(handleAxiosError);
	}

	getAllSale() {
		axios
			.get<SalaOutput[]>(`${endpoint}/api/Sala/GetAll`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ sale: data }, () => this.getActiveDoctors());
			})
			.catch(handleAxiosError);
	}

	getActiveDoctors(): void {
		const { toggleLoader } = this.context;
		axios
			.get<UserOutput[]>(`${endpoint}/api/User/GetAllActiveDottoriHome`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ activeDoctors: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	createTicketMdl(quantity: number, type?: MdlType | null) {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.post<string>(`${endpoint}/api/Mdl/Create`, { quantity, type }, { withCredentials: true })
			.then(({ data }) => {
				window.open(data, "_blank");
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	createTicketPrelievo() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<string>(`${endpoint}/api/Prelievo/Create`, { withCredentials: true })
			.then(({ data }) => {
				window.open(data, "_blank");
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/ws`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("RefreshDocStat", () => {
			this.getAllPrenotazioniNoLoader();
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection }, () => this.getAllPrenotazioni());
			})
			.catch(() => {
				window.location.reload();
			});
	}

	render() {
		const { prenotazioni, sale, activeDoctors, statusAudio, playAudio } = this.state;
		return (
			<div id="homepage">
				{playAudio && (
					<Sound
						url={"https://grmedical-public.s3.eu-south-1.amazonaws.com/static/pingDoc.wav"}
						playStatus={statusAudio}
						onFinishedPlaying={() => this.setState({ statusAudio: "STOPPED" })}
					/>
				)}
				<UserContext.Consumer>
					{(userContext: UserDefaultType) =>
						userContext.roles &&
						userContext.name &&
						(userContext.roles.includes("Dottore") ? (
							<DottoreHome nome={userContext.name} prenotazioni={prenotazioni} callByDottore={this.callByDottore} />
						) : (
							<AdminHome
								nome={userContext.name}
								createTicketMdl={this.createTicketMdl}
								createTicketPrelievo={this.createTicketPrelievo}
								sale={sale}
								prenotazioni={prenotazioni}
								activeDoctors={activeDoctors}
								callByAmministrazione={this.callByAmministrazione}
							/>
						))
					}
				</UserContext.Consumer>
			</div>
		);
	}
}

HomePageComponent.contextType = LoaderContext;
