import { notification } from "antd-notifications-messages";
import { FaFileImage, FaFilePdf, FaFileWord, FaFilePowerpoint, FaFileExcel, FaFileZipper, FaFileVideo, FaFileLines, FaFile } from "react-icons/fa6";

export type FileExtension = {
	extension: string;
	mimeType: string;
	icon: JSX.Element;
};

export const FileExtensions: FileExtension[] = [
	{ extension: "jpeg", mimeType: "image/jpeg", icon: <FaFileImage className="me-2" /> },
	{ extension: "jpg", mimeType: "image/jpeg", icon: <FaFileImage className="me-2" /> },
	{ extension: "png", mimeType: "image/png", icon: <FaFileImage className="me-2" /> },
	{ extension: "gif", mimeType: "image/gif", icon: <FaFileImage className="me-2" /> },
	{ extension: "webp", mimeType: "image/webp", icon: <FaFileImage className="me-2" /> },
	{ extension: "svg", mimeType: "image/svg+xml", icon: <FaFileImage className="me-2" /> },
	{ extension: "tiff", mimeType: "image/tiff", icon: <FaFileImage className="me-2" /> },
	{ extension: "tif", mimeType: "image/tiff", icon: <FaFileImage className="me-2" /> },
	{ extension: "bmp", mimeType: "image/bmp", icon: <FaFileImage className="me-2" /> },
	{ extension: "mp4", mimeType: "video/mp4", icon: <FaFileVideo className="me-2" /> },
	{ extension: "avi", mimeType: "video/x-msvideo", icon: <FaFileVideo className="me-2" /> },
	{ extension: "mov", mimeType: "video/quicktime", icon: <FaFileVideo className="me-2" /> },
	{ extension: "wmv", mimeType: "video/x-ms-wmv", icon: <FaFileVideo className="me-2" /> },
	{ extension: "flv", mimeType: "video/x-flv", icon: <FaFileVideo className="me-2" /> },
	{ extension: "mkv", mimeType: "video/x-matroska", icon: <FaFileVideo className="me-2" /> },
	{ extension: "webm", mimeType: "video/webm", icon: <FaFileVideo className="me-2" /> },
	{ extension: "pdf", mimeType: "application/pdf", icon: <FaFilePdf className="me-2" /> },
	{ extension: "doc", mimeType: "application/msword", icon: <FaFileWord className="me-2" /> },
	{ extension: "docx", mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", icon: <FaFileWord className="me-2" /> },
	{ extension: "ppt", mimeType: "application/vnd.ms-powerpoint", icon: <FaFilePowerpoint className="me-2" /> },
	{ extension: "pptx", mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation", icon: <FaFilePowerpoint className="me-2" /> },
	{ extension: "xls", mimeType: "application/vnd.ms-excel", icon: <FaFileExcel className="me-2" /> },
	{ extension: "xlsx", mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", icon: <FaFileExcel className="me-2" /> },
	{ extension: "odt", mimeType: "application/vnd.oasis.opendocument.text", icon: <FaFileLines className="me-2" /> },
	{ extension: "rtf", mimeType: "application/rtf", icon: <FaFileLines className="me-2" /> },
	{ extension: "txt", mimeType: "text/plain", icon: <FaFileLines className="me-2" /> },
	{ extension: "zip", mimeType: "application/zip", icon: <FaFileZipper className="me-2" /> },
	{ extension: "rar", mimeType: "application/x-rar-compressed", icon: <FaFileZipper className="me-2" /> },
	{ extension: "7z", mimeType: "application/x-7z-compressed", icon: <FaFileZipper className="me-2" /> },
	{ extension: "tar", mimeType: "application/x-tar", icon: <FaFileZipper className="me-2" /> },
	{ extension: "gz", mimeType: "application/gzip", icon: <FaFileZipper className="me-2" /> },
	{ extension: "bz2", mimeType: "application/x-bzip2", icon: <FaFileZipper className="me-2" /> },
	{ extension: "tar.gz", mimeType: "application/gzip", icon: <FaFileZipper className="me-2" /> },
	{ extension: "tar.bz2", mimeType: "application/x-bzip2", icon: <FaFileZipper className="me-2" /> },
];

export const CheckFileType = (file: File | null | undefined): boolean => {
	if (!file) return false;
	const extension = file.name.split(".").pop();
	const found = FileExtensions.find((e) => e.extension === extension);
	if (found) return true;
	notification({ type: "error", message: "Estensione del file non supportata dal sistema" });
	return false;
};

export const GetIconFromExtension = (extension: string): JSX.Element => {
	const found = FileExtensions.find((e) => e.extension === extension);
	if (found) return found.icon;
	return <FaFile className="me-2" />;
};
