import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { PrenotazioneEnum } from "../../enums";
import { PrenotazioneOutput } from "../../types";
import { format } from "date-fns";

type IState = {};
type IProps = {
	nome: string;
	prenotazioni: PrenotazioneOutput[];
	callByDottore: (id: number) => void;
};

export class DottoreHome extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	render() {
		const { nome, prenotazioni, callByDottore } = this.props;
		return (
			<div className="container">
				<div className="mt-5">
					<div className="row">
						<div className="col-12 col-md-6">
							<div style={{ lineHeight: "5px" }}>
								<h1>Benvenuto, {nome}.</h1>
								<p>
									{prenotazioni.filter((e) => e.status === PrenotazioneEnum.Arrivato).length > 0
										? `Hai ${prenotazioni.filter((e) => e.status === PrenotazioneEnum.Arrivato).length} persone in attesa`
										: `Non hai persone in attesa`}
								</p>
							</div>
						</div>

						<div className="col-12 col-md-6">
							<div className="d-flex justify-content-between align-items-center">
								<h1>Appuntamenti di oggi</h1>
								<Button className="btn btn-primary h-100" href="/createPrenotazione">
									AGGIUNGI
								</Button>
							</div>

							<div className="mt-5">
								{prenotazioni &&
									prenotazioni.map((e: PrenotazioneOutput, i: number) => (
										<div key={i}>
											<div className="my-4 p-4" style={{ backgroundColor: "#EEEEEE" }}>
												<div className="d-flex justify-content-between">
													<div>
														<small>{format(new Date(e.startDate), "dd/MM/yyyy")}</small>
														<small>
															{e.status === PrenotazioneEnum.Inizializzato && "🔵 PRENOTATO"}
															{e.status === PrenotazioneEnum.Arrivato && "🟡 ARRIVATO"}
															{e.status === PrenotazioneEnum.Chiamato && "🟢 CHIAMATO"}
														</small>
														<br />
														<small>
															{format(new Date(e.startDate), "HH:mm")} - {format(new Date(e.endDate), "HH:mm")}
														</small>
														<h2>
															{e.paziente.name} {e.paziente.surname}
														</h2>
													</div>
													<div className="text-end">
														<div className="mb-2">
															{e.status === PrenotazioneEnum.Inizializzato && (
																<Button
																	className="text-light w-100"
																	variant="primary"
																	disabled
																>
																	NON ANCORA ARRIVATO
																</Button>
															)}
															{e.status === PrenotazioneEnum.Arrivato && (
																<Button
																	className="text-light w-100"
																	variant="primary"
																	onClick={() => callByDottore(e.id)}
																	disabled={
																		prenotazioni.filter((e) => e.status === PrenotazioneEnum.Chiamato).length > 0
																	}
																>
																	CHIAMA
																</Button>
															)}
															{e.status === PrenotazioneEnum.Chiamato && (
																<Button
																	className="text-light w-100"
																	variant="success"
																	href={`/prenotazione?id=${e.id}`}
																>
																	IN CORSO
																</Button>
															)}
														</div>
													</div>
												</div>

												<div className="mt-4">
													<h4>{e.oggetto.name}</h4>
													<small>{e.description}</small>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
