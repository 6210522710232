import { Component } from "react";
import { UserContext, UserDefaultType } from "../contexts";
import { HomePageComponent, LoginBox, NavbarComponent } from "../components";

export class HomePage extends Component {
	render() {
		return (
			<div>
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<>
							{userContext.isLoaded &&
								(!userContext.isLogged ? (
									<LoginBox />
								) : (
									<>
										<NavbarComponent />
										<HomePageComponent />
									</>
								))}
						</>
					)}
				</UserContext.Consumer>
			</div>
		);
	}
}
