import axios from "axios";
import { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { endpoint, handleAxiosError } from "../../helpers";

type IProps = {
	visible: boolean;
	selectedDisponibilita: number;
	hideFunc: any;
};

type IState = {};

export class DisponibilitaModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};

		this.deleteDisponibilita = this.deleteDisponibilita.bind(this);
	}

	deleteDisponibilita() {
		const { selectedDisponibilita } = this.props;

		axios
			.delete(`${endpoint}/api/disponibilita/delete/${selectedDisponibilita}`, { withCredentials: true })
			.then(() => {
				window.location.reload();
			})
			.catch(handleAxiosError);
	}

	render() {
		const { visible, hideFunc } = this.props;

		return (
			<Modal show={visible} id="disponibilitaModal">
				<Modal.Header>
					<Modal.Title>Vuoi cancellare la disponibilità?</Modal.Title>
				</Modal.Header>

				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => hideFunc()}>
						Annulla
					</Button>
					<Button variant="danger" onClick={() => this.deleteDisponibilita()}>
						Elimina
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}
