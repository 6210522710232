import { Component } from "react";
import { UserContext, UserDefaultType } from "../contexts";
import { SaleButtons } from "../components";

export class SalePage extends Component {
	render() {
		return (
			<div id="salePage" className="main-color">
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<>
							<div className="container">
								<div className="d-flex justify-content-center" style={{ marginTop: "10%" }}>
									<div>
										<h2>
											Bentornato, {userContext.name}.
											<br />
											Entra in sala.
										</h2>
										<SaleButtons />
									</div>
								</div>
							</div>
						</>
					)}
				</UserContext.Consumer>
			</div>
		);
	}
}
