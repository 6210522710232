import axios from "axios";
import { ChangeEvent, Component, ContextType } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { PrivacyInput } from "../../types";
import { LoaderContext } from "../../contexts";
import { handleAxiosError } from "../../helpers";

type IProps = {
	isVisible: boolean;
	submitFunc: any;
	hideFunc: any;
};

type IState = {
	model: PrivacyInput;
	phase: number;
};

export class ManualPrivacyModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				signatureUrl: null,
				requestIpAddress: "",
				isProfilingEnabled: false,
				isThirdPartyEnabled: false,
			},
			phase: 1,
		};

		this.getIp = this.getIp.bind(this);
		this.submit = this.submit.bind(this);
		this.hide = this.hide.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getIp();
	}

	getIp() {
		const { model } = this.state;
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<{ ip: string }>("https://api.ipify.org?format=json")
			.then(({ data }) => {
				this.setState({ model: { ...model, requestIpAddress: data.ip } });
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	submit() {
		const { model } = this.state;
		const { submitFunc, hideFunc } = this.props;
		submitFunc(model);
		this.setState(
			{
				model: {
					signatureUrl: null,
					requestIpAddress: "",
					isProfilingEnabled: false,
					isThirdPartyEnabled: false,
				},
				phase: 1,
			},
			() => {
				hideFunc();
			}
		);
	}

	hide() {
		const { hideFunc } = this.props;
		this.setState(
			{
				model: {
					signatureUrl: null,
					requestIpAddress: "",
					isProfilingEnabled: false,
					isThirdPartyEnabled: false,
				},
				phase: 1,
			},
			() => {
				hideFunc();
			}
		);
	}

	render() {
		const { phase, model } = this.state;
		const { isVisible } = this.props;
		return (
			<Modal id="ManualPrivacyModal" show={isVisible}>
				<Modal.Body className="main-color">
					{phase === 1 ? (
						<div className="p-3">
							<div>
								<strong className="fs-3">Attenzione!</strong>
							</div>
							<div>
								<span>
									Gestire il consenso manualmente senza un consenso esplicito del pazienteè un reato.
									Procedi esclusivamente se hai una richiesta relativa alla gestione dei dati
									personali del paziente documentabile.
								</span>
							</div>
						</div>
					) : (
						<div className="p-3">
							<div className="d-flex">
								<Form.Check className="me-3" checked={true} disabled />
								<Form.Label>Finalità di gestione obbligatoria</Form.Label>
							</div>
							<div className="d-flex">
								<Form.Check
									className="me-3"
									checked={model.isProfilingEnabled}
									onChange={(e: ChangeEvent<any>) =>
										this.setState({ model: { ...model, isProfilingEnabled: e.target.checked } })
									}
								/>
								<Form.Label>Finalità di profilazione</Form.Label>
							</div>
							<div className="d-flex">
								<Form.Check
									className="me-3"
									checked={model.isThirdPartyEnabled}
									onChange={(e: ChangeEvent<any>) =>
										this.setState({ model: { ...model, isThirdPartyEnabled: e.target.checked } })
									}
								/>
								<Form.Label>Comunicazione a terze parti</Form.Label>
							</div>
						</div>
					)}
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-end">
					<Button variant="outline-dark" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							phase === 1 ? this.setState({ phase: 2 }) : this.submit();
						}}
					>
						Procedi
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

ManualPrivacyModal.contextType = LoaderContext;
