import { Component } from "react";
import { Button } from "react-bootstrap";
import { BiSolidPencil } from "react-icons/bi";
import { RepartoOutput, SchedaAnamnesticaOutput } from "../../types";

type IProps = {
    schedaAnamnestica: SchedaAnamnesticaOutput;
    reparti: RepartoOutput[];
};

type IState = {};

export class SchedaAnamnesticaRow extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { schedaAnamnestica, reparti } = this.props;
        return (
            <div id="SchedaAnamnesticaRow" style={{ opacity: schedaAnamnestica.isActive ? 1 : 0.5 }}>
                <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                    <div className="d-flex align-items-center">
                        <strong className="me-2">{schedaAnamnestica.title}</strong>
                    </div>
                    <div className="me-2 me-md-0">
                        <span>
                            {reparti.find((reparto) => reparto.id === schedaAnamnestica.reparto.id)?.name}
                        </span>
                    </div>
                    <div>
                        <span>
                            {schedaAnamnestica.isActive ? "Attiva" : "Non attiva"}
                        </span>
                    </div>
                    <div className="d-none d-md-block">
                        <Button className="me-2" href={`/editSchedaAnamnestica?id=${schedaAnamnestica.id}`}>
                            <BiSolidPencil />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}
