import axios from "axios";
import { Component, ContextType, FormEvent } from "react";
import { RepartoOutput, UserPazienteInput } from "../types";
import { AnagraficheForm } from "../components";
import { endpoint, handleAxiosError } from "../helpers";
import { LoaderContext } from "../contexts";
import { Form } from "react-bootstrap";

type IProps = {};

type IState = {
	model: UserPazienteInput;
	role: string;
	reparti: RepartoOutput[];
};

const searchParams = new URLSearchParams(window.location.search);
const isUser: boolean = Number(searchParams.get("isUser")) === 1;

export class CreateAnagraficaPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			role: "Dottore",
			model: {
				name: "",
				surname: "",
				codiceFiscale: "",
				email: "",
				phoneNumber: "",
				description: null,
				indirizzo: "",
				cap: "",
				citta: "",
				provincia: "TO",
				partitaIva: null,
				sdiPec: null,
				isMdl: false,
				isAbilitatoPrelievo: false,
				repartiIds: [],
				hasConvenzione: false,
				privacy: null,
			},
			reparti: [],
		};

		this.submit = this.submit.bind(this);
		this.setModel = this.setModel.bind(this);
		this.getReparti = this.getReparti.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.getReparti();
	}

	submit(e: FormEvent<HTMLFormElement>) {
		const { toggleLoader } = this.context;
		const { model, role } = this.state;
		e.preventDefault();

		toggleLoader(true);
		axios
			.post<number>(`${endpoint}/api/${isUser ? "User" : "Paziente"}/Create${isUser ? `?roleName=${role}` : ""}`, model, {
				withCredentials: true,
			})
			.then(({ data }) => {
				window.location.href = `/${isUser ? "anagraficaUser" : "anagrafica"}?id=${data}`;
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getReparti() {
		const { toggleLoader } = this.context;
		toggleLoader(true);
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAllForDropdown`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ reparti: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	setModel(param: string, value: any) {
		const { model } = this.state;
		this.setState({ model: { ...model, [param]: value } });
	}

	render() {
		const { model, reparti, role } = this.state;
		return (
			<div id="createAnagraficaPage" className="main-color">
				<div className="container">
					<div className="py-5">
						<h2 className="mt-5 mb-3">Nuova Anagrafica</h2>
						{isUser && (
							<div className="col-12 col-md-6">
								<Form.Group className="mb-3" controlId="ruolo">
									<Form.Label className="me-3">Ruolo</Form.Label>
									<Form.Select value={role} onChange={(e) => this.setState({ role: e.target.value })}>
										<option value="Dottore">Dottore</option>
										<option value="Admin">Amministrazione</option>
									</Form.Select>
								</Form.Group>
							</div>
						)}
						<AnagraficheForm
							reparti={reparti}
							model={model}
							setModel={this.setModel}
							submit={this.submit}
							isUser={isUser}
							isAdmin={role === "Admin"}
						/>
					</div>
				</div>
			</div>
		);
	}
}

CreateAnagraficaPage.contextType = LoaderContext;
