import { Component } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { BiCheckboxChecked, BiDownvote, BiPlus, BiText, BiToggleRight, BiTrash } from "react-icons/bi";
import { BsCalendarDate, BsTextLeft } from "react-icons/bs";
import { SASelectedType } from "../../enums";
import { SchedaAnamnesticaSectionInput, SchedaAnamnesticaSectionMultiResponseInputDto } from "../../types";

type IProps = {
	isVisible: boolean;
	editedSection: SchedaAnamnesticaSectionInput | null;
	submitFunc: (section: SchedaAnamnesticaSectionInput) => void;
	hideFunc: () => void;
	clearEditSection: () => void;
};

type IState = {
	selectedType: SASelectedType | null;
	question: string;
	responses: string[];
	otherAnswerSelected: boolean;
};

export class CreateNewSectionSAModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedType: null,
			question: "",
			otherAnswerSelected: false,
			responses: [],
		};

		this.hide = this.hide.bind(this);
		this.updateResponse = this.updateResponse.bind(this);
		this.addResponse = this.addResponse.bind(this);
		this.removeResponse = this.removeResponse.bind(this);
		this.submit = this.submit.bind(this);
	}

	componentDidUpdate(prevProps: Readonly<IProps>): void {
		const { editedSection } = this.props;
		if (editedSection !== null && prevProps.editedSection === null) {
			this.setState({
				selectedType: editedSection.selectedType,
				question: editedSection.question,
				responses: editedSection.sectionMultiResponses
					.filter((e: SchedaAnamnesticaSectionMultiResponseInputDto) => e.sectionResponse !== "Altro")
					.map((e) => e.sectionResponse),
				otherAnswerSelected:
					editedSection.sectionMultiResponses.filter((e: SchedaAnamnesticaSectionMultiResponseInputDto) => e.sectionResponse === "Altro")
						.length > 0,
			});
		}
	}

	hide() {
		const { hideFunc, clearEditSection } = this.props;
		clearEditSection();
		this.setState({ selectedType: null, question: "", responses: [], otherAnswerSelected: false }, () => hideFunc());
	}

	updateResponse(index: number, response: string) {
		const { responses } = this.state;
		responses[index] = response;
		this.setState({ responses });
	}

	addResponse(index: number) {
		const { responses } = this.state;
		responses.splice(index + 1, 0, "");
		this.setState({ responses });
	}

	removeResponse(index: number) {
		const { responses } = this.state;
		responses.splice(index, 1);
		this.setState({ responses });
	}

	submit(e: React.FormEvent<HTMLFormElement>) {
		const { submitFunc } = this.props;
		const { selectedType, question, responses, otherAnswerSelected } = this.state;

		e.preventDefault();

		if (selectedType !== null) {
			const responsesFixed: string[] = responses;
			if (otherAnswerSelected) responsesFixed.push("Altro");

			submitFunc({
				selectedType,
				question,
				sectionMultiResponses: responsesFixed.map((e) => ({ sectionResponse: e })),
			});

			this.hide();
		}
	}

	render() {
		const { isVisible } = this.props;
		const { selectedType, responses, question, otherAnswerSelected } = this.state;
		return (
			<Modal size="lg" id="newRepartoModal" show={isVisible} onHide={() => this.hide()} centered>
				<Modal.Header closeButton>
					{selectedType !== null &&
						(selectedType === SASelectedType.SiNo ? (
							<Modal.Title className="d-flex align-items-center">
								<BiToggleRight className="fs-3 me-2" />
								Sì / No
							</Modal.Title>
						) : selectedType === SASelectedType.RispostaMultipla ? (
							<Modal.Title className="d-flex align-items-center">
								<BiCheckboxChecked className="fs-3 me-2" />
								Risposta multipla
							</Modal.Title>
						) : selectedType === SASelectedType.RispostaBreve ? (
							<Modal.Title className="d-flex align-items-center">
								<BsTextLeft className="fs-3 me-2" />
								Risposta Breve
							</Modal.Title>
						) : selectedType === SASelectedType.RispostaLunga ? (
							<Modal.Title className="d-flex align-items-center">
								<BsTextLeft className="fs-3 me-2" />
								Risposta Lunga
							</Modal.Title>
						) : selectedType === SASelectedType.Data ? (
							<Modal.Title className="d-flex align-items-center">
								<BsCalendarDate className="fs-5 me-2" />
								Data
							</Modal.Title>
						) : selectedType === SASelectedType.TitoloSezione ? (
							<Modal.Title className="d-flex align-items-center">
								<BiText className="fs-5 me-2" />
								Titolo Sezione
							</Modal.Title>
						) : (
							selectedType === SASelectedType.DropDown && (
								<Modal.Title className="d-flex align-items-center">
									<BiDownvote className="fs-5 me-2" />
									Menu a discesa
								</Modal.Title>
							)
						))}
				</Modal.Header>
				<Modal.Body>
					<div>
						{selectedType === null ? (
							<div>
								<div className="d-flex justify-content-center align-items-center">
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.SiNo })}
										>
											<BiToggleRight className="fs-3 me-2" />
											Sì / No
										</Button>
									</div>
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.RispostaMultipla })}
										>
											<BiCheckboxChecked className="fs-3 me-2" />
											Risposta multipla
										</Button>
									</div>
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.RispostaBreve })}
										>
											<BsTextLeft className="fs-3 me-2" />
											Risposta Breve
										</Button>
									</div>
								</div>
								<div className="d-flex justify-content-center align-items-center mt-2">
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.RispostaLunga })}
										>
											<BsTextLeft className="fs-3 me-2" />
											Risposta Lunga
										</Button>
									</div>
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.Data })}
										>
											<BsCalendarDate className="fs-5 me-2" />
											Data
										</Button>
									</div>
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.TitoloSezione })}
										>
											<BiText className="fs-5 me-2" />
											Titolo Sezione
										</Button>
									</div>
									<div className="mx-2">
										<Button
											className="d-flex align-items-center"
											onClick={() => this.setState({ selectedType: SASelectedType.DropDown })}
										>
											<BiDownvote className="fs-5 me-2" />
											Menu a discesa
										</Button>
									</div>
								</div>
							</div>
						) : (
							<div>
								<div className="main-color">
									<Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.submit(e)}>
										{selectedType === SASelectedType.RispostaMultipla || selectedType === SASelectedType.DropDown ? (
											<MultipleAnswersForm
												question={question}
												updateQuestion={(value: string) => this.setState({ question: value })}
												responses={responses}
												updateResponse={(index: number, response: string) => this.updateResponse(index, response)}
												addResponse={(index: number) => this.addResponse(index)}
												removeResponse={(index: number) => this.removeResponse(index)}
												otherAnswerSelected={otherAnswerSelected}
												updateOtherAnswerSelected={() => this.setState({ otherAnswerSelected: !otherAnswerSelected })}
												altroOptionActive={selectedType === SASelectedType.RispostaMultipla}
											/>
										) : (
											<MainForm
												isTitle={selectedType === SASelectedType.TitoloSezione}
												question={question}
												updateQuestion={(value: string) => this.setState({ question: value })}
											/>
										)}
										<div className="d-flex justify-content-between mt-2">
											<Button
												className="main-color main-button me-2"
												variant="outline-dark"
												onClick={() =>
													this.setState({ selectedType: null, question: "", otherAnswerSelected: false, responses: [] })
												}
											>
												Annulla
											</Button>
											<Button variant="success" type="submit">
												Conferma
											</Button>
										</div>
									</Form>
								</div>
							</div>
						)}
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

type IPropsMainForm = {
	question: string;
	updateQuestion: (answer: string) => void;
	isTitle: boolean;
};

type IPropsMultipleAnswersForm = {
	question: string;
	updateQuestion: (answer: string) => void;
	responses: string[];
	updateResponse: (index: number, response: string) => void;
	addResponse: (index: number) => void;
	removeResponse: (index: number) => void;
	otherAnswerSelected: boolean;
	updateOtherAnswerSelected: () => void;
	altroOptionActive?: boolean;
};

const MainForm = (props: IPropsMainForm): JSX.Element => {
	const { question, updateQuestion, isTitle } = props;
	return (
		<div>
			<div>
				<strong>{isTitle ? "Titolo" : "Domanda"}</strong>
			</div>
			<div className="mb-3">
				<Form.Control
					as="textarea"
					type="text"
					placeholder={`Inserisci ${isTitle ? "il titolo" : "la domanda"}`}
					value={question}
					onChange={(e: React.ChangeEvent<any>) => updateQuestion(e.target.value)}
				/>
			</div>
		</div>
	);
};

const MultipleAnswersForm = (props: IPropsMultipleAnswersForm): JSX.Element => {
	const {
		question,
		responses,
		otherAnswerSelected,
		altroOptionActive,
		updateQuestion,
		updateResponse,
		addResponse,
		removeResponse,
		updateOtherAnswerSelected,
	} = props;

	return (
		<div>
			<div>
				<strong>Domanda</strong>
			</div>
			<div className="mb-3">
				<Form.Control
					as="textarea"
					type="text"
					placeholder="Inserisci la domanda"
					value={question}
					onChange={(e: React.ChangeEvent<any>) => updateQuestion(e.target.value)}
				/>
			</div>
			<div className="row">
				{responses.map((e: string, i: number) => (
					<div key={i} className="my-2">
						<InputGroup>
							<InputGroup.Text className="main-bg text-white">{i + 1}</InputGroup.Text>
							<Form.Control
								type="text"
								placeholder="Inserisci la risposta"
								value={e}
								onChange={(event: React.ChangeEvent<any>) => updateResponse(i, event.target.value)}
							/>
							<InputGroup.Text className="text-white bg-danger" style={{ cursor: "pointer" }} onClick={() => removeResponse(i)}>
								<BiTrash />
							</InputGroup.Text>
						</InputGroup>
						<div className="d-flex justify-content-center mt-3">
							<div className="rounded-circle main-bg" style={{ cursor: "pointer" }} onClick={() => addResponse(i)}>
								<BiPlus className="m-2 text-light" />
							</div>
						</div>
					</div>
				))}
			</div>
			{responses.length === 0 && (
				<div className="d-flex justify-content-center mt-3">
					<div className="rounded-circle main-bg" style={{ cursor: "pointer" }} onClick={() => addResponse(0)}>
						<BiPlus className="m-2 text-light" />
					</div>
				</div>
			)}
			{altroOptionActive && (
				<div className="d-flex">
					<Form.Check className="me-2" checked={otherAnswerSelected} onChange={() => updateOtherAnswerSelected()} />
					<Form.Label style={{ fontWeight: "700" }}>Aggiungi opzione "altro"</Form.Label>
				</div>
			)}
		</div>
	);
};
