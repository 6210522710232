import ReactDOM from 'react-dom/client'
import { App } from './App'

import "./styles/index.css"

const root: ReactDOM.Root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <App />
)