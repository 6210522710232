import axios from "axios";
import Calendar from "react-calendar";
import { Component, ContextType } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { UserOutput, RepartoOutput, DisponibilitaOutput } from "../types";
import { isSameDay } from "date-fns";
import { endpoint, handleAxiosError } from "../helpers";
import { TileArgs } from "react-calendar/dist/cjs/shared/types";
import { LoaderContext } from "../contexts";

type IProps = {};

type IState = {
	reparti: RepartoOutput[];
	dottori: UserOutput[];
	isRepartoSelected: boolean;
};

const now = new Date();

const GetDisponibilitaFromDottori = (dottori: UserOutput[]): DisponibilitaOutput[] => {
	let data: DisponibilitaOutput[] = [];
	dottori.forEach((e: UserOutput) => {
		data = [...data, ...e.disponibilita];
	});
	return data;
};

export class CalendarPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			reparti: [],
			dottori: [],
			isRepartoSelected: true,
		};

		this.tileClasseName = this.tileClasseName.bind(this);
		this.getReparti = this.getReparti.bind(this);
		this.getDottori = this.getDottori.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getReparti();
	}

	tileClasseName({ date, view }: TileArgs, disponibilita: DisponibilitaOutput[]) {
		let classApplied: string = "";

		if (view === "month" && isSameDay(now, date)) classApplied += "highlight ";

		disponibilita.forEach((e: DisponibilitaOutput) => {
			if (isSameDay(new Date(e.startDate), date)) classApplied += "highlight-available ";
		});

		return classApplied;
	}

	getReparti() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAll`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ reparti: data }, () => {
					this.getDottori();
				});
			})
			.catch(handleAxiosError);
	}

	getDottori() {
		const { toggleLoader } = this.context;
		axios
			.get<UserOutput[]>(`${endpoint}/api/User/GetAllDottoriFull/9999/0`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ dottori: data });
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	render() {
		const { reparti, dottori, isRepartoSelected } = this.state;
		return (
			<div>
				<div className="container">
					<div className="mb-3 mb-md-0 d-flex-inline d-md-flex text-center text-md-start justify-content-between mt-5">
						<h1 className="main-color">Calendari GR Medical</h1>
						<ButtonGroup className="buttonGroupCustom1">
							<Button
								variant="primary-outline"
								className={isRepartoSelected ? "selected" : ""}
								onClick={() => this.setState({ isRepartoSelected: true })}
							>
								Reparto
							</Button>
							<Button
								variant="primary-outline"
								className={!isRepartoSelected ? "selected" : ""}
								onClick={() => this.setState({ isRepartoSelected: false })}
							>
								Professionista
							</Button>
						</ButtonGroup>
					</div>
					<div>
						<div className="row">
							{isRepartoSelected
								? reparti &&
								  reparti.map((e: RepartoOutput, i: number) => (
										<div key={i} className="col-12 col-md-6">
											<div className="p-2 p-md-5">
												<div className="d-flex justify-content-center mb-3">
													<strong className="fs-3 main-color" style={{ borderRadius: "20px" }}>
														{e.name}
													</strong>
												</div>
												<Calendar
													tileClassName={(tileArgs: TileArgs) =>
														this.tileClasseName(tileArgs, GetDisponibilitaFromDottori(e.dottori))
													}
													onClickDay={(date: Date) =>
														(window.location.href = `/createPrenotazione?preSelectReparto=${e.id}&preSelectDate=${date}`)
													}
												/>
											</div>
										</div>
								  ))
								: dottori &&
								  dottori.map((e: UserOutput, i: number) => (
										<div key={i} className="col-12 col-md-6">
											<div className="p-2 p-md-5">
												<div className="d-flex justify-content-center mb-3">
													<strong className="fs-3 main-color" style={{ borderRadius: "20px" }}>
														{e.name} {e.surname}
													</strong>
												</div>
												<Calendar
													tileClassName={(tileArgs: TileArgs) => this.tileClasseName(tileArgs, e.disponibilita)}
													onClickDay={() => (window.location.href = "/createPrenotazione")}
												/>
											</div>
										</div>
								  ))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CalendarPage.contextType = LoaderContext;
