import Select, { MultiValue } from "react-select";
import { ChangeEvent, Component, ContextType, FormEvent } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { PrivacyInput, ReactSelectOption, RepartoOutput, UserPazienteInput } from "../../types";
import { Provincia, endpoint, getPresignedUrl, handleAxiosError, provinceList } from "../../helpers";
import { LoaderContext, UserContext, UserDefaultType } from "../../contexts";
import axios from "axios";
import * as signalR from "@microsoft/signalr";
import { ManualPrivacyModal } from "..";

type IProps = {
	isEdit?: boolean;
	isAdmin?: boolean;
	isUser?: boolean;
	model: UserPazienteInput;
	setModel: (key: string, value: any) => void;
	submit: (e: FormEvent<HTMLFormElement>) => void;
	reparti: RepartoOutput[];
};

type IState = {
	repartiSelectOptions: ReactSelectOption[];
	connection: signalR.HubConnection | null;
	isManualModalVisible: boolean;
};

export class AnagraficheForm extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			repartiSelectOptions: [],
			connection: null,
			isManualModalVisible: false,
		};

		this.createWebSocket = this.createWebSocket.bind(this);
		this.makeSign = this.makeSign.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.createWebSocket();
	}

	createWebSocket(): void {
		const { setModel } = this.props;
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/signature`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("FillPrivacy", (privacyModel: PrivacyInput) => {
			setModel("privacy", privacyModel);
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection });
			})
			.catch(() => {
				window.location.reload();
			});
	}

	componentDidUpdate(prevProps: Readonly<IProps>): void {
		const { reparti } = this.props;
		if (prevProps.reparti !== reparti)
			this.setState({
				repartiSelectOptions: reparti.map((e: RepartoOutput) => ({ value: e.id, label: e.name })),
			});
	}

	makeSign() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get(`${endpoint}/api/Paziente/MakeSign`, { withCredentials: true })
			.then(() => { })
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	render() {
		const { repartiSelectOptions, isManualModalVisible } = this.state;
		const { isEdit, isUser, model, isAdmin, setModel, submit } = this.props;
		return (
			<>
				<ManualPrivacyModal
					isVisible={isManualModalVisible}
					submitFunc={(privacyModel: PrivacyInput) => setModel("privacy", privacyModel)}
					hideFunc={() => this.setState({ isManualModalVisible: false })}
				/>
				<Form onSubmit={(e: FormEvent<HTMLFormElement>) => submit(e)} className="my-5">
					<div className="row">
						<div className="col-12 col-md-6">
							<Form.Group className="mb-3" controlId="nome">
								<Form.Label className="me-3">Nome</Form.Label>
								<Form.Control
									type="text"
									value={model.name ?? ""}
									onChange={(e: ChangeEvent<any>) => setModel("name", e.target.value)}
								/>
							</Form.Group>

							<Form.Group className="mb-3" controlId="cognome">
								<Form.Label className="me-3">Cognome</Form.Label>
								<Form.Control
									type="text"
									value={model.surname ?? ""}
									onChange={(e: ChangeEvent<any>) => setModel("surname", e.target.value)}
								/>
							</Form.Group>

							<Form.Group className="mb-3" controlId="email">
								<Form.Label className="me-3">Email</Form.Label>
								<Form.Control
									type="text"
									value={model.email ?? ""}
									onChange={(e: ChangeEvent<any>) => setModel("email", e.target.value)}
								/>
							</Form.Group>

							<Form.Group className="mb-3" controlId="telefono">
								<Form.Label className="me-3">Telefono</Form.Label>
								<InputGroup>
									<span className="input-group-text" id="basic-addon1">
										+39
									</span>
									<Form.Control
										type="text"
										value={model.phoneNumber ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("phoneNumber", e.target.value)}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mb-3" controlId="codiceFiscale">
								<Form.Label className="me-3">Codice Fiscale</Form.Label>
								<Form.Control
									disabled={isEdit}
									type="text"
									value={model.codiceFiscale ?? ""}
									onChange={(e: ChangeEvent<any>) => setModel("codiceFiscale", e.target.value)}
								/>
							</Form.Group>

							{isUser && !isAdmin && (
								<>
									<Form.Group className="mb-3" controlId="REPARTI">
										<Form.Label className="me-3">Reparti</Form.Label>
										<Select
											options={repartiSelectOptions}
											placeholder="Reparti..."
											value={repartiSelectOptions.filter((e: ReactSelectOption) => model.repartiIds?.includes(Number(e.value)))}
											onChange={(e: MultiValue<ReactSelectOption>) => {
												setModel(
													"repartiIds",
													e.map((e: ReactSelectOption) => e.value)
												);
											}}
											isMulti
										/>
									</Form.Group>

									<Form.Group className="d-flex py-3 mb-3" controlId="IsMDL">
										<Form.Check
											className="me-3"
											checked={model.isMdl}
											onChange={(e: ChangeEvent<any>) => setModel("isMdl", e.target.checked)}
										/>
										<Form.Label>Abilitato MDL</Form.Label>
									</Form.Group>

									<Form.Group className="d-flex py-3 mb-3" controlId="IsAbilitatoPrelievo">
										<Form.Check
											className="me-3"
											checked={model.isAbilitatoPrelievo}
											onChange={(e: ChangeEvent<any>) => setModel("isAbilitatoPrelievo", e.target.checked)}
										/>
										<Form.Label>Abilitato Prelievo</Form.Label>
									</Form.Group>
								</>
							)}

							{!isUser && (
								<Form.Group className="d-flex py-3 mb-3" controlId="Sconti">
									<Form.Check
										className="me-3"
										checked={model.hasConvenzione}
										onChange={(e: ChangeEvent<any>) => setModel("hasConvenzione", e.target.checked)}
									/>
									<Form.Label>Convenzione MDL</Form.Label>
								</Form.Group>
							)}
						</div>
						{!isUser && (
							<div className="col-12 col-md-6">
								<Form.Group className="mb-3" controlId="indirizzo">
									<Form.Label className="me-3">Indirizzo</Form.Label>
									<Form.Control
										type="text"
										value={model.indirizzo ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("indirizzo", e.target.value)}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="CAP">
									<Form.Label className="me-3">CAP</Form.Label>
									<Form.Control
										type="text"
										value={model.cap ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("cap", e.target.value)}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="citta">
									<Form.Label className="me-3">Città</Form.Label>
									<Form.Control
										type="text"
										value={model.citta ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("citta", e.target.value)}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="provincia">
									<Form.Label className="me-3">Provincia</Form.Label>
									<Form.Select
										value={model.provincia ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("provincia", e.target.value)}
									>
										{provinceList.map((e: Provincia, i: number) => (
											<option key={i} value={e.sigla}>
												{e.titolo}
											</option>
										))}
									</Form.Select>
								</Form.Group>

								<Form.Group className="mb-3" controlId="partitaIVA">
									<Form.Label className="me-3">Partita IVA</Form.Label>
									<Form.Control
										maxLength={11}
										type="text"
										value={model.partitaIva ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("partitaIva", e.target.value)}
									/>
								</Form.Group>

								<Form.Group className="mb-3" controlId="SDIPEC">
									<Form.Label className="me-3">SDI/PEC</Form.Label>
									<Form.Control
										type="text"
										value={model.sdiPec ?? ""}
										onChange={(e: ChangeEvent<any>) => setModel("sdiPec", e.target.value)}
									/>
								</Form.Group>
							</div>
						)}

						<Form.Group className="mb-3" controlId="description">
							<Form.Label className="me-3">{isUser ? "Descrizione" : "Note"}</Form.Label>
							<Form.Control
								as="textarea"
								rows={6}
								value={model.description ?? ""}
								onChange={(e: ChangeEvent<any>) => setModel("description", e.target.value)}
							/>
						</Form.Group>
					</div>
					<UserContext.Consumer>
						{(userContext: UserDefaultType) =>
							(userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
								<>
									{!isUser && (
										<div>
											<div>
												<span>Privacy:</span>
											</div>
											{model.privacy && (
												<div>
													{model.privacy.signatureUrl && (
														<div className="w-25">
															<img
																className="w-100 h-100"
																src={getPresignedUrl(model.privacy.signatureUrl)}
																alt="signaturePreview"
															/>
														</div>
													)}
													<div className="d-flex">
														<Form.Check className="me-3" checked={model.privacy !== null} disabled />
														<Form.Label>Finalità di gestione obbligatoria</Form.Label>
													</div>
													<div className="d-flex">
														<Form.Check className="me-3" checked={model.privacy.isProfilingEnabled} disabled />
														<Form.Label>Finalità di profilazione</Form.Label>
													</div>
													<div className="d-flex">
														<Form.Check className="me-3" checked={model.privacy.isThirdPartyEnabled} disabled />
														<Form.Label>Comunicazione a terze parti</Form.Label>
													</div>
												</div>
											)}
											<div>
												<div className="d-flex mt-3">
													<div className="me-4">
														<Button variant="primary" onClick={() => this.makeSign()}>
															RICHIEDI CONSENSO
														</Button>
													</div>
													<div>
														<Button variant="outline-dark" onClick={() => this.setState({ isManualModalVisible: true })}>
															GESTISCI MANUALMENTE
														</Button>
													</div>
												</div>
											</div>
										</div>
									)}
								</>
							)
						}
					</UserContext.Consumer>

					<div className="d-flex justify-content-end">
						<Button href="/anagrafiche" variant="secondary" className="me-2">
							ANNULLA
						</Button>
						<Button type="submit" variant="success" className="me-3">
							{isEdit ? "MODIFICA" : "CREA"}
						</Button>
					</div>
				</Form>
			</>
		);
	}
}

AnagraficheForm.contextType = LoaderContext;
