import CanvasDraw from "react-canvas-draw";
import * as signalR from "@microsoft/signalr";
import { ChangeEvent, Component, ContextType, RefObject, createRef } from "react";
import { Form } from "react-bootstrap";
import { PrivacyInput } from "../types";
import { GenerateRandomString, endpoint, handleAxiosError, uploadFile } from "../helpers";
import { BiCheckCircle } from "react-icons/bi";
import axios from "axios";
import { LoaderContext } from "../contexts";

interface IProps {}
interface IState {
	model: PrivacyInput;
	phase: number;
	connection: signalR.HubConnection | null;
	showSignature: boolean;
	showThanks: boolean;
}

type SaveData = {
	lines: Array<{
		points: Array<{ x: number; y: number }>;
		brushColor: string;
		brushRadius: number;
	}>;
	width: number;
	height: number;
};

export class SignaturePage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				isProfilingEnabled: false,
				isThirdPartyEnabled: false,
				requestIpAddress: "",
				signatureUrl: null,
			},
			phase: 1,
			connection: null,
			showSignature: false,
			showThanks: false,
		};

		this.createWebSocket = this.createWebSocket.bind(this);
		this.submit = this.submit.bind(this);
		this.getIp = this.getIp.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.getIp();
		this.createWebSocket();
	}

	getIp() {
		const { model } = this.state;
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<{ ip: string }>("https://api.ipify.org?format=json")
			.then(({ data }) => {
				this.setState({ model: { ...model, requestIpAddress: data.ip } });
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/signature`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("ShowSignature", () => {
			this.setState({ showSignature: true });
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection });
			})
			.catch(() => {
				window.location.reload();
			});
	}

	submit() {
		const { model } = this.state;
		this.setState({ showThanks: true }, () => {
			axios.post(`${endpoint}/api/Paziente/UpdateDocPrivacyForm`, model, { withCredentials: true }).then(() => {
				setTimeout(() => {
					this.setState({
						showThanks: false,
						showSignature: false,
						phase: 1,
						model: {
							isProfilingEnabled: false,
							isThirdPartyEnabled: false,
							requestIpAddress: "",
							signatureUrl: null,
						},
					});
				}, 5000);
			});
		});
	}

	render() {
		const { phase, model, showSignature, showThanks } = this.state;
		return (
			<div id="SignaturePage">
				{!showSignature ? (
					<Placeholder />
				) : showThanks ? (
					<ThanksPage />
				) : phase === 1 ? (
					<PhaseOne
						nextPhase={() => this.setState({ phase: 2 })}
						setSignatureUrl={(signatureUrl: string) => this.setState({ model: { ...model, signatureUrl: signatureUrl } })}
					/>
				) : (
					phase === 2 && (
						<PhaseTwo
							updateThirdParty={(value: boolean) => this.setState({ model: { ...model, isThirdPartyEnabled: value } })}
							updateProfiling={(value: boolean) => this.setState({ model: { ...model, isProfilingEnabled: value } })}
							submitFunc={() => this.submit()}
							model={model}
						/>
					)
				)}
			</div>
		);
	}
}

interface IPropsPhaseOne {
	nextPhase: () => void;
	setSignatureUrl: (signatureUrl: string) => void;
}

class PhaseOne extends Component<IPropsPhaseOne> {
	canvasRef: RefObject<CanvasDraw>;
	constructor(props: IPropsPhaseOne) {
		super(props);

		this.canvasRef = createRef<CanvasDraw>();
		this.saveCanvasAsFile = this.saveCanvasAsFile.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	saveCanvasAsFile = () => {
		const { toggleLoader } = this.context;
		const { setSignatureUrl, nextPhase } = this.props;
		if (this.canvasRef.current) {
			const saveDataString: string = this.canvasRef.current.getSaveData();
			const saveData: SaveData = JSON.parse(saveDataString);

			const canvas = document.createElement("canvas");
			const ctx: CanvasRenderingContext2D = canvas.getContext("2d") as CanvasRenderingContext2D;

			canvas.width = saveData.width;
			canvas.height = saveData.height;

			saveData.lines.forEach((line) => {
				ctx.beginPath();

				ctx.strokeStyle = line.brushColor;
				ctx.lineWidth = line.brushRadius;

				line.points.forEach((point, index) => {
					if (index === 0) ctx.moveTo(point.x, point.y);
					else ctx.lineTo(point.x, point.y);
				});

				ctx.stroke();
			});

			ctx.canvas.toBlob(async (blob: Blob | null) => {
				if (!blob) return;
				toggleLoader(true);
				const url: string | null = await uploadFile(new File([blob], `${GenerateRandomString(10)}.png`, { type: "image/png" }), "signature");

				toggleLoader(false);

				if (!url) return;
				setSignatureUrl(url);
				nextPhase();
			}, "image/png");
		}
	};

	render() {
		return (
			<div className="row d-flex vh-100 p-0 m-0">
				<div className="col-12 h-75 p-0 m-0">
					<div className="container h-100 py-5">
						<div className="main-color pb-5">
							<div className="fs-3">
								<strong>Consenso per la Gestione dei Dati</strong>
							</div>
							<div className="fs-5">
								<span>
									Acconsento al trattamento dei miei dati personali per la fornitura dei servizi medici da parte di GR Medical, come
									da informativa privacy disponibile in loco e su grmedical.it
								</span>
							</div>
						</div>
						<CanvasDraw
							className="w-100 h-100"
							canvasWidth="1920"
							canvasHeight="540"
							ref={this.canvasRef}
							backgroundColor="#E6F2FC"
							hideGrid
							hideInterface
						/>
					</div>
				</div>
				<div className="col-12 h-25 p-0 m-0">
					<div className="d-flex align-items-end h-100">
						<div className="col-2">
							<div className="px-5 py-4">
								<strong>Passaggio 1/2</strong>
							</div>
						</div>
						<div className="col-10">
							<div className="d-flex justify-content-end">
								<div style={{ cursor: "pointer" }} className="bg-primary px-5 py-3" onClick={() => this.canvasRef.current?.clear()}>
									<strong className="text-white fs-2">Riprova</strong>
								</div>
								<div style={{ cursor: "pointer" }} className="bg-success px-5 py-3" onClick={() => this.saveCanvasAsFile()}>
									<strong className="text-white fs-2">Avanti</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

PhaseOne.contextType = LoaderContext;

interface IPropsPhaseTwo {
	submitFunc: () => void;
	updateProfiling: (value: boolean) => void;
	updateThirdParty: (value: boolean) => void;
	model: PrivacyInput;
}

class PhaseTwo extends Component<IPropsPhaseTwo> {
	render() {
		const { model, submitFunc, updateProfiling, updateThirdParty } = this.props;
		return (
			<div className="row d-flex vh-100 p-0 m-0">
				<div className="col-12 h-75 p-0 m-0">
					<div className="container h-100 py-5">
						<div className="main-color pb-5">
							<div className="fs-3">
								<strong>Consenso per la Gestione dei Dati</strong>
							</div>
							<div style={{ margin: "10%" }}>
								<div className="d-flex align-items-center mb-5">
									<Form.Check
										className="fs-2 me-4"
										checked={model.isProfilingEnabled}
										onChange={(e: ChangeEvent<any>) => updateProfiling(e.target.checked)}
									/>
									<div style={{ lineHeight: "30px" }}>
										<div className="fs-4">
											<strong>Consenso per la gestione dei Dati a Scopi di Profilazione Commerciale</strong>
										</div>
										<div className="fs-4">
											<span>Acconsento al trattamento dei miei dati personali per analisi e offerte personalizzate</span>
										</div>
									</div>
								</div>
								<div className="d-flex align-items-center">
									<Form.Check
										className="fs-2 me-4"
										checked={model.isThirdPartyEnabled}
										onChange={(e: ChangeEvent<any>) => updateThirdParty(e.target.checked)}
									/>
									<div style={{ lineHeight: "30px" }}>
										<div className="fs-4">
											<strong>Consenso per la Comunicazione dei Dati a Terze Parti</strong>
										</div>
										<div className="fs-4">
											<span>
												Acconsento alla comunicazione dei miei dati a terze parti per informazioni e offerte selezionate
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 h-25 p-0 m-0">
					<div className="d-flex align-items-end h-100">
						<div className="col-2">
							<div className="px-5 py-4">
								<strong>Passaggio 2/2</strong>
							</div>
						</div>
						<div className="col-10">
							<div className="d-flex justify-content-end">
								<div style={{ cursor: "pointer" }} className="bg-success px-5 py-3" onClick={() => submitFunc()}>
									<strong className="text-white fs-2">Conferma</strong>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const Placeholder = (): JSX.Element => {
	return (
		<div style={{ backgroundColor: "#00679a" }} className="vw-100 vh-100 d-flex justify-content-center align-items-center">
			<div className="w-50 h-50 d-flex align-items-center">
				<img
					className="w-100 h-auto"
					src="https://testbucketgr.s3.eu-west-3.amazonaws.com/static/logoPlaceholder.png"
					alt="logoPlaceholder"
				/>
			</div>
		</div>
	);
};

const ThanksPage = (): JSX.Element => {
	return (
		<div style={{ backgroundColor: "#13bb00" }} className="vw-100 vh-100 d-flex justify-content-center align-items-center">
			<div className="w-50 h-50 d-flex align-items-center">
				<div className="text-center text-white">
					<div>
						<BiCheckCircle style={{ fontSize: "150px" }} />
					</div>
					<div>
						<strong style={{ fontSize: "120px" }}>Grazie!</strong>
					</div>
					<div>
						<span className="fs-3">
							Ricorda che puoi gestire o cambiare i consensi ogni volta che preferisci recandoti in reception o tramite email a
							info@grmedical.it
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

SignaturePage.contextType = LoaderContext;
