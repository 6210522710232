import axios from "axios";
import { ChangeEvent, Component, ContextType, FormEvent } from "react";
import { Button, Form } from "react-bootstrap";
import { UserLogin } from "../../types";
import { endpoint, handleAxiosError } from "../../helpers";
import { LoaderContext } from "../../contexts";
import { notification } from "antd-notifications-messages";

type IProps = {};

type IState = {
	model: UserLogin;
	OTPSent: boolean;
};

export class LoginBox extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				username: "",
				password: "",
				rememberMe: false,
				OTPCode: "",
				isSMS: false,
			},
			OTPSent: false,
		};

		this.login = this.login.bind(this);
		this.loginFirstPhase = this.loginFirstPhase.bind(this);
		this.submitOTP = this.submitOTP.bind(this);
	}

	context!: ContextType<typeof LoaderContext>;

	login(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		this.loginFirstPhase(false);
	}

	loginFirstPhase(isSMSOTP: boolean) {
		const { toggleLoader } = this.context;
		const { model } = this.state;

		if (isSMSOTP)
			model.isSMS = true;

		toggleLoader(true);
		axios
			.post<boolean>(`${endpoint}/api/User/LoginFirstPhase`, model, { withCredentials: true })
			.then(({ data: skipSecondPhase }) => {
				if (skipSecondPhase) window.location.reload();
				else this.setState({ OTPSent: true });

				if (isSMSOTP)
					notification({ message: "Codice temporaneo inviato via SMS.", type: "success" });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	submitOTP(event: FormEvent<HTMLFormElement>) {
		const { toggleLoader } = this.context;
		const { model } = this.state;
		event.preventDefault();

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/User/LoginSecondPhase`, model, { withCredentials: true })
			.then(() => {
				window.location.reload();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const { model, OTPSent } = this.state;

		return (
			<div id="login">
				<div className="centered p-4 col-12 col-md-3" >
					{OTPSent ? (
						<div>
							<div>
								<div className="mb-3">
									<img className="w-25" src="https://testbucketgr.s3.eu-west-3.amazonaws.com/static/logo.svg" alt="logoLogin" />
								</div>
								<span className="main-color" style={{ fontSize: "30px", fontWeight: "700" }}>
									Inserisci il
									<br />
									codice temporaneo.
								</span>
							</div>
							<Form onSubmit={(e: FormEvent<HTMLFormElement>) => this.submitOTP(e)}>
								<Form.Group>
									<Form.Control
										className="my-4"
										id="OTPCode"
										placeholder="Codice OTP"
										type="text"
										value={model.OTPCode}
										onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, OTPCode: e.target.value } })}
									/>
								</Form.Group>
								<Button className="w-100" type="submit">
									Entra
								</Button>
								<div className="mb-4">
									<span onClick={() => this.loginFirstPhase(true)} className="text-decoration-none main-color" style={{ fontSize: "12px", cursor: "pointer" }}>
										Richiedi nuovo codice tramite SMS
									</span>
								</div>
							</Form>
						</div>
					) : (
						<div>
							<div>
								<div className="mb-3">
									<img className="w-25" src="https://testbucketgr.s3.eu-west-3.amazonaws.com/static/logo.svg" alt="logoLogin" />
								</div>
								<span className="main-color" style={{ fontSize: "30px", fontWeight: "700" }}>
									Benvenuto in
									<br />
									GR Medical.
								</span>
							</div>

							<Form onSubmit={(e: FormEvent<HTMLFormElement>) => this.login(e)}>
								<Form.Group>
									<Form.Control
										className="my-4"
										id="username"
										placeholder="Username"
										type="text"
										value={model.username}
										onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, username: e.target.value } })}
									/>

									<Form.Control
										className="my-4"
										id="password"
										placeholder="Password"
										type="password"
										value={model.password}
										onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, password: e.target.value } })}
									/>

									<Form.Group className="d-flex justify-content-between">
										<div className="d-flex">
											<Form.Label className="me-2">Ricordami</Form.Label>
											<Form.Check checked={model.rememberMe} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, rememberMe: e.target.checked } })} />
										</div>
										<Button type="submit">Entra</Button>
									</Form.Group>
								</Form.Group>
							</Form>
						</div>
					)}
				</div>
			</div>
		);
	}
}

LoginBox.contextType = LoaderContext;
