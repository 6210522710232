import axios from "axios";
import { ChangeEvent, Component } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsPersonFillAdd } from "react-icons/bs";
import { LoaderContext, UserContext, UserDefaultType } from "../contexts";
import { UserOutput, PrenotazioneListInput, PrenotazioneOutput, RepartoOutput, PrenotazioniList, UserList } from "../types";
import { DeletePrenotazioneMessage, endpoint, getPresignedUrl, handleAxiosError } from "../helpers";
import { PrenotazioneEnum } from "../enums";
import { format } from "date-fns";
import { DeleteModal } from "../components";
import { debounce } from "lodash";

type IProps = {};

type IState = {
	reparti: RepartoOutput[];
	dottori: UserOutput[];
	prenotazioni: PrenotazioneOutput[];
	prenotazioniTotali: number;
	currentPage: number;
	model: PrenotazioneListInput;
	selectedPrenotazioneDelete: number | null;
};

const searchParams = new URLSearchParams(window.location.search);

export class PrenotazioniPage extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			reparti: [],
			dottori: [],
			prenotazioni: [],
			prenotazioniTotali: 0,
			model: {
				searchString: null,
				selectedDottore: null,
				selectedReparto: null,
				selectedDate: null,
				skip: 0,
				take: 20,
			},
			selectedPrenotazioneDelete: null,
			currentPage: 0,
		};

		this.getPrenotazioniSearchBar = debounce(this.getPrenotazioniSearchBar, 400);
		this.getPrenotazioni = this.getPrenotazioni.bind(this);
		this.deletePrenotazione = this.deletePrenotazione.bind(this);
		this.getReparti = this.getReparti.bind(this);
		this.getDottori = this.getDottori.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount() {
		this.getPrenotazioni();
		this.getReparti();
	}

	componentDidUpdate(_: Readonly<IProps>, prevState: Readonly<IState>): void {
		const { model } = this.state;
		const { model: prevModel } = prevState;
		if (model.searchString !== prevModel.searchString) this.getPrenotazioniSearchBar();
		else if (model !== prevModel) this.getPrenotazioni();
	}

	getPrenotazioni() {
		const { model, currentPage } = this.state;

		const newModel: PrenotazioneListInput = {
			...model,
			skip: currentPage * model.take,
		};

		axios
			.post<PrenotazioniList>(`${endpoint}/api/Prenotazione/GetAll`, newModel, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ prenotazioni: data.data, prenotazioniTotali: data.count }, () => {
					const passedSearchString: string | null = searchParams.get("search");
					if (passedSearchString) this.setState({ model: { ...model, searchString: passedSearchString } });
				});
			})
			.catch(handleAxiosError);
	}

	getPrenotazioniSearchBar() {
		this.getPrenotazioni();
	}

	nextPage() {
		const { prenotazioniTotali, currentPage, model } = this.state;
		const { take } = model;
		const maxPage: number = Math.ceil(prenotazioniTotali / take);

		if (currentPage + 1 < maxPage) this.setState({ currentPage: currentPage + 1 }, () => this.getPrenotazioni());
	}

	previousPage() {
		const { currentPage } = this.state;
		if (currentPage > 0) this.setState({ currentPage: currentPage - 1 }, () => this.getPrenotazioni());
	}

	getReparti() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<RepartoOutput[]>(`${endpoint}/api/Reparto/GetAllForDropdown`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ reparti: data }, () => {
					this.getDottori();
				});
			})
			.catch(handleAxiosError);
	}

	getDottori() {
		const { toggleLoader } = this.context;

		axios
			.get<UserList>(`${endpoint}/api/User/GetAllDottori/9999/0`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ dottori: data.data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	deletePrenotazione() {
		const { toggleLoader } = this.context;
		const { selectedPrenotazioneDelete } = this.state;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/Prenotazione/Delete/${selectedPrenotazioneDelete}`, { withCredentials: true })
			.then(() => {
				this.setState({ selectedPrenotazioneDelete: null }, () => {
					this.getPrenotazioni();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	render() {
		const { dottori, reparti, prenotazioni, model, selectedPrenotazioneDelete, prenotazioniTotali, currentPage } = this.state;
		const { selectedDate, selectedDottore, selectedReparto, searchString } = model;

		const maxPage: number = prenotazioniTotali > model.take ? Math.trunc(prenotazioniTotali / model.take) + 1 : 1;

		return (
			<div id="prenotazioniPage" className="main-color">
				<UserContext.Consumer>
					{(userContext: UserDefaultType) => (
						<>
							<DeleteModal
								hideFunc={() => this.setState({ selectedPrenotazioneDelete: null })}
								isVisible={selectedPrenotazioneDelete !== null}
								submitFunc={() => this.deletePrenotazione()}
								description={DeletePrenotazioneMessage}
							/>
							<div className="container mt-5 text-center text-md-start">
								<h1 className="pt-2 pb-4">
									<strong>Elenco prenotazioni</strong>
								</h1>
								<div className="d-flex-inline d-md-flex justify-content-between align-items-center">
									<Form.Control
										type="date"
										value={selectedDate ?? ""}
										onChange={(e: ChangeEvent<any>) => {
											this.setState({
												model: {
													...model,
													selectedDate: e.target.value === "" ? null : e.target.value,
												},
											});
										}}
										className="me-0 mb-3 mb-md-0 me-md-3"
									/>

									{(userContext.roles.includes("Admin") || userContext.roles.includes("SuperAdmin")) && (
										<Form.Select
											value={selectedDottore ?? -1}
											onChange={(e: ChangeEvent<any>) =>
												this.setState({ model: { ...model, selectedDottore: e.target.value } })
											}
											className="me-0 mb-3 mb-md-0 me-md-3"
										>
											<option disabled value={-1}>
												Seleziona un dottore...
											</option>
											{dottori &&
												dottori.map((e: UserOutput, i: number) => (
													<option key={i} value={e.id}>
														{e.name} {e.surname}
													</option>
												))}
										</Form.Select>
									)}

									<Form.Select
										value={selectedReparto ?? -1}
										onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, selectedReparto: e.target.value } })}
										className="me-0 mb-3 mb-md-0 me-md-3"
									>
										<option disabled value={-1}>
											Seleziona un reparto...
										</option>
										{reparti &&
											reparti.map((e: RepartoOutput, i: number) => (
												<option key={i} value={e.id}>
													{e.name}
												</option>
											))}
									</Form.Select>

									<InputGroup className="me-0 mb-3 mb-md-0 me-md-3">
										<Form.Control
											type="text"
											placeholder="Cerca..."
											value={searchString ?? ""}
											onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, searchString: e.target.value } })}
										/>
										<Button>
											<BiSearchAlt2 />
										</Button>
									</InputGroup>

									<Button className="mb-md-0 me-md-3 d-flex align-items-center" href="/createPrenotazione">
										<BsPersonFillAdd className="me-3" />
										NUOVO
									</Button>
								</div>
								<div className="mt-5">
									<div className="mt-5">
										{prenotazioni &&
											prenotazioni.map((e: PrenotazioneOutput, i: number) => (
												<div
													key={i}
													className="d-flex-inline d-md-flex justify-content-between align-items-center border-bottom mb-3 pb-3"
												>
													<div className="d-none d-md-block">
														<span>
															{e.status === PrenotazioneEnum.Inizializzato && "🔵"}
															{e.status === PrenotazioneEnum.Arrivato && "🟡"}
															{e.status === PrenotazioneEnum.Chiamato && "🟢"}
															{e.status === PrenotazioneEnum.Concluso && "✅"}
														</span>
													</div>
													<div>
														<a href={`/anagrafica?id=${e.paziente.id}`} className="text-decoration-none">
															<strong className="d-flex d-md-flex-inline justify-content-center">
																<div className="d-block d-md-none">
																	{e.status === PrenotazioneEnum.Inizializzato && "🔵 "}
																	{e.status === PrenotazioneEnum.Arrivato && "🟡 "}
																	{e.status === PrenotazioneEnum.Chiamato && "🟢 "}
																	{e.status === PrenotazioneEnum.Concluso && "✅ "}
																</div>
																{`${e.paziente.name} ${e.paziente.surname}`}
															</strong>
														</a>
													</div>
													<div>
														<span>{format(new Date(e.startDate), "dd/MM/yyyy")}</span>
													</div>
													<div>
														<span>
															{format(new Date(e.startDate), "HH:mm")} - {format(new Date(e.endDate), "HH:mm")}
														</span>
													</div>
													<div>
														<span>{e.oggetto.name}</span>
													</div>
													<div className="mt-3 mt-md-0">
														{e.refertoUrl && (
															<Button
																variant="secondary"
																className="ms-3"
																target="_blank"
																href={getPresignedUrl(e.refertoUrl)}
															>
																REFERTO
															</Button>
														)}
														<Button
															variant="danger"
															className="ms-3"
															onClick={() =>
																this.setState({
																	selectedPrenotazioneDelete: e.id,
																})
															}
														>
															ELIMINA
														</Button>
													</div>
												</div>
											))}
									</div>
								</div>
								<div className="d-flex justify-content-center my-5">
									<div className="px-2">
										<Button disabled={currentPage + 1 === 1} onClick={() => this.previousPage()}>
											{"<"}
										</Button>
									</div>
									<div className="px-2">
										<span>
											{currentPage + 1} / {maxPage}
										</span>
									</div>
									<div className="px-2">
										<Button disabled={currentPage + 1 === maxPage} onClick={() => this.nextPage()}>
											{">"}
										</Button>
									</div>
								</div>
							</div>
						</>
					)}
				</UserContext.Consumer>
			</div>
		);
	}
}

PrenotazioniPage.contextType = LoaderContext;
