import axios from "axios";
import { Component, ContextType, PropsWithChildren } from "react";
import { UserContext } from "./UserContext";
import { UserDefaultValue, UserDefaultType } from "./UserDefaultValue";
import { UserLogged } from "../../types";
import { endpoint, handleAxiosError } from "../../helpers";
import { LoaderContext } from "../Loader/LoaderContext";

type IState = {
	user: UserDefaultType;
};

export class UserProvider extends Component<PropsWithChildren<any>, IState> {
	constructor(props: PropsWithChildren<any>) {
		super(props);
		this.state = {
			user: UserDefaultValue,
		};
	}

	context!: ContextType<typeof LoaderContext>;

	componentDidMount() {
		axios
			.get<UserLogged>(`${endpoint}/api/User/GetCurrentUser`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ user: { ...data, isLoaded: true } });
			})
			.catch(handleAxiosError);
	}

	render() {
		const { user } = this.state;
		return <UserContext.Provider value={user}>{this.props.children}</UserContext.Provider>;
	}
}

UserProvider.contextType = LoaderContext;
