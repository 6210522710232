import { Component } from "react";
import { Button } from "react-bootstrap";
import { BiSolidPencil } from "react-icons/bi";
import { BsArrowDownCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { OggettoPrestazioneOutput, RepartoOutput } from "../../types";

type IProps = {
	isSelected: boolean;
	reparto: RepartoOutput;
	selectFunc: any;
	newPrestazioneFunc: any;
	editRepartoFunc: any;
	editOggettoFunc: any;
};

type IState = {};

export class RepartoRow extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	render() {
		const { reparto, isSelected, selectFunc, newPrestazioneFunc, editRepartoFunc, editOggettoFunc } = this.props;
		return (
			<div id="repartoRow" style={{ opacity: reparto.isActive ? "1" : "0.5" }}>
				<div className="d-flex justify-content-between align-items-center p-3 border-bottom">
					{reparto.oggettiPrestazioni.length > 0 ? (
						<div style={{ cursor: "pointer" }} className="d-flex align-items-center" onClick={() => selectFunc(isSelected ? null : reparto.id)}>
							{isSelected ? <BsArrowDownCircleFill className="me-2" /> : <BsArrowRightCircleFill className="me-2" />}
							<strong className="me-2">{reparto.name}</strong>
						</div>
					) : (
						<div className="d-flex align-items-center">
							<strong className="me-2">{reparto.name}</strong>
						</div>
					)}
					<div className="d-none d-md-block">
						<Button className="me-2" onClick={() => editRepartoFunc(reparto)}>
							<BiSolidPencil />
						</Button>
						<Button onClick={() => newPrestazioneFunc()}>NUOVA PRESTAZIONE</Button>
					</div>
				</div>
				{isSelected && reparto.oggettiPrestazioni.length > 0 && (
					<div>
						{reparto.oggettiPrestazioni.map((e: OggettoPrestazioneOutput, i: number) => (
							<div key={i} style={{ fontWeight: e.isActive ? "700" : "500" }} className="row d-flex-inline d-md-flex justify-content-between align-items-center p-2">
								<div className="col-12 col-md-3">
									<span>{e.name}</span>
								</div>
								<div className="col-12 col-md-2 d-flex d-md-flex-inline">
									<span className="me-2 me-md-0">{e.timeRange} min</span>
									<span className="d-block d-md-none me-2">{e.isActive ? "Attiva" : "Disattiva"}</span>
									<span className="d-block d-md-none">{e.price}€</span>
								</div>
								<div className="col-12 col-md-2 d-md-block d-none">
									<span>{e.price}€</span>
								</div>
								<div className="col-12 col-md-2 d-md-block d-none">
									<span>{e.isActive ? "Attiva" : "Disattiva"}</span>
								</div>
								<div className="col-12 col-md-3 text-end d-none d-md-block">
									<Button onClick={() => editOggettoFunc(e)}>
										<BiSolidPencil />
									</Button>
								</div>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}
