import { Component } from "react";
import { LoaderContext } from "../../contexts";
import { SchedaAnamnesticaCompiledOutput } from "../../types";
import { format } from "date-fns";

type IProps = {
	data: SchedaAnamnesticaCompiledOutput;
};
type IState = {};

export class SchedaAnamnesticaBlock extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {};
	}

	context!: React.ContextType<typeof LoaderContext>;

	render() {
		const { data } = this.props;
		return (
			<div
				className="grey-bg w-100 my-3"
				style={{ cursor: "pointer" }}
				onClick={() => (window.location.href = `/saCompiled?id=${data.id}&returnUrl=${window.location.href}`)}
			>
				<div className="d-flex justify-content-between p-3">
					<div className="col-6">
						<span style={{ fontSize: "30px", fontWeight: "700" }}>{data.schedaAnamnestica?.title}</span>
					</div>
					<div className="col-6">
						<div className=" text-end">
							<div>
								<span>
									Dott. {data.createdBy.name} {data.createdBy.surname}
								</span>
							</div>
							<div>
								<strong>{format(new Date(data.createdDate), "dd/MM/yyyy - HH:mm")}</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

SchedaAnamnesticaBlock.contextType = LoaderContext;
