import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { LoaderContext } from "../../contexts";
import { RepartoInput } from "../../types";

type IProps = {
	isVisible: boolean;
	submitFunc: any;
	hideFunc: any;
};

type IState = {
	model: RepartoInput;
};

export class NewRepartoModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			model: {
				name: "",
				isActive: true,
			},
		};

		this.submit = this.submit.bind(this);
		this.hide = this.hide.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	submit() {
		const { model } = this.state;
		const { submitFunc } = this.props;

		submitFunc(model)
		this.setState({ model: { name: "", isActive: true } }, () => this.hide());
	}

	hide() {
		const { hideFunc } = this.props;
		this.setState({ model: { name: "", isActive: true } }, () => hideFunc());
	}

	render() {
		const { isVisible } = this.props;
		const { model } = this.state;
		return (
			<Modal id="newRepartoModal" show={isVisible}>
				<Modal.Header>
					<Modal.Title>Nuovo reparto</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="mb-3">
						<span>Attenzione! Non sarà possibile eliminare questo reparto nel futuro. Crea questo reparto solo se necessario.</span>
					</div>

					<Form.Group className="mb-3">
						<Form.Label className="me-3">Nome Reparto</Form.Label>
						<Form.Control type="text" value={model.name} onChange={(e: ChangeEvent<any>) => this.setState({ model: { ...model, name: e.target.value } })} />
					</Form.Group>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button variant="success" onClick={() => this.submit()}>
						Crea
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

NewRepartoModal.contextType = LoaderContext;
