import axios from "axios";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { endpoint, handleAxiosError } from "../helpers";
import { notification } from "antd-notifications-messages";
import { LoaderContext } from "../contexts";
import { CallResponse, CurrentPrestazione } from "../types";
import { format } from "date-fns";

import Sound from "react-sound";

import * as signalR from "@microsoft/signalr";

enum MdlType {
	Infermieristica,
	MedicoCompetente,
}

type PlayStatus = "PLAYING" | "STOPPED" | "PAUSED";

type IProps = {};

type IState = {
	currentPrestazioneMDL: CurrentPrestazione | null;
	phase: MdlType | null;
	currentCoda: number;
	connection: signalR.HubConnection | null;
	statusAudio: PlayStatus;
	playAudio: boolean;
};

export class VisiteMDL extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			currentPrestazioneMDL: null,
			phase: null,
			currentCoda: 0,
			connection: null,
			statusAudio: "STOPPED",
			playAudio: false,
		};

		this.firstCall = this.firstCall.bind(this);
		this.secondCall = this.secondCall.bind(this);
		this.closeVisita = this.closeVisita.bind(this);
		this.getPersoneInCoda = this.getPersoneInCoda.bind(this);
		this.getPersoneInCodaNoLoader = this.getPersoneInCodaNoLoader.bind(this);
		this.createWebSocket = this.createWebSocket.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.createWebSocket();
	}

	getPersoneInCoda() {
		const { phase } = this.state;
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<number>(`${endpoint}/api/Mdl/GetPersoneInCoda/${phase}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ currentCoda: data });
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	getPersoneInCodaNoLoader() {
		const { phase, currentCoda } = this.state;

		axios
			.get<number>(`${endpoint}/api/Mdl/GetPersoneInCoda/${phase}`, { withCredentials: true })
			.then(({ data }) => {
				if (currentCoda !== data) {
					this.setState({ currentCoda: data, playAudio: true, statusAudio: "PLAYING" });
				}
			})
			.catch(handleAxiosError);
	}

	firstCall() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<CallResponse>(`${endpoint}/api/Mdl/CallFirstByDottore`, { withCredentials: true })
			.then(({ data }) => {
				if (!data.anyOthers) {
					notification({ message: "Nessuna persona in coda disponibile", type: "info" });
					this.getPersoneInCoda();
				} else {
					this.setState({ currentPrestazioneMDL: { id: data.id, code: data.code, date: new Date() } }, () => this.getPersoneInCoda());
				}
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	secondCall() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<CallResponse>(`${endpoint}/api/Mdl/CallSecondByDottore`, { withCredentials: true })
			.then(({ data }) => {
				if (!data.anyOthers) {
					notification({ message: "Nessuna persona in coda disponibile", type: "info" });
					this.getPersoneInCoda();
				} else {
					this.setState({ currentPrestazioneMDL: { id: data.id, code: data.code, date: new Date() } }, () => this.getPersoneInCoda());
				}
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	closeVisita(keepGoing: boolean) {
		const { phase, currentPrestazioneMDL } = this.state;

		if (currentPrestazioneMDL) {
			axios
				.get<CallResponse>(`${endpoint}/api/Mdl/CloseByDottore/${currentPrestazioneMDL.id}`, { withCredentials: true })
				.then(() => {
					if (keepGoing) {
						if (phase === MdlType.Infermieristica) {
							this.firstCall();
						} else if (phase === MdlType.MedicoCompetente) {
							this.secondCall();
						}
					} else {
						this.setState({ currentPrestazioneMDL: null });
					}
				})
				.catch(handleAxiosError);
		}
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/ws`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("RefreshDocStatMDL", () => {
			this.getPersoneInCodaNoLoader();
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection });
			})
			.catch(() => {
				window.location.reload();
			});
	}

	render() {
		const { phase, currentPrestazioneMDL, currentCoda, playAudio, statusAudio } = this.state;

		return (
			<div id="visiteMDLPage" className="main-color">
				{playAudio && (
					<Sound
						url={"https://grmedical-public.s3.eu-south-1.amazonaws.com/static/pingDoc.wav"}
						playStatus={statusAudio}
						onFinishedPlaying={() => this.setState({ statusAudio: "STOPPED" })}
					/>
				)}

				<div className="container my-5">
					{currentPrestazioneMDL ? (
						<>
							<h2>
								{phase === MdlType.MedicoCompetente && "Medico Competente"}
								{phase === MdlType.Infermieristica && "Visita Infermieristica"}
							</h2>
							<h2>Medicina del Lavoro</h2>
							<Button disabled>{`M${currentPrestazioneMDL.code.toString().padStart(3, "0")}`}</Button>
							<div className="mt-3">
								<h1>{`${format(currentPrestazioneMDL.date, "HH:mm")}`}</h1>
							</div>
							<div className="mt-3">
								<span>Al momento ci sono {currentCoda - 1} persone in coda</span>
							</div>
							<div>
								{currentCoda - 1 > 0 && (
									<Button className="me-2" onClick={() => this.closeVisita(true)}>
										Chiudi e passa al prossimo
									</Button>
								)}
								<Button variant="danger" onClick={() => this.closeVisita(false)}>
									Chiudi
								</Button>
							</div>
						</>
					) : (
						<>
							{phase === null && (
								<div style={{ marginTop: "25%" }}>
									<h2 className="my-4">Di cosa ti occupi oggi?</h2>
									<div>
										<Button
											variant="outline-dark"
											className="me-3"
											onClick={() => this.setState({ phase: MdlType.Infermieristica }, () => this.getPersoneInCoda())}
										>
											Visita Infermieristica
										</Button>
										<Button
											className="mt-3 mt-md-0"
											variant="outline-dark"
											onClick={() => this.setState({ phase: MdlType.MedicoCompetente }, () => this.getPersoneInCoda())}
										>
											Visita Medico Competente
										</Button>
									</div>
								</div>
							)}
							{phase === MdlType.Infermieristica && (
								<div>
									<div className="my-5">
										<h2>Visita Infermieristica</h2>
										<h2>Medicina del Lavoro</h2>
									</div>
									<div>
										<Button variant="success" className="me-3" onClick={() => this.firstCall()}>
											Chiama
										</Button>
										<Button variant="primary" href="/">
											Torna alla dashboard
										</Button>
									</div>
									<div className="mt-3">
										<span>Al momento ci sono {currentCoda} persone in coda</span>
									</div>
								</div>
							)}
							{phase === MdlType.MedicoCompetente && (
								<div>
									<div className="my-5">
										<h2>Medico Competente</h2>
										<h2>Medicina del Lavoro</h2>
									</div>
									<div>
										<Button variant="success" className="me-3" onClick={() => this.secondCall()}>
											Chiama
										</Button>
										<Button variant="primary" href="/">
											Torna alla dashboard
										</Button>
									</div>
									<div className="mt-3">
										<span>Al momento ci sono {currentCoda} persone in coda</span>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</div>
		);
	}
}

VisiteMDL.contextType = LoaderContext;
