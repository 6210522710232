import { Component } from "react";
import { LoaderContext } from "../contexts";
import { SchedaAnamnesticaCompiledOutput, SchedaAnamnesticaUserResponseOutput } from "../types";
import axios from "axios";
import { endpoint, getPresignedUrl, handleAxiosError } from "../helpers";
import { format } from "date-fns";
import { Button } from "react-bootstrap";
import { SASelectedType } from "../enums";

interface IProps {}
interface IState {
	data: SchedaAnamnesticaCompiledOutput | null;
}

const searchParams = new URLSearchParams(window.location.search);

export class SchedaAnamnesticaCompiledDetail extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			data: null,
		};

		this.getData = this.getData.bind(this);
		this.generateSA = this.generateSA.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.getData();
	}

	getData() {
		const { toggleLoader } = this.context;
		toggleLoader(true);

		axios
			.get(`${endpoint}/api/SchedaAnamnestica/GetCompiledById/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ data });
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	generateSA() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<string>(`${endpoint}/api/SchedaAnamnestica/GenerateSA/${searchParams.get("id")}`, { withCredentials: true })
			.then(({ data }) => {
				const preSignedUrl: string = getPresignedUrl(data);
				window.open(preSignedUrl, "_blank");
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	render() {
		const { data } = this.state;
		return (
			<div id="SchedaAnamnesticaCompiledDetail">
				{data && data.paziente && data.schedaAnamnestica && (
					<div className="container my-5 main-color">
						<div className="row">
							<div className="col-12 col-md-8">
								<div className="d-flex justify-content-between">
									<div>
										<strong className="fs-3">
											{data.paziente.name} {data.paziente.surname}
										</strong>
									</div>
									<div>
										<Button variant="primary" className="me-2" onClick={() => this.generateSA()}>
											Stampa Scheda
										</Button>
										<Button
											variant="danger"
											onClick={() => (window.location.href = searchParams.get("returnUrl")?.toString() ?? "/")}
										>
											Torna indietro
										</Button>
									</div>
								</div>
								<div className="mt-5">
									<strong className="fs-3">Scheda Anamnestica: {data.schedaAnamnestica.title}</strong>
								</div>
								<div>
									<span>
										Redatta da Dott. {data.createdBy.name} {data.createdBy.surname} il{" "}
										{format(new Date(data.createdDate), "dd/MM/yyyy")} alle {format(new Date(data.createdDate), "HH:mm")}
									</span>
								</div>
								<div id="printable">
									{data.userResponses.map((e: SchedaAnamnesticaUserResponseOutput, i: number) => (
										<div className="mt-3" key={i}>
											<div>
												{e.schedaAnamnesticaSection.selectedType === SASelectedType.TitoloSezione ? (
													<strong className="fs-3">{e.schedaAnamnesticaSection.question}: </strong>
												) : (
													<strong>{e.schedaAnamnesticaSection.question}: </strong>
												)}
												{e.schedaAnamnesticaSection.selectedType === SASelectedType.RispostaMultipla ? (
													e.userResponse.split(",").map((multiResponse: string, indexMultiResponse: number) => (
														<div key={indexMultiResponse}>
															<li>
																<span>{multiResponse}</span>
															</li>
														</div>
													))
												) : e.schedaAnamnesticaSection.selectedType === SASelectedType.RispostaLunga ? (
													<div dangerouslySetInnerHTML={{ __html: e.userResponse }} />
												) : (
													<span>
														{e.userResponse === "true" ? "sì" : e.userResponse === "false" ? "no" : e.userResponse}
													</span>
												)}
											</div>
										</div>
									))}
								</div>
							</div>
							{data.paziente.schedeAnamnesticheCompiled.filter((e) => e.id !== Number(searchParams.get("id"))).length > 0 && (
								<div className="col-12 col-md-4">
									<div>
										<strong className="fs-4 main-color">Ultime schede</strong>
									</div>
									<div className="row">
										{data.paziente.schedeAnamnesticheCompiled
											.filter((e) => e.id !== Number(searchParams.get("id")))
											.map((e: SchedaAnamnesticaCompiledOutput, i: number) => (
												<LatestSA data={e} key={i} />
											))}
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

SchedaAnamnesticaCompiledDetail.contextType = LoaderContext;

interface IPropsLatestSA {
	data: SchedaAnamnesticaCompiledOutput;
}

const LatestSA = (props: IPropsLatestSA): JSX.Element => {
	const { data } = props;
	const { schedaAnamnestica } = data;
	return (
		<div className="col-12">
			{schedaAnamnestica && (
				<div
					className="main-color grey-bg p-3 mt-3"
					style={{ cursor: "pointer" }}
					onClick={() => (window.location.href = `/saCompiled?id=${data.id}&returnUrl=${window.location.href}`)}
				>
					<div>
						<span>SCHEDA ANAMNESTICA</span>
					</div>
					<div>
						<strong className="fs-4">{schedaAnamnestica.title}</strong>
					</div>
					<div className="mt-3" style={{ fontSize: "14px" }}>
						<span className="me-2">
							Dott. {data.createdBy.name} {data.createdBy.surname}
						</span>
						<strong>{format(new Date(data.createdDate), "dd/MM/yyyy - HH:mm")}</strong>
					</div>
				</div>
			)}
		</div>
	);
};
