import axios from "axios";
import { ChangeEvent, Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { endpoint, handleAxiosError } from "../../helpers";
import { LoaderContext } from "../../contexts";
import { notification } from "antd-notifications-messages";

type IProps = {
	visible: boolean;
	hideFunc: any;
};

type IState = {
	oldPassword: string;
	newPassword: string;
	confirmPassword: string;
};

export class ChangePasswordModal extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			oldPassword: "",
			newPassword: "",
			confirmPassword: "",
		};
		this.submit = this.submit.bind(this);
		this.hide = this.hide.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	submit() {
		const { toggleLoader } = this.context;
		const { oldPassword, newPassword, confirmPassword } = this.state;

		if (newPassword !== confirmPassword) {
			notification({ message: "Le password non corrispondono", type: "error" });
			return;
		}

		toggleLoader(true);
		axios
			.post(`${endpoint}/api/User/ResetPassword`, { oldPassword, newPassword }, { withCredentials: true })
			.then(() => {
				this.hide();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	}

	hide() {
		const { hideFunc } = this.props;

		this.setState({ oldPassword: "", newPassword: "", confirmPassword: "" }, () => {
			hideFunc();
		});
	}

	render() {
		const { oldPassword, newPassword, confirmPassword } = this.state;
		const { visible } = this.props;

		return (
			<Modal id="ChangePasswordModal" show={visible} centered>
				<Modal.Body>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Vecchia Password</Form.Label>
						<Form.Control type="password" value={oldPassword} onChange={(e: ChangeEvent<any>) => this.setState({ oldPassword: e.target.value })} />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Nuova Password</Form.Label>
						<Form.Control type="password" value={newPassword} onChange={(e: ChangeEvent<any>) => this.setState({ newPassword: e.target.value })} />
					</Form.Group>
					<Form.Group className="mb-3">
						<Form.Label className="me-3">Ripeti la nuova password</Form.Label>
						<Form.Control type="password" value={confirmPassword} onChange={(e: ChangeEvent<any>) => this.setState({ confirmPassword: e.target.value })} />
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-center">
					<Button variant="secondary" onClick={() => this.hide()}>
						Annulla
					</Button>
					<Button variant="success" onClick={() => this.submit()}>
						Cambia password
					</Button>
				</Modal.Footer>
			</Modal>
		);
	}
}

ChangePasswordModal.contextType = LoaderContext;
