import { SASelectedType as SchedaAnamnesticaSelectedType } from "../enums";
import { PazienteOutput } from "./Paziente";
import { RepartoOutput } from "./Reparto";
import { UserOutput } from "./User";

export type SchedaAnamnesticaInput = {
	title: string;
	isActive: boolean;
	fk_Reparto: number;
	sections: SchedaAnamnesticaSectionInput[];
};

export type SchedaAnamnesticaOutput = {
	id: number;
	title: string;
	isActive: boolean;
	reparto: RepartoOutput;
	sections: SchedaAnamnesticaSectionOutput[];
	schedeAnamnesticheCompiled: SchedaAnamnesticaCompiledOutput[];
};

export type SchedaAnamnesticaCompiledInput = {
	fk_Paziente: number;
	fk_SchedaAnamnestica: number;
	userResponses: SchedaAnamnesticaUserResponseInput[];
};

export type SchedaAnamnesticaCompiledOutput = {
	id: number;
	createdDate: string;
	paziente: PazienteOutput | null;
	schedaAnamnestica: SchedaAnamnesticaOutput | null;
	createdBy: UserOutput;
	userResponses: SchedaAnamnesticaUserResponseOutput[];
};

export type SchedaAnamnesticaSectionInput = {
	selectedType: SchedaAnamnesticaSelectedType;
	question: string;
	sectionMultiResponses: SchedaAnamnesticaSectionMultiResponseInputDto[];
};

export type SchedaAnamnesticaSectionOutput = {
	id: number;
	selectedType: SchedaAnamnesticaSelectedType;
	question: string;
	schedaAnamnestica: SchedaAnamnesticaOutput;
	sectionMultiResponses: SchedaAnamnesticaSectionMultiResponseOutputDto[];
	userResponses: SchedaAnamnesticaUserResponseOutput[];
};

export type SchedaAnamnesticaSectionMultiResponseInputDto = {
	sectionResponse: string;
};

export type SchedaAnamnesticaSectionMultiResponseOutputDto = {
	id: number;
	sectionResponse: string;
	schedaAnamnesticaSection: SchedaAnamnesticaSectionOutput;
};

export type SchedaAnamnesticaUserResponseOutput = {
	id: number;
	userResponse: string;
	schedaAnamnesticaSection: SchedaAnamnesticaSectionOutput;
	schedaAnamnesticaCompiled: SchedaAnamnesticaCompiledOutput;
};

export type SchedaAnamnesticaUserResponseInput = {
	userResponse: string;
	fk_SchedaAnamnesticaSection: number;
};

export type MultiSelectValue = {
	sectionId: number;
	multiResponseId: number;
	value: string;
	checked: boolean;
	otherString: string | null;
};

export const MapOutputToInput = (data: SchedaAnamnesticaOutput): SchedaAnamnesticaInput => {
	return {
		title: data.title,
		isActive: data.isActive,
		fk_Reparto: data.reparto.id,
		sections: data.sections.map((section) => ({
			selectedType: section.selectedType,
			question: section.question,
			sectionMultiResponses: section.sectionMultiResponses.map((response) => ({
				sectionResponse: response.sectionResponse,
			})),
		})),
	};
};
