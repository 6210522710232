import axios from "axios";
import { ChangeEvent, Component, RefObject, createRef } from "react";
import { LoaderContext, UserContext, UserDefaultType } from "../contexts";
import { Button, Form } from "react-bootstrap";
import { BiPlusCircle, BiSolidHand, BiTrash } from "react-icons/bi";
import { DocumentoInput, DocumentoOutput, PrenotazioneOutput, SchedaAnamnesticaCompiledOutput } from "../types";
import { CheckFileType, GenerateRandomString, GetIconFromExtension, endpoint, getPresignedUrl, handleAxiosError, uploadFile } from "../helpers";
import { PrenotazioneEnum } from "../enums";
import { format } from "date-fns";
import { notification } from "antd-notifications-messages";

import * as signalR from "@microsoft/signalr";

import Sound from "react-sound";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type PlayStatus = "PLAYING" | "STOPPED" | "PAUSED";

type IProps = {};

type IState = {
	prenotazione: PrenotazioneOutput | null;
	prenotazioniToCall: PrenotazioneOutput[];
	refertoText: string;
	refertoUrl: string | null;
	selectedDocDelete: number | null;
	isDragOver: boolean;
	fileName: string;
	file: File | null;
	connection: signalR.HubConnection | null;
	statusAudio: PlayStatus;
	playAudio: boolean;
};

const queryParams = new URLSearchParams(window.location.search);

const modules = {
	toolbar: [
		["bold", "italic", "underline", "strike"], // toggled buttons

		[{ list: "ordered" }, { list: "bullet" }],
		[{ indent: "-1" }, { indent: "+1" }], // outdent/indent

		[{ size: ["small", false, "large", "huge"] }], // custom dropdown

		[{ color: [] }, { background: [] }], // dropdown with defaults from theme
		[{ align: [] }],

		["clean"], // remove formatting button
	],
};

export class PrenotazioneDetailPage extends Component<IProps, IState> {
	fileInputRef: RefObject<HTMLInputElement>;
	constructor(props: IProps) {
		super(props);
		this.state = {
			prenotazione: null,
			prenotazioniToCall: [],
			refertoText: "",
			refertoUrl: null,
			selectedDocDelete: null,
			isDragOver: false,
			fileName: "",
			file: null,
			connection: null,
			statusAudio: "STOPPED",
			playAudio: false,
		};

		this.fileInputRef = createRef<HTMLInputElement>();

		this.getPrenotazione = this.getPrenotazione.bind(this);
		this.getNextPrenotazioni = this.getNextPrenotazioni.bind(this);
		this.closeVisita = this.closeVisita.bind(this);
		this.switchVisita = this.switchVisita.bind(this);
		this.generateReferto = this.generateReferto.bind(this);
		this.handleDragEnter = this.handleDragEnter.bind(this);
		this.handleDragLeave = this.handleDragLeave.bind(this);
		this.handleDrop = this.handleDrop.bind(this);
		this.handleDragOver = this.handleDragOver.bind(this);
		this.uploadDoc = this.uploadDoc.bind(this);
		this.removeDoc = this.removeDoc.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.removeDoc = this.removeDoc.bind(this);
		this.getNextPrenotazioniNoLoader = this.getNextPrenotazioniNoLoader.bind(this);
		this.createWebSocket = this.createWebSocket.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	componentDidMount(): void {
		this.createWebSocket();
	}

	createWebSocket(): void {
		const hubConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(
				`${endpoint}/ws`,
				signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling | signalR.HttpTransportType.ServerSentEvents
			)
			.withAutomaticReconnect()
			.build();

		hubConnection.on("RefreshDocStat", () => {
			this.getNextPrenotazioniNoLoader();
		});

		hubConnection.onclose(() => {
			window.location.reload();
		});

		hubConnection
			.start()
			.then(async () => {
				this.setState({ connection: hubConnection }, () => {
					this.getPrenotazione();
					this.getNextPrenotazioni();
				});
			})
			.catch(() => {
				window.location.reload();
			});
	}

	getPrenotazione() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PrenotazioneOutput>(`${endpoint}/api/Prenotazione/GetById/${queryParams.get("id")}`, {
				withCredentials: true,
			})
			.then(({ data }) => {
				if (data.status !== PrenotazioneEnum.Chiamato) {
					window.location.href = "/";
				} else {
					this.setState({ prenotazione: data });
				}
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	getNextPrenotazioni() {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<PrenotazioneOutput[]>(`${endpoint}/api/Prenotazione/GetAllByDottoreToCall`, { withCredentials: true })
			.then(({ data }) => {
				this.setState({ prenotazioniToCall: data });
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	}

	getNextPrenotazioniNoLoader() {
		const { prenotazioniToCall } = this.state;

		axios
			.get<PrenotazioneOutput[]>(`${endpoint}/api/Prenotazione/GetAllByDottoreToCall`, { withCredentials: true })
			.then(({ data }) => {
				if (prenotazioniToCall !== data) {
					this.setState({ statusAudio: "PLAYING", playAudio: true }, () => {
						this.setState({ prenotazioniToCall: data });
					});
				}
			})
			.catch(handleAxiosError);
	}

	closeVisita() {
		const { prenotazione, refertoUrl } = this.state;
		const { toggleLoader } = this.context;

		if (prenotazione) {
			toggleLoader(true);
			axios
				.post(`${endpoint}/api/Prenotazione/CloseByDottore/${prenotazione.id}`, { refertoUrl }, { withCredentials: true })
				.then(() => {
					window.location.href = "/";
				})
				.catch(handleAxiosError)
				.finally(() => toggleLoader(false));
		}
	}

	switchVisita(nextPrenotazioneId: number) {
		const { prenotazione, refertoUrl } = this.state;
		const { toggleLoader } = this.context;

		if (prenotazione) {
			toggleLoader(true);
			axios
				.post(`${endpoint}/api/Prenotazione/CloseByDottore/${prenotazione.id}`, { refertoUrl }, { withCredentials: true })
				.then(() => {
					axios
						.get(`${endpoint}/api/Prenotazione/CallByDottore/${nextPrenotazioneId}`, {
							withCredentials: true,
						})
						.then(() => {
							window.location.href = `/prenotazione?id=${nextPrenotazioneId}`;
						})
						.catch(handleAxiosError);
				})
				.catch(handleAxiosError)
				.finally(() => toggleLoader(false));
		}
	}

	generateReferto() {
		const { prenotazione, refertoText } = this.state;
		const { toggleLoader } = this.context;

		if (prenotazione) {
			toggleLoader(true);
			axios
				.post<string>(`${endpoint}/api/Prenotazione/GenerateReferto/${prenotazione.id}`, { refertoText }, { withCredentials: true })
				.then(({ data }) => {
					this.setState({ refertoUrl: data });
				})
				.catch(handleAxiosError)
				.finally(() => toggleLoader(false));
		}
	}

	handleDragEnter = (e: any) => {
		e.preventDefault();
		this.setState({ isDragOver: true });
	};

	handleDragLeave = (e: any) => {
		e.preventDefault();
		this.setState({ isDragOver: false });
	};

	handleDrop = (e: any) => {
		e.preventDefault();
		if (this.fileInputRef.current) {
			this.fileInputRef.current.files = e.dataTransfer.files;
		}
		const currentFile: File = e.dataTransfer.files[0];
		if (CheckFileType(currentFile)) {
			const currentFileName: string = e.dataTransfer.files[0].name;
			const nameWithoutExtension: string = currentFileName.split(".").slice(0, -1).join(".");
			this.setState({ fileName: nameWithoutExtension, file: currentFile });
		}
		this.setState({ isDragOver: false });
	};

	handleDragOver = (e: any) => {
		e.preventDefault();
	};

	uploadDoc = async () => {
		const { toggleLoader } = this.context;
		const { prenotazione, file, fileName } = this.state;
		if (!file || !prenotazione?.paziente) return notification({ type: "error", message: "File non trovato o anagrafica mancante" });

		toggleLoader(true);
		const fileSize: string = (file.size / 1024 / 1024).toFixed(2);
		const fileExtension: string = file.name.split(".")[1] ?? "unknown";

		const uploadedUrl: string | null = await uploadFile(file, "documenti", GenerateRandomString(5));

		if (!uploadedUrl) {
			toggleLoader(false);
			return;
		}

		const model: DocumentoInput = {
			fk_Paziente: prenotazione.paziente.id,
			titolo: fileName,
			url: uploadedUrl,
			type: fileExtension,
			size: fileSize,
		};

		axios
			.post(`${endpoint}/api/Paziente/AddDoc`, model, { withCredentials: true })
			.then(() => {
				this.getPrenotazione();
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
				this.setState({ fileName: "", file: null });
			});
	};

	removeDoc = (id: number) => {
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.delete(`${endpoint}/api/Paziente/RemoveDoc/${id}`, { withCredentials: true })
			.then(() => {
				this.setState({ selectedDocDelete: null }, () => {
					this.getPrenotazione();
				});
			})
			.catch(handleAxiosError)
			.finally(() => {
				toggleLoader(false);
			});
	};

	handleFileSelect(e: ChangeEvent<HTMLInputElement>) {
		e.preventDefault();
		if (e.target.files) {
			const currentFile: File = e.target.files[0];
			if (CheckFileType(currentFile)) {
				const currentFileName: string = e.target.files[0].name;
				const nameWithoutExtension: string = currentFileName.split(".").slice(0, -1).join(".");
				this.setState({ fileName: nameWithoutExtension, file: currentFile });
			}
		}
	}

	render() {
		const { playAudio, statusAudio, prenotazione, prenotazioniToCall, refertoText, refertoUrl, file, fileName, isDragOver } = this.state;
		return (
			<div id="prenotazioneDetail" className="container">
				{playAudio && (
					<Sound
						url={"https://grmedical-public.s3.eu-south-1.amazonaws.com/static/pingDoc.wav"}
						playStatus={statusAudio}
						onFinishedPlaying={() => this.setState({ statusAudio: "STOPPED" })}
					/>
				)}
				{prenotazione && (
					<div className="my-5">
						<div className="row d-flex justify-content-between">
							<div className="col-12 col-md-8">
								<div className="d-flex-inline d-md-flex align-items-center">
									<div className="me-3 d-none d-md-block">
										<Button>{prenotazione.code !== null ? "W" + ("000" + prenotazione.code).slice(-3) : "-"}</Button>
									</div>
									<div className="text-end d-block d-md-none d-flex justify-content-between">
										<Button>{prenotazione.code !== null ? "W" + ("000" + prenotazione.code).slice(-3) : "-"}</Button>
										<Button onClick={() => this.closeVisita()}>CONCLUDI VISITA</Button>
									</div>
									<div className="main-color mt-5 mt-md-0" style={{ lineHeight: "22px" }}>
										<div>
											<span style={{ fontSize: "40px" }}>
												<strong>
													{prenotazione.paziente.name} {prenotazione.paziente.surname}
												</strong>
											</span>
										</div>
										<div className="mt-3 mt-md-0">
											<span>
												APPUNTAMENTO DEL {format(new Date(prenotazione.startDate), "dd/MM/yyyy")} ore{" "}
												{format(new Date(prenotazione.startDate), "HH:mm")}
											</span>
										</div>
									</div>
								</div>
								<div className="mt-5">
									<h3 className="main-color">
										<strong>{prenotazione.oggetto.name}</strong>
									</h3>
									<p>{prenotazione.description}</p>
								</div>
								<div className="mt-5">
									<div className="row d-flex">
										{prenotazione.paziente.schedeAnamnesticheCompiled &&
											prenotazione.paziente.schedeAnamnesticheCompiled.map((e: SchedaAnamnesticaCompiledOutput, i: number) => (
												<SchedaAnamnesticaBlock data={e} key={i} />
											))}
										<div className="col-12 col-md-6 p-0">
											<a
												href={`/compileSA?id=${prenotazione.paziente.id}&returnUrl=${window.location.href}`}
												className="text-decoration-none"
											>
												<div
													className="main-bg text-white py-3 px-4 m-3 d-flex align-items-center"
													style={{ height: "150px", cursor: "pointer" }}
												>
													<div className="me-3 fs-4">
														<BiPlusCircle />
													</div>
													<div>
														<strong>Crea nuova scheda</strong>
													</div>
												</div>
											</a>
										</div>
									</div>
								</div>
								{prenotazione.paziente.description && (
									<div className="mt-5">
										<h3 className="main-color">
											<strong>Note cliente</strong>
										</h3>
										<p>{prenotazione.paziente.description}</p>
									</div>
								)}
								<div className="mt-5">
									<div className="row d-flex main-color">
										{prenotazione.paziente.documenti.map((e: DocumentoOutput, i: number) => (
											<div className="col-12 col-md-4" key={i}>
												<div className="p-3 my-4 grey-bg">
													<div className="d-flex justify-content-between align-items-center">
														<div className="d-flex align-items-center">
															{GetIconFromExtension(e.type)}
															<span className="text-break">
																<strong>{e.type.toUpperCase()}</strong> - {e.size}mb
															</span>
														</div>
														<UserContext.Consumer>
															{(user: UserDefaultType) =>
																(user.roles.includes("Admin") || user.roles.includes("SuperAdmin")) && (
																	<div
																		style={{ cursor: "pointer" }}
																		onClick={() => this.setState({ selectedDocDelete: e.id })}
																	>
																		<BiTrash className="text-white rounded bg-danger p-1" />
																	</div>
																)
															}
														</UserContext.Consumer>
													</div>
													<div className="d-flex">
														<div className="my-3">
															<strong
																className="text-break"
																style={{ cursor: "pointer" }}
																onClick={() => window.open(getPresignedUrl(e.url), "_blank")}
															>
																{e.titolo}
															</strong>
														</div>
													</div>
													<div style={{ fontSize: "12px" }}>
														<div>
															<span>
																Dott. {e.createdBy.name} {e.createdBy.surname}
															</span>
														</div>
														<div>
															<strong>
																{format(new Date(e.createdDate), "dd/MM/yyyy")} -{" "}
																{format(new Date(e.createdDate), "HH:mm")}
															</strong>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
									{file ? (
										<div className="p-3 grey-bg">
											<div>
												<strong>Conferma il nome del caricamento:</strong>
											</div>
											<div className="d-flex-inline d-md-flex justify-content-between">
												<div className="col-12 col-md-7">
													<Form.Control
														onChange={(e: ChangeEvent<any>) => this.setState({ fileName: e.target.value })}
														value={fileName}
														type="text"
														className="w-100"
													/>
												</div>
												<div className="col-12 col-md-4">
													<Button
														className="me-5 me-md-2 mt-2 mt-md-0"
														variant="secondary"
														onClick={() =>
															this.setState({ fileName: "", file: null }, () => {
																if (this.fileInputRef.current) {
																	this.fileInputRef.current.value = "";
																}
															})
														}
													>
														ANNULLA
													</Button>
													<Button className="mt-2 mt-md-0" variant="primary" onClick={() => this.uploadDoc()}>
														CARICA
													</Button>
												</div>
											</div>
										</div>
									) : (
										<div
											onDrop={this.handleDrop}
											onDragOver={this.handleDragOver}
											onDragEnter={this.handleDragEnter}
											onDragLeave={this.handleDragLeave}
											onClick={() => this.fileInputRef.current?.click()}
										>
											<img
												className="w-100 h-100 d-none d-md-block"
												src={
													isDragOver
														? "https://grmedical-public.s3.eu-south-1.amazonaws.com/static/hoverupload.jpg"
														: "https://grmedical-public.s3.eu-south-1.amazonaws.com/static/preupload.jpg"
												}
												alt="uploadImg"
											/>
											<img
												className="w-100 h-100 d-block d-md-none"
												src="https://grmedical-public.s3.eu-south-1.amazonaws.com/static/PreuploadResp.jpg"
												alt="uploadImg"
											/>
										</div>
									)}
									<input type="file" ref={this.fileInputRef} onChange={this.handleFileSelect} style={{ display: "none" }} />
								</div>
								<div className="mt-5">
									<h3 className="main-color">
										<strong>Refertazione</strong>
									</h3>
									{refertoUrl ? (
										<div>
											<span>Referto caricato.</span>
											<span
												className="color-danger fs-3"
												style={{ cursor: "pointer" }}
												onClick={() => this.setState({ refertoUrl: null })}
											>
												X
											</span>
											<div>
												<Button onClick={() => window.open(getPresignedUrl(refertoUrl), "_blank")}>Stampa</Button>
											</div>
										</div>
									) : (
										<>
											<div>
												<ReactQuill
													value={refertoText}
													modules={modules}
													onChange={(_content, _delta, _source, editor) => this.setState({ refertoText: editor.getHTML() })}
												/>
											</div>
											<div className="mt-3">
												<Button onClick={() => this.generateReferto()}>CARICA REFERTO</Button>
											</div>
										</>
									)}
								</div>

								{prenotazione.paziente.prenotazioni.filter((e) => new Date(e.startDate) < new Date() && e.id !== prenotazione.id && !e.isDeleted)
									.length > 0 && (
									<div className="mt-5">
										<h3 className="main-color">
											<strong>
												Appuntamenti passati {prenotazione.paziente.name} {prenotazione.paziente.surname}
											</strong>
										</h3>
										<div className="row">
											{prenotazione.paziente.prenotazioni
												.filter((e) => new Date(e.startDate) < new Date() && e.id !== prenotazione.id && !e.isDeleted)
												.map((e: PrenotazioneOutput, i: number) => (
													<div key={i} className="col-12">
														<div className="grey-bg main-color py-3 px-4 m-3">
															<div>
																<div>
																	<span>
																		<strong>{format(new Date(e.startDate), "dd/MM/yyyy")}</strong> - ore{" "}
																		{format(new Date(e.startDate), "HH:mm")} - Dott. {e.dottore.name}{" "}
																		{e.dottore.surname}
																	</span>
																</div>
																<div className="fs-4">
																	<strong>{e.oggetto.name}</strong>
																</div>
															</div>
															{e.refertoUrl && (
																<div>
																	<Button
																		onClick={() => window.open(getPresignedUrl(e.refertoUrl ?? ""), "_blank")}
																	>
																		REFERTO
																	</Button>
																</div>
															)}
															{e.description && (
																<div style={{ fontSize: "10px" }} className="mt-3">
																	<div>
																		<strong>Note prestazione</strong>
																	</div>
																	<div>
																		<span>{e.description}</span>
																	</div>
																</div>
															)}
														</div>
													</div>
												))}
										</div>
									</div>
								)}

								{prenotazione.paziente.prenotazioni.filter(
									(e) => new Date(e.startDate) >= new Date() && e.id !== prenotazione.id && !e.isDeleted
								).length > 0 && (
									<div className="mt-5">
										<h3 className="main-color">
											<strong>
												Appuntamenti futuri {prenotazione.paziente.name} {prenotazione.paziente.surname}
											</strong>
										</h3>
										<div className="row">
											{prenotazione.paziente.prenotazioni
												.filter((e) => new Date(e.startDate) > new Date() && e.id !== prenotazione.id && !e.isDeleted)
												.map((e: PrenotazioneOutput, i: number) => (
													<div key={i} className="col-12">
														<div className="grey-bg main-color py-3 px-4 m-3">
															<div>
																<div>
																	<span>
																		<strong>{format(new Date(e.startDate), "dd/MM/yyyy")}</strong> - ore{" "}
																		{format(new Date(e.startDate), "HH:mm")} - Dott. {e.dottore.name}{" "}
																		{e.dottore.surname}
																	</span>
																</div>
																<div className="fs-4">
																	<strong>{e.oggetto.name}</strong>
																</div>
															</div>
															{e.description && (
																<div style={{ fontSize: "10px" }} className="mt-3">
																	<div>
																		<strong>Note prestazione</strong>
																	</div>
																	<div>
																		<span>{e.description}</span>
																	</div>
																</div>
															)}
														</div>
													</div>
												))}
										</div>
									</div>
								)}
							</div>
							<div className="col-12 col-md-4">
								<div className="text-end d-none d-md-block">
									<Button onClick={() => this.closeVisita()}>CONCLUDI VISITA</Button>
								</div>
								{prenotazioniToCall.length > 0 && (
									<div className="mt-5">
										<h3 className="main-color mx-3">
											<strong>Prossimi appuntamenti</strong>
										</h3>
										<div className="row">
											{prenotazioniToCall.filter((e) => e.id !== prenotazione.id).length > 0 &&
												prenotazioniToCall
													.filter((e) => e.id !== prenotazione.id)
													.map((e: PrenotazioneOutput, i: number) => (
														<div key={i} className="col-12">
															<div className="grey-bg main-color py-3 px-4 m-3">
																<div>
																	<div>
																		<span>{format(new Date(e.startDate), "HH:mm")}</span>
																		{e.status === PrenotazioneEnum.Inizializzato && <span>🔵 PRENOTATO</span>}
																		{e.status === PrenotazioneEnum.Arrivato && <span>🟡 ARRIVATO</span>}
																	</div>
																	<div className="fs-4">
																		<strong>
																			{e.paziente.name} {e.paziente.surname}
																		</strong>
																	</div>
																</div>
																<div>
																	<div>
																		<strong>{e.oggetto.name}</strong>
																	</div>
																	<div className="mt-3 d-flex align-items-center">
																		<div className="me-3">
																			<Button>
																				{e.code !== null ? "W" + ("000" + e.code).slice(-3) : "-"}
																			</Button>
																		</div>
																		<div>
																			<Button
																				onClick={() => this.switchVisita(e.id)}
																				disabled={e.status !== PrenotazioneEnum.Arrivato}
																				variant="success"
																				className="d-flex align-items-center"
																			>
																				<BiSolidHand className="me-2" />
																				CHIAMA
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													))}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

PrenotazioneDetailPage.contextType = LoaderContext;

interface SchedaAnamnesticaBlockProps {
	data: SchedaAnamnesticaCompiledOutput;
}

export class SchedaAnamnesticaBlock extends Component<SchedaAnamnesticaBlockProps> {
	constructor(props: SchedaAnamnesticaBlockProps) {
		super(props);

		this.openSA = this.openSA.bind(this);
	}

	context!: React.ContextType<typeof LoaderContext>;

	openSA = () => {
		const { data } = this.props;
		const { toggleLoader } = this.context;

		toggleLoader(true);
		axios
			.get<string>(`${endpoint}/api/SchedaAnamnestica/GenerateSA/${data.id}`, { withCredentials: true })
			.then(({ data }) => {
				window.open(getPresignedUrl(data), "_blank");
			})
			.catch(handleAxiosError)
			.finally(() => toggleLoader(false));
	};

	render() {
		const { data } = this.props;
		return (
			<div className="col-12 col-md-6 p-0">
				<div onClick={() => this.openSA()} className="grey-bg main-color py-3 px-4 m-3" style={{ height: "150px", cursor: "pointer" }}>
					<div>
						<div>
							<span>SCHEDA ANAMNESTICA</span>
						</div>
						<div className="fs-4">
							<strong>{data.schedaAnamnestica?.title}</strong>
						</div>
					</div>
					<div style={{ fontSize: "10px" }} className="mt-3">
						<div>
							<span>
								Dott. {data.createdBy.name} {data.createdBy.surname}
							</span>
						</div>
						<div>
							<strong>
								{format(new Date(data.createdDate), "dd/MM/yyyy")} - {format(new Date(data.createdDate), "HH:mm")}
							</strong>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

SchedaAnamnesticaBlock.contextType = LoaderContext;
