import { notification } from "antd-notifications-messages";
import { AxiosError } from "axios";

export const handleAxiosError = (error: AxiosError<string>): void => {
	const errorMessage = error.response?.data ?? "Errore";
	notification({ message: errorMessage, type: "error" });
};

export const handleError = (error: any): void => {
	notification({ message: error.toString(), type: "error" });
};
